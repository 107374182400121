import React from 'react';
import RosterCalendar from './RosterCalendar';
import Taber from '../../components/Taber';
import { Box, Card } from '@material-ui/core';
import useQueryParams from '../../hooks/useQueryParams';
import ListView from './ListView';
import { translate } from '../../shared/translate';

export default (props) => {
  const [query, setQuery] = useQueryParams();

  return (
    <div>
      <Taber
        currentTab={+query?.tab || 0}
        tabs={[
          {
            name: translate.shift_schedule || '排班表',
          },
          {
            name: translate.shift_schedule_overview || '排班日程總覽',
          },
        ]}
        onTabChange={(tab) => setQuery({ tab })}
      />
      {
        {
          0: <ListView {...props} />,
          1: (
            <Card square elevation={0}>
              <Box p={2}>
                <RosterCalendar />
              </Box>
            </Card>
          ),
        }[query?.tab || 0]
      }
    </div>
  );
};
