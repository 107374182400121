import React from 'react';
import { translate, translateFormat } from '../../../../shared/translate';
import { gql } from '@apollo/client';
import { client } from '../../../../shared/apollo';
import confirmation from '../../../../shared/confirmation';
import { actionWrapper, NumberFormat } from '../../../../shared';
import ActivityIndicator from '../../../../components/ActivityIndicator';
import SystemButton from '../../../../components/SystemButton';

export const APPOINTMENT_CANCEL = gql`
  mutation ($id: ID!) {
    appointmentCancel(id: $id) {
      id
      status
    }
  }
`;
const ActionCancel = ({ appointments = [] }) => {
  return (
    <SystemButton
      color={'error'}
      disabled={appointments.length === 0}
      onClick={async () => {
        if (
          await confirmation(
            translateFormat(translate.confirm_cancel_appointments, {
              appointmentsLength: NumberFormat(appointments.length),
            }),
          )
        ) {
          await actionWrapper(
            async () => {
              for (let i = 0; i < appointments.length; i++) {
                const appointmentId = appointments[i]?.id;

                ActivityIndicator.show(`${translate.cancel_booking || '取消預約'} ${i + 1}/${appointments.length}`);

                await client.mutate({
                  mutation: APPOINTMENT_CANCEL,
                  variables: {
                    id: appointmentId,
                  },
                });
              }
            },
            {
              messages: {
                success: translate.cancel_success || '成功取消',
              },
            },
          );
        }
      }}
    >
      {translate.cancel_booking || '取消預約'}
    </SystemButton>
  );
};

export default ActionCancel;
