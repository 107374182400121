import { infinityFetch } from '../../index';
import { gql } from '@apollo/client';
import { FRAGMENT_LIST_ROSTER } from '../../../routes/list_Rosters/RosterCalendar/query';

const GET_QUERY = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $sortBy: [SorterInput!], $filter: RosterSlotFilterInput) {
    node(id: $id) {
      ... on CompanyShop {
        id
        rosterSlots(cursor: $cursor, limits: $limits, sortBy: $sortBy, filter: $filter) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_LIST_ROSTER
          }
        }
      }
    }
  }
  ${FRAGMENT_LIST_ROSTER}
`;

export default async function (source, { cursor, limits, sortBy, filter }) {
  return await infinityFetch({
    fetchPolicy: 'network-only',
    query: GET_QUERY,
    variables: {
      id: localStorage.getItem('shopId'),
      cursor,
      limits,
      sortBy,
      filter,
    },
    getConnection: (data) => data?.node?.rosterSlots,
  });
}
