import React from 'react';
import { Dialog, Grid, InputAdornment } from '@material-ui/core';
import { StateParamFormPageMaker } from '../../../components/FormPageMaker';
import { CREATE_QUERY, DELETE_QUERY, UPDATE_QUERY } from './query';
import { convertMetaArray, convertMetaObject } from '../../../shared';
import DateTimeField from '../../../components/FormPageMaker/Fields/DateTimeField';
import CustomTimeField from '../../../components/FormPageMaker/Fields/CustomTimeField';
import CheckboxField from '../../../components/FormPageMaker/Fields/CheckboxField';
import DaysOfWeekField from '../../../components/FormPageMaker/Fields/DaysOfWeekField';
import { client } from '../../../shared/apollo';
import { translate } from '../../../shared/translate';
import moment from 'moment';

const LocationRuleModalForm = ({ serviceLocationId, initialData, open, onClose, onSubmitted }) => {
  return (
    <Dialog open={open} PaperProps={{ square: true }}>
      <LocationRuleForm
        key={initialData?.id ?? 'new'}
        serviceLocationId={serviceLocationId}
        initialData={initialData}
        onClose={onClose}
        onSubmitted={onSubmitted}
      />
    </Dialog>
  );
};

export default LocationRuleModalForm;

class LocationRuleForm extends StateParamFormPageMaker {
  state = {
    ...this.state,
    id: this.props.initialData?.id,
    action: this.props.initialData?.id ? 'update' : 'create',
    gql: {
      submit: CREATE_QUERY,
      remove: DELETE_QUERY,
    },
    cards: [
      {
        padding: 16,
        fields: [
          {
            label: translate.service_location_duration_minutes || '時段長度',
            required: true,
            render: ({ values, setFieldValue, disabled }) => {
              return (
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <CustomTimeField
                      minuteStep={5}
                      required={true}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position={'start'}>{translate.start_time || '開始時間'}</InputAdornment>
                        ),
                      }}
                      disabled={disabled}
                      value={values?.timeAt}
                      onChange={(v) => setFieldValue('timeAt', v)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTimeField
                      minuteStep={5}
                      required={true}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position={'start'}>{translate.close_time || '結束時間'}</InputAdornment>
                        ),
                      }}
                      disabled={disabled}
                      value={values?.timeThru}
                      onChange={(v) => setFieldValue('timeThru', v)}
                    />
                  </Grid>
                </Grid>
              );
            },
          },
          {
            label: translate.available_date || '有效期',
            render: ({ values, setFieldValue, disabled }) => {
              return (
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <DateTimeField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position={'start'}>{translate.start_date || '開始日期'}</InputAdornment>
                        ),
                      }}
                      disabled={disabled}
                      value={values?.startedAt}
                      onChange={(v) => setFieldValue('startedAt', v)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DateTimeField
                      hasPermanent={true}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position={'start'}>{translate.end_booking_date || '完結日期'}</InputAdornment>
                        ),
                      }}
                      disabled={disabled}
                      defaultValue={values?.startedThru}
                      value={values?.startedThru}
                      onChange={(v) => setFieldValue('startedThru', v)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CheckboxField
                      size={'small'}
                      label={translate.set_available_business_days || '設定可預約工作日'}
                      checked={!!values.daysOfWeek}
                      onChange={() => {
                        if (values.daysOfWeek) setFieldValue('daysOfWeek', null);
                        else setFieldValue('daysOfWeek', []);
                      }}
                    />
                  </Grid>
                  {!!values.daysOfWeek && (
                    <Grid item xs={12}>
                      <DaysOfWeekField
                        disabled={disabled}
                        value={values.daysOfWeek}
                        onChange={(v) => {
                          setFieldValue('daysOfWeek', v);
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              );
            },
          },
          {
            required: true,
            label: translate.max_bookable_quantity || '可預約數量上限',
            name: 'capacity',
            type: 'number',
            inputProps: {
              min: 1,
              step: 1,
            },
          },
          {
            md: true,
            label: translate.sort_index,
            description: translate.display_order_description,
            type: 'number',
            name: 'sortIndex',
            inputProps: {
              step: 1,
            },
          },
          {
            md: 'auto',
            label: translate.activation,
            type: 'switch',
            name: 'active',
          },
        ],
      },
    ],
  };
  onCancelClick = () => {
    const { onClose } = this.props;
    if (onClose) onClose();
  };
  onRemoveClick = async (actions) => {
    const { onSubmitted } = this.props;
    await super.onRemoveClick(actions);
    if (onSubmitted) onSubmitted();
  };

  submit = async (values) => {
    const { serviceLocationId, onSubmitted } = this.props;
    const { id, action } = this.state;

    const input = {
      active: values.active,
      capacity: values.capacity,
      daysOfWeek: values.daysOfWeek,
      metadata: convertMetaArray(values.metadata),
      sortIndex: values.sortIndex,
      startedAt: (() => {
        const m1 = moment(values.startedAt || new Date());
        const m2 = moment(values.timeAt || '1970-01-01 00:00:00');
        m1.hours(m2.hours()).minutes(m2.minutes()).seconds(0).milliseconds(0);
        return m1.toISOString();
      })(),
      startedThru: (() => {
        const m1 = moment(values.startedThru || new Date('2999-01-01'));
        const m2 = moment(values.timeThru || '1970-01-01 00:00:00');
        m1.hours(m2.hours()).minutes(m2.minutes()).seconds(0).milliseconds(0);
        return m1.toISOString();
      })(),
    };

    if (action === 'create') {
      await client.mutate({
        mutation: CREATE_QUERY,
        variables: {
          input: {
            ...input,
            serviceLocationId,
          },
        },
      });
    } else {
      await client.mutate({
        mutation: UPDATE_QUERY,
        variables: {
          id,
          input,
        },
      });
    }

    if (onSubmitted) onSubmitted();
  };

  getInitialData() {
    const { initialData } = this.props;
    return {
      active: true,
      sortIndex: 0,
      ...initialData,
      startedThru:
        !initialData?.startedThru || /^2999/.test(initialData?.startedThru) ? null : initialData?.startedThru,

      timeAt: initialData?.startedAt,
      timeThru: initialData?.startedThru,
      metadata: convertMetaObject(initialData?.metadata),
    };
  }
}
