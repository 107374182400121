import React from 'react';
import { Grid, InputAdornment } from '@material-ui/core';
import CheckboxField from '../../../components/FormPageMaker/Fields/CheckboxField';
import DateTimeField from '../../../components/FormPageMaker/Fields/DateTimeField';
import CustomTimeField from '../../../components/FormPageMaker/Fields/CustomTimeField';
import { translate } from '../../../shared/translate';

const ServiceStartDateField = ({ disabled, value, onChange }) => {
  const startedAt = value?.[0],
    startedThru = value?.[1];

  const active = !!startedAt;

  return (
    <Grid container>
      <Grid item xs={12}>
        <CheckboxField
          size={'small'}
          label={translate.set_available_date || '設定可預約日期'}
          checked={active}
          onChange={() => {
            if (active) onChange([null, null]);
            else onChange([new Date().toISOString(), null]);
          }}
          disabled={disabled}
        />
      </Grid>
      {active && (
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <DateTimeField
              InputProps={{
                startAdornment: (
                  <InputAdornment position={'start'}>{translate.start_date || '開始日期'}</InputAdornment>
                ),
              }}
              disabled={disabled}
              value={startedAt}
              onChange={(v) => onChange([v, startedThru])}
            />
          </Grid>
          <Grid item xs={6}>
            <DateTimeField
              hasPermanent={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position={'start'}>{translate.end_booking_date || '完結日期'}</InputAdornment>
                ),
              }}
              disabled={disabled}
              defaultValue={startedThru}
              value={startedThru}
              onChange={(v) => onChange([startedAt, v])}
            />
          </Grid>
          {/*<Grid item xs={6}>*/}
          {/*  <CustomTimeField*/}
          {/*    InputProps={{*/}
          {/*      startAdornment: <InputAdornment position={'start'}>開始時間</InputAdornment>,*/}
          {/*    }}*/}
          {/*    disabled={disabled}*/}
          {/*    value={startedAt}*/}
          {/*    onChange={(v) => onChange([v, startedThru])}*/}
          {/*  />*/}
          {/*</Grid>*/}
          {/*<Grid item xs={6}>*/}
          {/*  <CustomTimeField*/}
          {/*    InputProps={{*/}
          {/*      startAdornment: <InputAdornment position={'start'}>結束時間</InputAdornment>,*/}
          {/*    }}*/}
          {/*    disabled={disabled}*/}
          {/*    value={startedThru}*/}
          {/*    onChange={(v) => onChange([startedAt, v])}*/}
          {/*  />*/}
          {/*</Grid>*/}
        </Grid>
      )}
    </Grid>
  );
};

export default ServiceStartDateField;
