import { gql } from '@apollo/client';

export const FRAGMENT_ROSTER_RULE = gql`
  fragment FRAGMENT_ROSTER_RULE on RosterRule {
    id
    updatedAt
    createdAt
    active
    metadata {
      key
      value
    }
    startedAt
    startedThru
    daysOfWeek
    staff {
      id
      name
    }
    customer {
      id
      email
    }
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $sortBy: [SorterInput!], $filter: RosterRuleFilterInput) {
    node(id: $id) {
      ... on ShopRoster {
        id
        rules(cursor: $cursor, limits: $limits, sortBy: $sortBy, filter: $filter) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_ROSTER_RULE
          }
        }
      }
    }
  }
  ${FRAGMENT_ROSTER_RULE}
`;

export const CREATE_QUERY = gql`
  mutation ($input: [RosterRuleCreateInput!]!) {
    rosterRulesCreate(input: $input) {
      id
    }
  }
`;

export const DELETE_QUERY = gql`
  mutation ($id: ID!) {
    rosterRuleDelete(id: $id) {
      id
    }
  }
`;
