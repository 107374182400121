import { Grid } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import DateRangeField from '../../components/FormPageMaker/Fields/DateRangeField';
import { translate } from '../../shared/translate';
import SnapButton from './SnapButton';
import { useSalePerformanceRange } from '../../components/SalePerformanceRangeProvider';

const FormHeader = () => {
  const [range, setRange] = useSalePerformanceRange();

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item>
          <SnapButton
            active={range?.dayMode === '0'}
            onClick={() => {
              setRange((prev) => ({
                ...prev,
                dayMode: '0',
                startedAt: moment().startOf('day').toISOString(),
                startedThru: moment().endOf('day').toISOString(),
              }));
            }}
          >
            {translate.today}
          </SnapButton>
        </Grid>
        <Grid item>
          <SnapButton
            active={range?.dayMode === '1'}
            onClick={() => {
              setRange((prev) => ({
                ...prev,
                dayMode: '1',
                startedAt: moment().subtract(7, 'days').startOf('day').toISOString(),
                startedThru: moment().endOf('day').toISOString(),
              }));
            }}
          >
            {translate.last_7_days}
          </SnapButton>
        </Grid>
        <Grid item>
          <SnapButton
            active={range?.dayMode === '2'}
            onClick={() => {
              setRange((prev) => ({
                ...prev,
                dayMode: '2',
                startedAt: moment().startOf('month').startOf('day').toISOString(),
                startedThru: moment().endOf('day').toISOString(),
              }));
            }}
          >
            {translate.this_month}
          </SnapButton>
        </Grid>
        <Grid item>
          <DateRangeField
            cusStyle={{
              width: '80px',
            }}
            fromValue={range?.startedAt}
            toValue={range?.startedThru}
            onFromValueChange={(startedAt) => {
              setRange((prev) => ({
                ...prev,
                dayMode: '3',
                startedAt,
                startedThru: range?.startedThru,
              }));
            }}
            onToValueChange={(startedThru) => {
              setRange((prev) => ({
                ...prev,
                dayMode: '3',
                startedAt: range?.startedAt,
                startedThru,
              }));
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default FormHeader;
