import { Box, Grid } from '@material-ui/core';
import React from 'react';
import CheckboxField from '../../../components/FormPageMaker/Fields/CheckboxField';
import { translate } from '../../../shared/translate';
import SelectField from '../../../components/FormPageMaker/Fields/SelectField';
import CustomFieldWrapper from '../../../components/CustomFormMetadataField/inputFields/CustomFieldWrapper';
import KeetaVariationsTable from './KeetaVariationsTable';
import FormRow from '../../../components/FormRow';
import KeetaModifiersTable from './KeetaModifiersTable';

const KeetaCard = ({
  loading,
  values: { sku, settingKeeta, variations, modifiers, modifierValues },
  setFieldValue,
  disabled,
}) => {
  const preModifiers = modifierValues?.map((mdfs) => mdfs.modifiers)?.flat() ?? [];
  const { id, active, userGetModeList, isSpecialty, isAlcoholic, skuList, choiceGroupSkuList } = settingKeeta || {};

  return (
    <Box p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CheckboxField
            loading={loading}
            disabled={disabled}
            text={translate.enable_keeta || '使用Keeta'}
            checked={active}
            onChange={() => {
              setFieldValue(
                'settingKeeta',
                active
                  ? null
                  : {
                      active: true,
                      userGetModeList: ['delivery'],
                      skuList: {},
                      choiceGroupSkuList: {},
                    },
              );
            }}
          />
        </Grid>
        {active && (
          <Grid item xs={12}>
            <Box className="p-8 border">
              <Grid container spacing={2}>
                <FormRow md={6} title={translate.pickup_method || '取餐方式'} required={true}>
                  <SelectField
                    loading={loading}
                    disabled={disabled}
                    required={true}
                    value={
                      {
                        ['delivery']: 'delivery',
                        ['pickup']: 'pickup',
                        'delivery,pickup': 'deliveryAndPickup',
                      }[userGetModeList] || ''
                    }
                    onChange={(e) => {
                      setFieldValue('settingKeeta', {
                        ...settingKeeta,
                        userGetModeList: {
                          delivery: ['delivery'],
                          pickup: ['pickup'],
                          deliveryAndPickup: ['delivery', 'pickup'],
                        }[e.target.value],
                      });
                    }}
                    options={[
                      {
                        label: translate.delivery || '送貨',
                        value: 'delivery',
                      },
                      {
                        label: translate.pick_up || '自取',
                        value: 'pickup',
                      },
                      {
                        label: translate.delivery_and_pick_up || '外送及自取',
                        value: 'deliveryAndPickup',
                      },
                    ]}
                  />
                </FormRow>
                <Grid item xs={0} md={6} />

                <Grid item xs={12}>
                  <Grid container spacing={5}>
                    <Grid item>
                      <CheckboxField
                        loading={loading}
                        disabled={disabled}
                        text={translate.chefs_picks || '主廚推介'}
                        checked={isSpecialty}
                        onChange={() => setFieldValue('settingKeeta', { ...settingKeeta, isSpecialty: !isSpecialty })}
                      />
                    </Grid>
                    <Grid item>
                      <CheckboxField
                        loading={loading}
                        disabled={disabled}
                        text={translate.contains_alcohol || '含酒精'}
                        checked={isAlcoholic}
                        onChange={() => setFieldValue('settingKeeta', { ...settingKeeta, isAlcoholic: !isAlcoholic })}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <FormRow xs={12} title={translate.price_setting || '價格設定'}>
                  <KeetaVariationsTable
                    userGetModeList={userGetModeList ?? []}
                    disabled={disabled}
                    variations={variations}
                    value={skuList}
                    onChange={(v) =>
                      setFieldValue('settingKeeta', {
                        ...settingKeeta,
                        skuList: v,
                      })
                    }
                  />
                </FormRow>
                <FormRow xs={12} title={translate.additional_fee}>
                  <KeetaModifiersTable
                    sku={sku}
                    userGetModeList={userGetModeList ?? []}
                    disabled={disabled}
                    modifiers={preModifiers.concat(modifiers)}
                    value={choiceGroupSkuList}
                    onChange={(v) =>
                      setFieldValue('settingKeeta', {
                        ...settingKeeta,
                        choiceGroupSkuList: v,
                      })
                    }
                  />
                </FormRow>
              </Grid>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default KeetaCard;
