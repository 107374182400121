import React from 'react';
import { translate } from '../../../shared/translate';

export default {
  option: {
    group: 'PayPal',
    icon: <img className="h-4" src={require('../../../assets/paypal-logo.png')} alt="paypal" />,
    label: 'PayPal',
    value: 'PAYPAL_STANDARD',
    credentialPlatform: 'PAYPAL',
  },
  form: (actions) => {
    return null;
  },
};
