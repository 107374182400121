import React from 'react';
import { translate } from '../../../shared/translate';

export default {
  option: {
    group: translate.recommend,
    icon: <img className="h-4" src={require('../../../assets/unopay.png')} alt="unopay" />,
    label: 'UNOPay',
    value: 'STRIPE_CONNECT_CUSTOM',
    credentialPlatform: 'STRIPE_CONNECT_CUSTOM',
  },
  form: (actions) => {
    return null;
  },
};
