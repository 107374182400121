import React from 'react';
import FormPageMaker from '../../components/FormPageMaker';
import { translate } from '../../shared/translate';
import TargetCard from './TargetCard';
import moment from 'moment';
import { cloneObject, convertMetaArray, convertMetaObject, parseConnection, removeTypename } from '../../shared';
import { DELETE_QUERY, UPDATE_QUERY, CREATE_QUERY, GET_QUERY } from './query';
import { client } from '../../shared/apollo';
import ExcludedGiftsCard from './ExcludedGiftsCard';
import CopyButton from '../../components/CopyButton';
import { Grid, Typography } from '@material-ui/core';
import ActionCard from './ActionCard';
import { parseInputAction, parseInputTrigger } from '../item_Coupon/utils';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: true,
      remove: DELETE_QUERY,
    },
    tabs: [
      {
        name: translate.gift_details || '贈品詳情',
        cards: [
          {
            fields: [
              {
                md: 12,
                render: () => (
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="h6">{translate.gift}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        {translate.gifts_description ||
                          '「贈品」是自動贈送的產品；客戶結帳後，目標產品會以免費且不參與任何折扣的形式添加到訂單。'}
                      </Typography>
                    </Grid>
                  </Grid>
                ),
              },
              {
                type: 'text',
                label: translate.name + ' ' + translate.zh,
                name: 'metadata.name_hk',
                required: true,
                maxLength: 85,
                md: 12,
              },
              {
                type: 'text',
                label: translate.name + ' ' + translate.en,
                name: 'metadata.name_en',
                required: true,
                maxLength: 85,
                md: 12,
              },
              {
                type: 'checkbox',
                label: translate.eligible_membership_only,
                name: 'membersOnly',
                md: 'auto',
              },
              {
                type: 'checkbox',
                label: translate.discount_discount_after_expiry,
                name: 'discardSubsequent',
                md: 'auto',
              },
              {
                type: 'number',
                label: translate.coupon_order,
                name: 'sortIndex',
                required: true,
                inputProps: {
                  step: 1,
                  min: 0,
                },
                description: translate.coupon_order_description,
              },
              {
                type: 'datetime-local',
                label: translate.valid_start_date_time,
                name: 'publishAt',
                md: 12,
              },
              {
                type: 'datetime-local',
                label: translate.end_date_time,
                name: 'publishThru',
                md: 12,
              },
            ],
          },
        ],
      },
      {
        name: translate.offer_triggers_and_actions,
        cards: [
          TargetCard,
          ActionCard,
          {
            fields: [
              {
                type: 'number',
                label: translate.discount_repeat_times,
                md: 3,
                inputProps: {
                  min: 1,
                  step: 1,
                },
                name: 'repeat',
              },
            ],
          },
          ExcludedGiftsCard,
        ],
      },
    ],
  };

  renderExtraButtons({ loading, values }) {
    const { id } = this.state;
    if (!id || loading) return null;
    return (
      <CopyButton
        getCopyValues={() => {
          const clone = cloneObject(values);
          return {
            ...clone,
            id: undefined,
            handle: undefined,
          };
        }}
        disabled={loading}
        url={'/gifts/new'}
      />
    );
  }

  submit = async (values) => {
    const input = {
      name: values.metadata.name_hk,
      description: '',
      remark: values.remark,
      sortIndex: values.sortIndex,
      discardSubsequent: values.discardSubsequent,
      triggers: [parseInputTrigger(values.trigger)],
      actions: [parseInputAction(values.action)],
      publishAt: values.publishAt,
      publishThru: values.publishThru,
      membersOnly: values.membersOnly,
      active: values.active,
      repeat: values.repeat,
      excludedGiftIds: values.excludedGifts.map((g) => g.id),
      metadata: convertMetaArray(values.metadata),
    };

    if (this.state.id) {
      await client.mutate({
        mutation: UPDATE_QUERY,
        variables: {
          id: this.state.id,
          input,
        },
      });
    } else {
      await client.mutate({
        mutation: CREATE_QUERY,
        variables: {
          input: {
            ...input,
            companyId: localStorage.getItem('companyId'),
          },
        },
      });
    }

    return true;
  };

  getInitialData({ node } = {}) {
    const now = moment().toISOString(),
      nextMonth = moment().add(1, 'month').toISOString();
    const { copyValues } = this.state || {};

    const {
      id,
      updatedAt,
      name,
      description,
      sortIndex = 0,
      publishAt = now,
      publishThru = nextMonth,
      active = true,
      repeat = 1,
      membersOnly = true,
      discardSubsequent = false,
      excludedGifts,
      metadata,
      triggers,
      actions,
    } = node || {};

    return {
      id,
      updatedAt,
      name: name || convertMetaObject(metadata).name_hk,
      description,
      sortIndex,
      publishAt,
      publishThru,
      active,
      repeat,
      membersOnly,
      discardSubsequent,
      metadata: {
        name_hk: name,
        name_en: name,
        ...convertMetaObject(metadata),
      },
      excludedGifts: cloneObject(parseConnection(excludedGifts).nodes),
      trigger: {
        ...(removeTypename(cloneObject(triggers))?.[0] ?? {}),
      },
      action: {
        actionType: 'VARIATION',
        ...(removeTypename(cloneObject(actions))?.[0] ?? {}),
      },
      ...copyValues,
    };
  }
}
