import React from 'react';
import { Box } from '@material-ui/core';
import Media from './Media';

export default ({
  loading,
  disabled,
  src = '',
  optimizedSrc,
  resizeMode = 'contain',
  children,
  style = {},
  placeholderColor = '#fff',
  onClick,
  isPlaceholder,
  ...props
}) => {
  return (
    <div
      style={{
        display: 'inline-block',
        background: optimizedSrc ? `url("${optimizedSrc}") no-repeat center center / ${resizeMode}` : placeholderColor,
        width: '100px',
        height: '100px',
      }}
    >
      {isPlaceholder ? (
        <Box
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: 'rgb(220 220 220 / 20%)',
            gap: '5px',
          }}
        >
          <Media
            disabled={true}
            src={loading ? require('assets/gray.jpg') : require('assets/image_placeholder.jpg')}
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </Box>
      ) : (
        <div
          style={{
            background: src ? `url("${src}") no-repeat center center / ${resizeMode}` : placeholderColor,
            width: '100%',
            height: '100%',
          }}
        />
      )}
    </div>
  );
};
