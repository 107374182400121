import React from 'react';
import { translate } from '../../../shared/translate';

export default {
  option: {
    group: `KPAY ${translate.offline || '（線下）'}`,
    icon: <img className="h-4" src={require('../../../assets/Logo_KPay.png')} alt="kpay" />,
    label: translate.kpay_qr_code_scan || 'KPAY 綜合刷碼支付',
    value: 'KPAY_POS_SCAN',
    credentialPlatform: 'KPAY_POS',
  },
  form: (actions) => {
    return null;
  },
};
