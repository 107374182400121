import React from 'react';
import { translate } from '../../../shared/translate';

export default {
  option: {
    group: `EFT ${translate.online || '（線上）'}`,
    icon: <img className="h-4" src={require('../../../assets/eft-logo.png')} alt="eft" />,
    label: translate.eft_fps || 'EFT 轉數快',
    value: 'EFT_FPS',
    credentialPlatform: 'EFT',
  },
  form: (actions) => {
    return null;
  },
};
