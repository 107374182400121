export const convertKeetaInput2Setting = (input) => {
  if (!input) return undefined;
  return {
    ...(input ?? {}),
    skuList:
      input?.skuList?.reduce((reducer, opt) => {
        reducer[opt?.sku] = opt;
        return reducer;
      }, {}) ?? {},
    choiceGroupSkuList:
      input?.choiceGroupSkuList?.reduce((reducer, opt) => {
        reducer[opt?.id] = opt;
        return reducer;
      }, {}) ?? {},
  };
};

export const convertKeetaSetting2Input = (settingKeeta, values) => {
  if (!settingKeeta) return null;

  const preModifiers = values?.modifierValues?.map((mdfs) => mdfs.modifiers)?.flat() ?? [];

  return {
    userGetModeList: settingKeeta?.userGetModeList,
    isSpecialty: settingKeeta?.isSpecialty,
    isAlcoholic: settingKeeta?.isAlcoholic,
    skuList:
      values.variations?.reduce((reducer, variation) => {
        const setting = settingKeeta?.skuList[variation?.sku];
        if (setting && setting?.sku)
          reducer.push({
            sku: setting.sku,
            price: setting.price || 0,
            pickPrice: setting.pickPrice || 0,
          });
        return reducer;
      }, []) ?? [],
    choiceGroupSkuList:
      preModifiers.concat(values.modifiers)?.reduce((reducer, modifier) => {
        for (const option of modifier?.options) {
          const key = `${values.sku}#${modifier?.name}#${option?.name}`;
          const setting = settingKeeta?.choiceGroupSkuList[key];
          if (setting && setting?.id)
            reducer.push({
              id: setting.id,
              price: setting.price || 0,
              pickPrice: setting.pickPrice || 0,
            });
        }
        return reducer;
      }, []) ?? [],
  };
};
