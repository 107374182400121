import React, { Fragment } from 'react';
import { Card, InputAdornment, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { translate } from '../../../shared/translate';
import InputField from '../../../components/FormPageMaker/Fields/InputField';
import CheckboxField from '../../../components/FormPageMaker/Fields/CheckboxField';
import { omit } from 'lodash';

const KeetaModifiersTable = ({
  sku,
  userGetModeList = [],
  disabled,
  modifiers = [],
  value = {},
  onChange = (_) => _,
}) => {
  const allOptions = (
    modifiers?.map((modifier) => modifier?.options?.map((option) => ({ modifier, option })) ?? []) ?? []
  ).flat();

  return (
    <Card variant={'outlined'}>
      <Table style={{ width: '100%' }} size={'small'}>
        <TableHead style={{ backgroundColor: '#eee' }}>
          <TableCell width={50}>
            {allOptions.length > 0 && (
              <CheckboxField
                disabled={disabled}
                checked={Object.keys(value).length === allOptions.length}
                indeterminate={Object.keys(value).length > 0 && Object.keys(value).length < allOptions.length}
                onChange={() => {
                  if (Object.keys(value).length === allOptions.length) {
                    onChange({});
                  } else {
                    onChange(
                      allOptions.reduce((reducer, { modifier, option }) => {
                        const id = `${sku}#${modifier?.name}#${option?.name}`;
                        reducer[id] = {
                          id,
                          price: option?.priceAdjustment || 0,
                          pickPrice: option?.priceAdjustment || 0,
                        };
                        return reducer;
                      }, {}),
                    );
                  }
                }}
              />
            )}
          </TableCell>
          <TableCell>{translate.variant_content}</TableCell>
          <TableCell width={150}>{translate.take_out_price || '外賣價'}</TableCell>
          <TableCell width={150}>{translate.pick_up_price || '自取價'}</TableCell>
        </TableHead>
        <TableBody>
          {!allOptions.length && (
            <TableRow>
              <TableCell colSpan={99}>
                <Typography align={'center'}>{translate.no_data}</Typography>
              </TableCell>
            </TableRow>
          )}
          {allOptions.map(({ modifier, option }, i) => {
            const canDelivery = userGetModeList.includes('delivery');
            const canPickup = userGetModeList.includes('pickup');

            const id = `${sku}#${modifier?.name}#${option?.name}`;
            const val = value[id];
            return (
              <TableRow key={id || i} style={{ backgroundColor: !val ? '#00000011' : undefined }}>
                <TableCell>
                  <CheckboxField
                    disabled={disabled}
                    checked={!!val}
                    onChange={() => {
                      if (!!val) {
                        onChange(omit(value, id));
                      } else {
                        onChange({
                          ...value,
                          [id]: {
                            id,
                            price: option?.priceAdjustment || 0,
                            pickPrice: option?.priceAdjustment || 0,
                          },
                        });
                      }
                    }}
                  />
                </TableCell>
                <TableCell>
                  {modifier?.name} {option?.name}
                </TableCell>
                <TableCell style={{ opacity: !canDelivery ? 0.25 : 1 }}>
                  <InputField
                    disabled={disabled || !canDelivery || !val}
                    value={val?.price}
                    type={'number'}
                    inputProps={{
                      min: 0,
                      step: 0.01,
                    }}
                    onChange={(e) => {
                      val.price = e.target.value;
                      onChange(value);
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </TableCell>
                <TableCell style={{ opacity: !canPickup ? 0.25 : 1 }}>
                  <InputField
                    disabled={disabled || !canPickup || !val}
                    value={val?.pickPrice}
                    type={'number'}
                    inputProps={{
                      min: 0,
                      step: 0.01,
                    }}
                    onChange={(e) => {
                      val.pickPrice = e.target.value;
                      onChange(value);
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Card>
  );
};

export default KeetaModifiersTable;
