import React, { Fragment } from 'react';
import { InputAdornment, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { StateParamsConnectionPageMarker } from '../../../components/ConnectionPageMaker';
import InputField from '../../../components/FormPageMaker/Fields/InputField';
import SelectComboVariation from '../../../components/SelectCombo/SelectComboVariation';
import { translate } from '../../../shared/translate';
import { binColor, removeBtnStyle } from '../../../theme';
import TrashButton from '../../../components/TrashButton';
import { sleep } from '../../../shared';
import ConnectionPagination from '../../../components/ConnectionPageMaker/ConnectionPagination';

const useStyles = (theme) => ({
  removeBtn: removeBtnStyle,
});
export class ItemsTable extends StateParamsConnectionPageMarker {
  state = {
    ...this.state,
    skip: true,
    limits: 25,
    refreshable: false,
    refreshStockKey: 0,
    fields: [
      {
        // title: `${translate.product} *`,
        title: translate.product,
        width: 350,
        render: (item) => {
          const { cursor = 0 } = this.state;
          const { items, onChange, disabled } = this.props;
          const { variation, add, sku, __row } = item || {};

          return (
            <SelectComboVariation
              disablePortal={false}
              id={!!add && 'add'}
              value={!add ? variation : null}
              showSubtitle={false}
              placeholder={sku}
              key={items?.length}
              onChange={(variation) => {
                if (!!add) {
                  onChange(
                    (items || [])?.concat({
                      variation,
                      sku: variation?.sku,
                      quantity: 1,
                      cost: 0,
                    }),
                  );
                  sleep(0).then(this.goToLastPage);
                  return;
                }
                onChange(
                  items.map((item, i) =>
                    i === cursor + __row
                      ? {
                          ...item,
                          variation: variation ? variation : undefined,
                          sku: variation ? variation?.sku : undefined,
                        }
                      : item,
                  ),
                );
              }}
              disabled={disabled}
            />
          );
        },
      },
      {
        title: `${translate.quantity}`,
        width: 120,
        align: 'center',
        render: (item) => {
          const { cursor = 0 } = this.state;
          const { items, onChange, disabled } = this.props;
          const { sku, quantity, __row } = item || {};
          return (
            <InputField
              type={'number'}
              // eslint-disable-next-line
              inputProps={{
                min: 1,
                step: 1,
                style: { textAlign: 'center' },
              }}
              disabled={!sku || disabled}
              value={quantity}
              onChange={(e) => {
                onChange(
                  items.map((item, i) =>
                    i === cursor + __row
                      ? {
                          ...item,
                          quantity: e.target.value,
                        }
                      : item,
                  ),
                );
              }}
              required={!item?.add}
            />
          );
        },
      },
      {
        title: `${translate.unit_cost}`,
        width: 120,
        align: 'center',
        render: (item) => {
          const { cursor = 0 } = this.state;
          const { items, onChange, status, isSubmitting, disabled } = this.props;
          const { cost, __row } = item || {};

          return (
            <InputField
              type={'number'}
              inputProps={{
                min: 0,
                step: 0.01,
                style: { textAlign: 'center' },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position={'start'}>
                    <Typography>$</Typography>
                  </InputAdornment>
                ),
              }}
              required={true}
              disabled={!!item?.add || disabled || (!!status && status !== 'PENDING') || isSubmitting}
              value={!!cost ? +(+cost || 0).toFixed(2) : undefined}
              onChange={(e) => {
                onChange(
                  items.map((item, i) =>
                    i === cursor + __row
                      ? {
                          ...item,
                          cost: e.target.value,
                        }
                      : item,
                  ),
                );
              }}
            />
          );
        },
      },
      {
        title: `${translate.total_cost}`,
        width: 120,
        align: 'center',
        render: (item) => {
          const { cursor = 0 } = this.state;
          const { items, onChange, status, isSubmitting, disabled } = this.props;
          const { cost, quantity, __row } = item || {};
          return (
            <InputField
              type={'number'}
              inputProps={{
                min: 0,
                step: 0.01,
                style: { textAlign: 'center' },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position={'start'}>
                    <Typography>$</Typography>
                  </InputAdornment>
                ),
              }}
              required={true}
              disabled={!!item?.add || disabled || (!!status && status !== 'PENDING') || isSubmitting}
              value={!!quantity && !!cost ? +((+quantity || 0) * (+cost || 0)).toFixed(2) : undefined}
              onChange={(e) => {
                onChange(
                  items.map((item, i) =>
                    i === cursor + __row
                      ? {
                          ...item,
                          cost: e.target.value / (quantity || 0),
                        }
                      : item,
                  ),
                );
              }}
            />
          );
        },
      },
      {
        title: '',
        width: 76,
        align: 'center',
        render: ({ add, __row }) => {
          const { cursor = 0 } = this.state;
          const { disabled, items, onChange, status } = this.props;
          if (add || status === 'COMPLETED' || status === 'VOIDED') {
            return <></>;
          } else {
            return (
              <TrashButton
                disabled={disabled}
                onClick={() => onChange(items.filter((__, i) => i !== cursor + __row))}
              />
            );
          }
        },
      },
    ],
  };

  renderNoData() {
    return <></>;
  }

  getData(data = {}) {
    const { cursor, limits } = this.state;
    const { items } = this.props;
    return {
      nodes: (items ?? [])
        .concat({
          add: true,
        })
        .slice(cursor ?? 0, (cursor ?? 0) + limits),
      totalCount: (items?.length ?? 0) + 1,
      nextCursor: null,
    };
  }

  renderWrapper(res) {
    const { loading } = this.props;
    return this.renderContent({ ...res, loading });
  }

  renderPagination({ loading, data: { nextCursor, totalCount } = {} }) {
    const { cursor, limits, refreshable } = this.state;
    return (
      <ConnectionPagination
        loading={loading}
        totalCount={totalCount}
        totalCountDisplay={totalCount - 1}
        cursor={cursor}
        limits={limits}
        onRefreshClick={
          !!refreshable
            ? () => {
                this.refetch && this.refetch();
              }
            : undefined
        }
        onCursorChange={(cursor) => {
          this.writeQueryParams({ cursor });
        }}
      />
    );
  }
}
ItemsTable.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};
ItemsTable.defaultProps = {
  items: [],
  onChange: (_) => _,
};

export default withStyles(useStyles)(ItemsTable);
