import React from 'react';
import { Card, CardContent } from '@material-ui/core';
import { translate } from '../../../shared/translate';
import FormRow from '../../../components/FormRow';
import VariationSkusField from '../TargetCard/VariationSkusField';

const ActionCard = ({ loading, values, values: { action }, setFieldValue, disabled }) => {
  return (
    <Card square elevation={0}>
      <CardContent>
        <FormRow title={translate.gift} required={true}>
          <VariationSkusField
            loading={loading}
            disabled={disabled}
            value={action?.actionIds}
            onChange={(ids, nodes) => {
              setFieldValue('action', { ...action, actionIds: ids, _actionNodes: nodes });
            }}
          />
        </FormRow>
      </CardContent>
    </Card>
  );
};

export default ActionCard;
