import React from 'react';
import { translate } from '../../../shared/translate';

export default {
  option: {
    group: `BBMSL ${translate.offline || '（線下）'}`,
    icon: <img className="h-4" src={require('../../../assets/BBMSL.png')} alt="BBMSL" />,
    label: `BBMSL ${translate.union_pay_intl || '雲閃付'}`,
    value: 'BBMSL_POS_QUICKPASS',
    credentialPlatform: 'BBMSL',
  },
  form: (actions) => {
    return null;
  },
};
