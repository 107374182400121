import React from 'react';
import ConnectionPageMaker from '../../../components/ConnectionPageMaker';
import { translate } from 'shared/translate';
import { GET_QUERY } from './query';
import ActiveSwitcher from './ActiveSwitcher';
import EllipsisTypography from '../../../components/EllipsisTypography';

export default class extends ConnectionPageMaker {
  state = {
    ...this.state,
    gql: { get: GET_QUERY },
    createUrl: '/gifts/new',
    getRowLink: ({ id, name }) => ({ pathname: '/gifts/' + id, state: { title: name } }),
    fields: [
      {
        title: translate.name,
        fieldName: 'name',
        sortBy: 'name',
      },
      {
        title: translate.type,
        render: () => <EllipsisTypography noWrap>{translate.gift}</EllipsisTypography>,
        width: 100,
      },
      {
        title: translate.coupon_order,
        fieldName: 'sortIndex',
        type: 'number',
        sortBy: 'sortIndex',
        filter: 'sortIndex',
        width: 100,
      },
      {
        title: translate.start_date_time,
        width: 180,
        align: 'right',
        fieldName: 'publishAt',
        type: 'datetime',
        filter: 'publishAt',
        sortBy: 'publishAt',
      },
      {
        title: translate.end_date_time,
        width: 180,
        align: 'right',
        fieldName: 'publishThru',
        type: 'datetime',
        filter: 'publishThru',
        sortBy: 'publishThru',
      },
      {
        title: translate.status,
        width: 100,
        render: (data) => {
          const { id, active } = data || {};
          return <ActiveSwitcher id={id} data={data} active={active} onCompleted={this.refetch} />;
        },
        filter: 'active',
        type: 'bool',
        sortBy: 'active',
        noLink: true,
      },
    ],
    menus: [],
    selectionMode: true,
  };
  getData = ({ node } = {}) => {
    const { gifts } = node || {};
    return gifts;
  };
  getExtraFetchVariables() {
    return { id: localStorage.getItem('companyId') };
  }
  renderWrapper(...args) {
    return this.renderContent(...args);
  }
}
