import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import SwitchField from '../../components/FormPageMaker/Fields/SwitchField';
import { translate } from '../../shared/translate';

const DisallowStaffSummaryField = ({ value, onChange, disabled }) => {
  return (
    <Grid container justifyContent={'space-between'}>
      <Grid item xs={true} md={9}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant={'h6'}>{translate.allow_staff_summary || '允許員工查看及列印日結報表'}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'}>
              {translate.allow_staff_summary_remark ||
                '若關閉此功能，員工在 POS 查看或列印日結報表時，需輸入店長 PIN 碼'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <SwitchField
          checked={!value}
          disabled={disabled}
          onChange={() => {
            onChange(!value);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default DisallowStaffSummaryField;
