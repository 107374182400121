import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import moment from 'moment';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { ReactComponent as IconNotEnoughData } from '../../../assets/icon/not enough data.svg';
import { intervalToDuration, parseISO } from 'date-fns';
import { translate } from '../../../shared/translate';
import _ from 'lodash';
import { useSalePerformanceRange } from '../../../components/SalePerformanceRangeProvider';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const LineChartCard = ({ loading, label, datasets: _datasets }) => {
  const [range] = useSalePerformanceRange();
  const dayMode = range?.dayMode,
    startedAt = range?.startedAt,
    startedThru = range?.startedThru;

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        align: 'end',
      },
      title: {
        display: false,
      },
    },
  };

  const duration = intervalToDuration({
    start: parseISO(startedAt ?? moment().startOf('day').toISOString()),
    end: parseISO(startedThru ?? moment().endOf('day').toISOString()),
  });
  const showByMonth = duration.years > 1 || duration.months > 1 ? true : false;
  const diffUnit = { 0: 'hours', 1: 'days', 2: 'days', 3: showByMonth ? 'months' : 'days' }[dayMode] || 'days';
  const dateFormat = { hours: 'HH:mm:ss', days: 'DD/MM', months: 'MMMM, YYYY' }[diffUnit] || 'DD/MM';
  const diff = Math.abs(moment(startedAt).diff(moment(startedThru), diffUnit)) || 7;

  const colors = [
    '#88c5f1',
    '#98e37e',
    '#ffcb7f',
    '#f44336',
    '#9c27b0',
    '#673ab7',
    '#3f51b5',
    '#2196f3',
    '#03a9f4',
    '#00bcd4',
    '#009688',
    '#4caf50',
    '#8bc34a',
    '#cddc39',
    '#ffeb3b',
    '#ffc107',
    '#ff9800',
    '#e91e63',
    '#ff5722',
    '#795548',
    '#9e9e9e',
    '#607d8b',
  ];

  const datasets = _datasets.map((dataset, i) => ({
    ...dataset,
    data: _.sortBy(dataset.data, 'x').map(({ x, y }) => ({
      x: moment(x).startOf('day').format(dateFormat),
      y,
    })),
    backgroundColor: (colors || [])[i],
    borderColor: (colors || [])[i],
  }));

  const labels = Array(diff)
    .fill(undefined)
    .map((_, i) => {
      return moment(startedAt).startOf('day').add(i, diffUnit).format(dateFormat);
    });

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant={'h6'} color={'secondary'}>
              {label}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <IconNotEnoughData width={'100%'} />
            ) : !datasets.length ? (
              <Typography variant="h6" align="center">
                {translate.no_info || '沒有資料'}
              </Typography>
            ) : (
              <Line
                options={options}
                data={{
                  labels,
                  datasets,
                }}
              />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default LineChartCard;
