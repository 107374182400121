import React from 'react';
import { translate } from '../../../shared/translate';

export default {
  option: {
    group: `${translate.qfpay || '錢方'} ${translate.offline || '（線下）'}`,
    icon: <img className="h-4" src={require('../../../assets/QFPay Logo-p-500.png')} alt="qf" />,
    label: translate.integrated_credit_card_payment || '綜合信用卡支付',
    value: 'QFPAY_POS_CARD',
    credentialPlatform: 'QFPAY_POS',
  },
  form: (actions) => {
    return null;
  },
};
