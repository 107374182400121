import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from '../../../components/IconRender';
import moment from 'moment';
import { translate } from '../../../shared/translate';
import OverlayLoading from '../../../components/OverlayLoading';

const CustomToolBar = ({ loading, label, view, onView, onNavigate }) => {
  const disabled = loading;

  const handleNavigate = (action) => {
    onNavigate(action);
  };

  return (
    <div style={{ marginBottom: 16 }}>
      <div style={{ height: 16 }} />
      <Grid container justifyContent={'center'} alignItems={'center'}>
        <Grid item>
          <IconButton disabled={disabled} onClick={() => handleNavigate('PREV')}>
            <Icon icon={'faChevronLeft'} />
          </IconButton>
        </Grid>
        <Grid item>
          <Button disabled={disabled} variant={'text'} onClick={() => handleNavigate('TODAY')}>
            {
              {
                month: (
                  <Typography className="rbc-toolbar-label" variant={'h5'}>
                    {translate[moment(label, 'MMM YYYY').format('MMMM')?.toLowerCase()]}
                    {moment(label, 'MMM YYYY').format('YYYY')}
                  </Typography>
                ),
                week: (
                  <Typography className="rbc-toolbar-label" variant={'h5'}>
                    {label}
                  </Typography>
                ),
                agenda: (
                  <Typography className="rbc-toolbar-label" variant={'h5'}>
                    {label}
                  </Typography>
                ),
              }[view]
            }
            {loading && <OverlayLoading />}
          </Button>
        </Grid>
        <Grid item>
          <IconButton disabled={disabled} onClick={() => handleNavigate('NEXT')}>
            <Icon icon={'faChevronRight'} />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomToolBar;
