import React from 'react';
import { translate } from '../../../shared/translate';

export default {
  option: {
    group: 'FreightAmigo',
    icon: <img className="h-4" src={require('../../../assets/freightAmigo-logo.png')} alt="FreightAmigo" />,
    label: `FreightAmigo ${translate.wechat_pay_cn}` || 'FreightAmigo 微信支付（中國）',
    value: 'FREIGHT_AMIGO_WECHAT_CN',
    credentialPlatform: 'FREIGHT_AMIGO_PAYMENT',
  },
  form: (actions) => {
    return null;
  },
};
