import React from 'react';
import { translate } from '../../../shared/translate';

export default {
  option: {
    group: `KPAY ${translate.online || '（線上）'}`,
    icon: <img className="h-4" src={require('../../../assets/Logo_KPay.png')} alt="kpay" />,
    label: translate.kpay_alipay || 'KPAY 支付寶',
    value: 'KPAY_ALIPAY_SALE_QR',
    credentialPlatform: 'KPAY',
  },
  form: (actions) => {
    return null;
  },
};
