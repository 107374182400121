import React from 'react';
import { translate } from '../../../shared/translate';

export default {
  option: {
    group: 'SoePay',
    icon: <img className="h-4" src={require('../../../assets/soepay_logo.svg')} alt="soepay" />,
    label: translate.qr_code_scan || '綜合刷碼支付',
    value: 'SOEPAY_POS_QR',
    credentialPlatform: 'SOEPAY_POS',
  },
  form: (actions) => {
    return null;
  },
};
