import React from 'react';
import { Card, CardContent, Grid, Box } from '@material-ui/core';
import { translate, translate as t } from 'shared/translate';
import FormRow from 'components/FormRow';
import LSkeleton from '../../../components/LSkeleton';
import SelectField from '../../../components/FormPageMaker/Fields/SelectField';
import TriggerVariationField from './TriggerVariationField';
import TriggerOrderField from './TriggerOrderField';

export default ({ loading, values, values: { trigger }, setFieldValue, disabled }) => {
  return (
    <Card square elevation={0}>
      <CardContent>
        <Grid container direction={'column'}>
          <FormRow title={t.condition_setting} required={true}>
            {loading ? (
              <LSkeleton height={30} />
            ) : (
              <Card variant={'outlined'}>
                <SelectField
                  variant={'standard'}
                  InputProps={{
                    style: { backgroundColor: trigger?.triggerType ? '#fafafa' : undefined, padding: 8 },
                    disableUnderline: true,
                  }}
                  required={true}
                  disabled={disabled}
                  options={[
                    {
                      label: translate.condition_designated_product || '當購買指定產品',
                      value: 'VARIATION',
                    },
                    {
                      label: translate.condition_order || '當訂單達到指定條件',
                      value: 'ORDER',
                    },
                  ]}
                  value={trigger?.triggerType}
                  onChange={(e) =>
                    setFieldValue('trigger', {
                      triggerValueType: 'QUANTITY',
                      ...trigger,
                      triggerType: e?.target?.value,
                    })
                  }
                />
                {!!trigger?.triggerType && (
                  <Box p={2}>
                    {
                      {
                        VARIATION: (
                          <TriggerVariationField
                            disabled={disabled}
                            value={trigger}
                            onChange={(v) => setFieldValue('trigger', v)}
                          />
                        ),
                        ORDER: (
                          <TriggerOrderField
                            disabled={disabled}
                            value={trigger}
                            onChange={(v) => setFieldValue('trigger', v)}
                          />
                        ),
                      }[trigger?.triggerType]
                    }
                  </Box>
                )}
              </Card>
            )}
          </FormRow>
        </Grid>
      </CardContent>
    </Card>
  );
};
