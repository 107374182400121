import React from 'react';
import { Dialog, Grid, InputAdornment } from '@material-ui/core';
import { StateParamFormPageMaker } from '../../../components/FormPageMaker';
import { CREATE_QUERY, DELETE_QUERY } from './query';
import DateTimeField from '../../../components/FormPageMaker/Fields/DateTimeField';
import CustomTimeField from '../../../components/FormPageMaker/Fields/CustomTimeField';
import DaysOfWeekField from '../../../components/FormPageMaker/Fields/DaysOfWeekField';
import { client } from '../../../shared/apollo';
import { translate } from '../../../shared/translate';
import moment from 'moment';
import { RadioField } from '../../../components/CustomFormMetadataField/inputFields';
import StaffsField from './StaffsField';
import CustomersField from './CustomersField';

const RosterRuleModalForm = ({ initialData, rosterId, startedAt, startedThru, open, onClose, onSubmitted }) => {
  return (
    <Dialog open={open} PaperProps={{ square: true }}>
      <RosterRuleForm
        key={initialData?.id ?? 'new'}
        initialData={initialData}
        rosterId={rosterId}
        startedAt={startedAt}
        startedThru={startedThru}
        onClose={onClose}
        onSubmitted={onSubmitted}
      />
    </Dialog>
  );
};

export default RosterRuleModalForm;

class RosterRuleForm extends StateParamFormPageMaker {
  state = {
    ...this.state,
    id: this.props.initialData?.id,
    action: 'create',
    gql: {
      submit: CREATE_QUERY,
      remove: DELETE_QUERY,
    },
    cards: [
      {
        padding: 16,
        fields: [
          {
            label: translate.available_date || '有效期',
            render: ({ values, setFieldValue, disabled }) => {
              return (
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <DateTimeField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position={'start'}>{translate.start_date || '開始日期'}</InputAdornment>
                        ),
                      }}
                      inputProps={{
                        min: moment(values.initialStartedAt).format('YYYY-MM-DD'),
                        max: moment(values.initialStartedThru).format('YYYY-MM-DD'),
                      }}
                      disabled={disabled}
                      value={values?.startedAt}
                      onChange={(v) => setFieldValue('startedAt', v)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DateTimeField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position={'start'}>{translate.end_booking_date || '完結日期'}</InputAdornment>
                        ),
                      }}
                      inputProps={{
                        min: moment(values.initialStartedAt).format('YYYY-MM-DD'),
                        max: moment(values.initialStartedThru).format('YYYY-MM-DD'),
                      }}
                      disabled={disabled}
                      value={values?.startedThru}
                      onChange={(v) => setFieldValue('startedThru', v)}
                    />
                  </Grid>
                </Grid>
              );
            },
          },
          {
            label: translate.working_days || '工作日',
            render: ({ values, setFieldValue, disabled }) => {
              return (
                <DaysOfWeekField
                  disabled={disabled}
                  value={values.daysOfWeek}
                  onChange={(v) => {
                    setFieldValue('daysOfWeek', v);
                  }}
                />
              );
            },
          },

          {
            label: translate.service_location_duration_minutes || '時段長度',
            required: true,
            render: ({ values, setFieldValue, disabled }) => {
              return (
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <CustomTimeField
                      minuteStep={60}
                      required={true}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position={'start'}>{translate.start_time || '開始時間'}</InputAdornment>
                        ),
                      }}
                      disabled={disabled}
                      value={values?.timeAt}
                      onChange={(v) => setFieldValue('timeAt', v)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTimeField
                      minuteStep={60}
                      required={true}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position={'start'}>{translate.close_time || '結束時間'}</InputAdornment>
                        ),
                      }}
                      disabled={disabled}
                      value={values?.timeThru}
                      onChange={(v) => setFieldValue('timeThru', v)}
                    />
                  </Grid>
                </Grid>
              );
            },
          },
          {
            required: true,
            type: 'radio',
            render: (props) => {
              const { values, setFieldValue, disabled } = props;

              return (
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <RadioField
                      disabled={disabled}
                      required={true}
                      field={{
                        values: [translate.staff || '員工', translate.member || '會員'],
                      }}
                      value={values?.rosterRuleChoice}
                      onChange={(e) => setFieldValue('rosterRuleChoice', e)}
                    />
                  </Grid>
                  {values?.rosterRuleChoice === translate.staff && (
                    <Grid item xs={12}>
                      <StaffsField {...props} />
                    </Grid>
                  )}
                  {values?.rosterRuleChoice === translate.member && (
                    <Grid item xs={12}>
                      <CustomersField {...props} />
                    </Grid>
                  )}
                </Grid>
              );
            },
          },
        ],
      },
    ],
  };
  onCancelClick = () => {
    const { onClose } = this.props;
    if (onClose) onClose();
  };
  onRemoveClick = async (actions) => {
    const { onSubmitted } = this.props;
    await super.onRemoveClick(actions);
    if (onSubmitted) onSubmitted();
  };

  submit = async (values) => {
    const { rosterId, onSubmitted } = this.props;

    if (!values?.customerIds?.length && !values?.staffIds?.length)
      throw new Error(translate.select_staff_or_member || '請選擇員工或會員');

    const input = {
      rosterId,
      startedAt: (() => {
        const m1 = moment(values.startedAt || new Date());
        const m2 = moment(values.timeAt || '1970-01-01 00:00:00');
        m1.hours(m2.hours()).minutes(m2.minutes()).seconds(0).milliseconds(0);
        return m1.toISOString();
      })(),
      startedThru: (() => {
        const m1 = moment(values.startedThru || new Date('2999-01-01'));
        const m2 = moment(values.timeThru || '1970-01-01 00:00:00');
        m1.hours(m2.hours()).minutes(m2.minutes()).seconds(0).milliseconds(0);
        return m1.toISOString();
      })(),
      daysOfWeek: values.daysOfWeek,
    };

    if (values?.staffIds?.length > 0) {
      await client.mutate({
        mutation: CREATE_QUERY,
        variables: {
          input: values.staffIds.map((staffId) => ({
            ...input,
            staffId: staffId?.id,
          })),
        },
      });
    }

    if (values?.customerIds?.length > 0) {
      await client.mutate({
        mutation: CREATE_QUERY,
        variables: {
          input: values.customerIds.map((customerId) => ({
            ...input,
            customerId: customerId?.id,
          })),
        },
      });
    }

    if (onSubmitted) onSubmitted();
  };

  getInitialData() {
    const { initialData, startedAt, startedThru } = this.props;
    return {
      active: true,
      sortIndex: 0,
      ...initialData,
      initialStartedAt: startedAt,
      initialStartedThru: startedThru,
      startedAt,
      startedThru,
      timeAt: initialData?.startedAt,
      timeThru: initialData?.startedThru,
      rosterRuleChoice: undefined,
      staffIds: undefined,
      customerIds: undefined,
    };
  }
}
