import { Box, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import DateRangeField from '../../../components/FormPageMaker/Fields/DateRangeField';
import { translate } from '../../../shared/translate';
import ExportButton from '../../item_SalePerformance/ExportButton';
import SnapButton from '../SnapButton';
import { useSalePerformanceRange } from '../../../components/SalePerformanceRangeProvider';

const ReportHeader = ({ exportMutation, exportVariables, loading, label }) => {
  const [range, setRange] = useSalePerformanceRange();

  return (
    <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
      {!!exportMutation && (
        <Grid item xs={12}>
          <Box display={'flex'} justifyContent={'flex-end'}>
            <ExportButton
              disabled={loading}
              mutation={exportMutation}
              variables={exportVariables}
              startedAt={range?.startedAt}
              startedThru={range?.startedThru}
            />
          </Box>
        </Grid>
      )}
      <Grid item>
        {!!label && (
          <Typography variant={'h6'} color={'secondary'}>
            {label}
          </Typography>
        )}
      </Grid>
      <Grid item>
        <Grid container spacing={1}>
          <Grid item>
            <SnapButton
              active={range?.dayMode === '0'}
              onClick={() => {
                setRange((prev) => ({
                  ...prev,
                  dayMode: '0',
                  startedAt: moment().startOf('day').toISOString(),
                  startedThru: moment().endOf('day').toISOString(),
                }));
              }}
            >
              {translate.today}
            </SnapButton>
          </Grid>
          <Grid item>
            <SnapButton
              active={range?.dayMode === '1'}
              onClick={() => {
                setRange((prev) => ({
                  ...prev,
                  dayMode: '1',
                  startedAt: moment().subtract(7, 'days').startOf('day').toISOString(),
                  startedThru: moment().endOf('day').toISOString(),
                }));
              }}
            >
              {translate.last_7_days}
            </SnapButton>
          </Grid>
          <Grid item>
            <SnapButton
              active={range?.dayMode === '2'}
              onClick={() => {
                setRange((prev) => ({
                  ...prev,
                  dayMode: '2',
                  startedAt: moment().startOf('month').startOf('day').toISOString(),
                  startedThru: moment().endOf('day').toISOString(),
                }));
              }}
            >
              {translate.this_month}
            </SnapButton>
          </Grid>
          <Grid item>
            <DateRangeField
              fromValue={range?.startedAt}
              toValue={range?.startedThru}
              onFromValueChange={(startedAt) => {
                setRange((prev) => ({
                  ...prev,
                  dayMode: '3',
                  startedAt,
                  startedThru: range?.startedThru,
                }));
              }}
              onToValueChange={(startedThru) => {
                setRange((prev) => ({
                  ...prev,
                  dayMode: '3',
                  startedAt: range?.startedAt,
                  startedThru,
                }));
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReportHeader;
