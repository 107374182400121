import React from 'react';
import ConnectionPageMaker from '../../../components/ConnectionPageMaker';
import { translate } from 'shared/translate';
import { CREATE_QUERY, DELETE_QUERY, GET_QUERY, GET_VARIATION_PRICE_SCHEDULE_QUERY, UPDATE_QUERY } from './query';
import { parseConnection, PriceFormat } from '../../../shared';
import { Query } from '@apollo/client/react/components';
import CommonIconButton from '../../../components/CommonIconButton';
import VariationCombinationDisplay from '../../../components/VariationCombinationDisplay';
import { Box, Grid, InputAdornment, Typography } from '@material-ui/core';
import { ReactComponent as pencil } from '../../../assets/icon/pencil.svg';
import { ReactComponent as checkCircle } from '../../../assets/icon/checkCircle.svg';
import { ReactComponent as trash } from '../../../assets/icon/trash.svg';
import { ReactComponent as back } from '../../../assets/icon/back.svg';
import EllipsisTypography from '../../../components/EllipsisTypography';
import moment from 'moment';
import InputField from '../../../components/FormPageMaker/Fields/InputField';
import DateTimeField from '../../../components/FormPageMaker/Fields/DateTimeField';
import { client } from '../../../shared/apollo';
import toast from '../../../shared/toast';
import errorParser from '../../../shared/errorParser';
import CustomTimeField from '../../../components/FormPageMaker/Fields/CustomTimeField';
import { Skeleton } from '@material-ui/lab';
import MenuImport from './MenuImport';
import MenuExport from './MenuExport';

export default class extends ConnectionPageMaker {
  state = {
    ...this.state,
    editData: undefined,
    isSubmitting: false,
    gql: { get: GET_QUERY },
    menus: [MenuImport, MenuExport],
    fields: [
      {
        title: translate.product,
        filter: "name",
        type: 'text',
        render: ({ name, combination }) => {
          return (
            <Grid container>
              <Grid item xs={12}>
                <Typography>{name}</Typography>
              </Grid>
              {!!combination?.length && (
                <Grid item xs={12}>
                  <VariationCombinationDisplay
                    style={{ paddingTop: 6 }}
                    typographyStyle={{ fontSize: '0.98em' }}
                    combination={combination}
                  />
                </Grid>
              )}
            </Grid>
          );
        },
      },
      // {
      //   title: translate.regular_price || '原價',
      //   fieldName: 'unitPrice',
      //   type: 'price',
      //   filter: 'unitPrice',
      //   sortBy: 'unitPrice',
      //   width: 100,
      // },
      {
        title: translate.sale_price || '特價',
        width: 142,
        sortBy: 'vps.unitPrice',
        render: ({ sku, salePrice }) => {
          if (this.variationPriceScheduleLoading) return <Skeleton width={'100%'} />;

          if (this.state.editData?.sku === sku)
            return (
              <InputField
                type={'number'}
                // eslint-disable-next-line
                InputProps={{
                  startAdornment: <InputAdornment position={'start'}>$</InputAdornment>,
                }}
                // eslint-disable-next-line
                inputProps={{
                  min: 0,
                  step: 0.01,
                }}
                disabled={this.state.isSubmitting}
                value={this.state.editData?.salePrice}
                onChange={(e) => this.setState({ editData: { ...this.state.editData, salePrice: e.target.value } })}
              />
            );
          if (!!salePrice) {
            return (
              <EllipsisTypography variant={'caption'} color={'textSecondary'}>
                {PriceFormat(salePrice)}
              </EllipsisTypography>
            );
          } else {
            return <Typography>-</Typography>;
          }
        },
      },
      {
        title: translate.start_date,
        width: 280,
        align: 'right',
        type: 'datetime',
        sortBy: 'vps.validAt',
        render: ({ sku, validAt }) => {
          if (this.variationPriceScheduleLoading) return <Skeleton width={'100%'} />;

          if (this.state.editData?.sku === sku)
            return (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <Typography>{translate.start_date || '開始日期'}</Typography>
                    </Grid>
                    <Grid item xs="true">
                      <DateTimeField
                        type={'date'}
                        disabled={this.state.isSubmitting}
                        value={this.state.editData?.validAtDate}
                        onChange={(e) => this.setState({ editData: { ...this.state.editData, validAtDate: e } })}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <Typography>{translate.start_time || '開始時間'}</Typography>
                    </Grid>
                    <Grid item xs="auto">
                      <CustomTimeField
                        minuteStep={10}
                        disabled={this.state.isSubmitting}
                        value={this.state.editData?.validAtTime}
                        onChange={(e) => this.setState({ editData: { ...this.state.editData, validAtTime: e } })}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );

          const time = !!validAt ? moment(validAt) : undefined;
          return (
            <>
              {time?.isValid() ? (
                <EllipsisTypography variant={'body1'}>{time.format('D MMM YYYY LT')}</EllipsisTypography>
              ) : (
                <EllipsisTypography variant={'body1'}>{'-'}</EllipsisTypography>
              )}
            </>
          );
        },
      },
      {
        title: translate.end_date,
        width: 280,
        align: 'right',
        type: 'datetime',
        sortBy: 'vps.validThru',
        render: ({ sku, validThru }) => {
          if (this.variationPriceScheduleLoading) return <Skeleton width={'100%'} />;

          if (this.state.editData?.sku === sku)
            return (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <Typography>{translate.end_date || '完結日期'}</Typography>
                    </Grid>
                    <Grid item xs="true">
                      <DateTimeField
                        type={'date'}
                        disabled={this.state.isSubmitting}
                        value={this.state.editData?.validThruDate}
                        onChange={(e) => this.setState({ editData: { ...this.state.editData, validThruDate: e } })}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <Typography>{translate.close_time || '結束時間'}</Typography>
                    </Grid>
                    <Grid item xs="auto">
                      <CustomTimeField
                        minuteStep={10}
                        disabled={this.state.isSubmitting}
                        value={this.state.editData?.validThruTime}
                        onChange={(e) => this.setState({ editData: { ...this.state.editData, validThruTime: e } })}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );

          const time = !!validThru ? moment(validThru) : undefined;
          return (
            <>
              {time?.isValid() ? (
                <EllipsisTypography variant={'body1'}>{time.format('D MMM YYYY LT')}</EllipsisTypography>
              ) : (
                <EllipsisTypography variant={'body1'}>{'-'}</EllipsisTypography>
              )}
            </>
          );
        },
      },
      {
        title: translate.edit,
        width: 150,
        align: 'center',
        render: ({ id, sku, salePrice, validAt, validThru }) => {
          if (!!this.state.editData && this.state.editData?.sku === sku) {
            const validSalePrice = +this.state.editData?.salePrice >= 0 && /^(?!0\d)\d+(\.\d{1,2})?$/.test(this.state.editData?.salePrice);
            if (!validSalePrice && this.state.editData?.salePrice !== undefined) toast.error(translate.positive_number_up_to_two_decimals || '請輸入正數，且最多只可輸入兩個小數位');
            const disabled =
              this.state.isSubmitting ||
              !this.state.editData?.salePrice ||
              !this.state.editData?.validAtDate ||
              !this.state.editData?.validAtTime ||
              !this.state.editData?.validThruDate ||
              !this.state.editData?.validThruTime ||
              !validSalePrice;

            return (
              <Box display="flex">
                <CommonIconButton
                  icon={back}
                  style={{ color: 'transparent' }}
                  title={translate.cancel}
                  disabled={this.state.isSubmitting}
                  onClick={async (e) => {
                    e.stopPropagation();
                    this.setState({ editData: undefined });
                  }}
                />
                <CommonIconButton
                  icon={checkCircle}
                  style={{ color: 'transparent', opacity: disabled ? 0.55 : 1 }}
                  title={translate.confirm}
                  disabled={disabled}
                  onClick={async (e) => {
                    try {
                      e.stopPropagation();
                      this.setState({ isSubmitting: true });

                      let input = {
                        unitPrice: this.state.editData?.salePrice,
                        validAt: (() => {
                          let date = this.state.editData?.validAtDate;
                          let time = this.state.editData?.validAtTime;
                          if (!date || !time) return undefined;

                          const mDate = moment(date),
                            mTime = moment(time);
                          return mDate
                            .hours(mTime.hours())
                            .minutes(mTime.minutes())
                            .seconds(0)
                            .milliseconds(0)
                            .toISOString();
                        })(),
                        validThru: (() => {
                          let date = this.state.editData?.validThruDate;
                          let time = this.state.editData?.validThruTime;
                          if (!date || !time) return undefined;

                          const mDate = moment(date),
                            mTime = moment(time);
                          return mDate
                            .hours(mTime.hours())
                            .minutes(mTime.minutes())
                            .seconds(0)
                            .milliseconds(0)
                            .toISOString();
                        })(),
                      };

                      if (!!salePrice) {
                        await client.mutate({
                          mutation: UPDATE_QUERY,
                          variables: {
                            id,
                            input,
                          },
                        });
                      } else {
                        await client.mutate({
                          mutation: CREATE_QUERY,
                          variables: {
                            input: {
                              shopId: localStorage.getItem('shopId'),
                              sku: this.state.editData?.sku,
                              ...input,
                            },
                          },
                        });
                      }

                      this.setState({ editData: undefined });
                      this.refetch2();
                      if (!!salePrice) {
                        toast.success(translate.update_success || '成功更新');
                      } else {
                        toast.success(translate.create_success || '新增成功');
                      }
                    } catch (e) {
                      toast.error(errorParser(e));
                    } finally {
                      this.setState({ isSubmitting: false });
                    }
                  }}
                />
                <CommonIconButton
                  icon={trash}
                  title={translate.delete}
                  disabled={this.state.isSubmitting || !salePrice}
                  onClick={async (e) => {
                    try {
                      e.stopPropagation();
                      this.setState({ isSubmitting: true });
                      await client.mutate({
                        mutation: DELETE_QUERY,
                        variables: { id },
                      });
                      this.setState({ editData: undefined });
                      this.refetch2();
                      toast.success(translate.delete_success || '成功刪除');
                    } catch (e) {
                      toast.error(errorParser(e));
                    } finally {
                      this.setState({ isSubmitting: false });
                    }
                  }}
                />
              </Box>
            );
          }
          return (
            <CommonIconButton
              icon={pencil}
              title={translate.edit}
              disabled={this.state.isSubmitting}
              onClick={(e) => {
                e.stopPropagation();
                this.setState({
                  editData: {
                    id,
                    sku,
                    salePrice,
                    validAtDate: validAt ? moment(validAt) : undefined,
                    validAtTime: validAt ? moment(validAt) : undefined,
                    validThruDate: validThru ? moment(validThru) : undefined,
                    validThruTime: validThru ? moment(validThru) : undefined,
                  },
                });
              }}
            />
          );
        },
      },
    ],
  };

  getData = (data) => {
    return data?.node?.variations;
  };

  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }

  renderWrapper({ loading, data = {}, ...args }) {
    const skus = parseConnection(data?.node?.variations).nodes.map(({ sku }) => sku) || [];
    const { skip } = this.state;

    return (
      <Query
        query={GET_VARIATION_PRICE_SCHEDULE_QUERY}
        errorPolicy={'all'}
        fetchPolicy={'cache-and-network'}
        variables={{
          filter: { sku: { operator: 'IN', value: skus } },
          id: localStorage.getItem('shopId'),
          limits: skus?.length
        }}
        skip={loading || !skus?.length}
      >
        {({ loading: loading2, data: data2, refetch }) => {
          this.refetch2 = refetch;
          this.variationPriceScheduleLoading = loading2;
          const salePriceNodes = data2?.node?.variationPriceSchedules?.nodes || [];

          const variationPriceScheduleConnection = parseConnection(data?.node?.variations || {});
          const variationPriceScheduleNodes =
            variationPriceScheduleConnection.nodes.map(({ sku, product, combination, unitPrice }) => {
              const {
                id,
                unitPrice: salePrice,
                validAt,
                validThru,
              } = salePriceNodes.find((node) => node.sku === sku) || {};

              return {
                id,
                sku,
                name: product?.name,
                combination,
                unitPrice,
                salePrice,
                validAt,
                validThru,
              };
            }) || [];

          return this.renderContent({
            ...args,
            loading: loading2 || loading || skip,
            data: { node: { variations: { ...variationPriceScheduleConnection, nodes: variationPriceScheduleNodes } } },
          });
        }}
      </Query>
    );
  }

  getSortBy() {
    const { sortBy } = this.state;
    return sortBy
      ? [sortBy]
      : [
          {
            field: 'vps.active',
            order: 'DESC',
          },
        ];
  }
}
