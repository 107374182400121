import React from 'react';
import { Typography } from '@material-ui/core';
import ConnectionPageMarker from '../../../components/ConnectionPageMaker';
import { translate } from '../../../shared/translate';
import { GET_SALE_RECORD_QUERY } from './query';
import { JSONParseSafely } from '../../../shared';
import { withSalePerformanceRange } from '../../../components/SalePerformanceRangeProvider';

class SalesRecordsList extends ConnectionPageMarker {
  state = {
    ...this.state,
    title: translate.all_collections_data,
    gql: {
      get: GET_SALE_RECORD_QUERY,
    },
    fields: [
      {
        title: translate.date,
        fieldName: 'createdAt',
        type: 'datetime',
      },
      {
        title: translate.shop_name,
        fieldName: 'shopName',
      },
      {
        title: translate.product_sku,
        render: (value) => {
          return <Typography>{value?.variationSku || value?.productSku}</Typography>;
        },
      },
      {
        title: translate.order_number,
        fieldName: 'referenceNo',
      },
      {
        title: translate.quantity,
        fieldName: 'quantity',
      },
      {
        title: translate.amount,
        fieldName: 'amount',
        type: 'price',
      },
    ],
  };

  getExtraFetchVariables() {
    const { id: code, range } = this.props;
    const { startedAt, startedThru, shop } = range ?? {};

    return {
      startedAt,
      shopId: shop?.id,
      startedThru,
      id: localStorage.getItem('companyId'),
      code,
    };
  }

  getData(data) {
    return data.node.report.collectionSales.salesRecords;
  }
}

export default withSalePerformanceRange(SalesRecordsList);
