import { client } from 'shared/apollo';
import { translate } from 'shared/translate';
import FormPageMaker from '../../components/FormPageMaker';
import { CREATE_QUERY, GET_QUERY, UPDATE_QUERY, REMOVE_QUERY } from './query';
import React from 'react';
import RosterRulesList from './RosterRulesList';
import { Box, Grid, InputAdornment, Typography } from '@material-ui/core';
import DateTimeField from '../../components/FormPageMaker/Fields/DateTimeField';
import moment from 'moment';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: !!this.state.id ? UPDATE_QUERY : CREATE_QUERY,
      remove: REMOVE_QUERY,
    },
    tabs: [
      {
        name: translate.shift_schedule_settings || '排班表設定',
        cards: [
          {
            fields: [
              {
                label: translate.name,
                type: 'text',
                name: 'name',
                required: true,
                md: 6,
              },

              {
                label: translate.working_days || '工作日',
                required: true,
                render: ({ values, setFieldValue, disabled }) => {
                  return (
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <DateTimeField
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position={'start'}>{translate.start_date || '開始日期'}</InputAdornment>
                            ),
                          }}
                          disabled={disabled}
                          value={values?.startedAt}
                          onChange={(v) => setFieldValue('startedAt', v)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DateTimeField
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position={'start'}>
                                {translate.end_booking_date || '完結日期'}
                              </InputAdornment>
                            ),
                          }}
                          disabled={disabled}
                          defaultValue={values?.startedThru}
                          value={values?.startedThru}
                          onChange={(v) => setFieldValue('startedThru', v)}
                        />
                      </Grid>
                    </Grid>
                  );
                },
              },

              {
                md: 6,
                label: `${translate.attendance_points || '出席積分'} (${
                  translate.eligible_membership_only || '會員限定'
                })`,
                type: 'number',
                name: 'gainMemberPoints',
                inputProps: {
                  min: 0,
                  step: 1,
                },
              },
            ],
          },
        ],
      },
      {
        name: translate.shift_schedule_rules || '排班規則',
        noPadding: true,
        cards: [
          (props) =>
            !!this.state.id ? (
              <RosterRulesList id={this.state.id} {...props} />
            ) : (
              <Box p={5} textAlign={'center'}>
                <Typography>{translate.create_shift_schedule_first || '請先建立排班表，才能更改排班規則'}</Typography>
              </Box>
            ),
        ],
      },
    ].filter(Boolean),
  };

  submit = async (values) => {
    const {
      id,
      gql: { submit },
    } = this.state;

    const input = {
      shopId: !!id ? undefined : localStorage.getItem('shopId'),
      name: values.name,
      startedAt: (() => {
        const m1 = moment(values.startedAt || new Date());
        const m2 = moment(values.timeAt || '1970-01-01 00:00:00');
        m1.hours(m2.hours()).minutes(m2.minutes()).seconds(0).milliseconds(0);
        return m1.toISOString();
      })(),
      startedThru: (() => {
        const m1 = moment(values.startedThru || new Date('2999-01-01'));
        const m2 = moment(values.timeThru || '1970-01-01 00:00:00');
        m1.hours(m2.hours()).minutes(m2.minutes()).seconds(0).milliseconds(0);
        return m1.toISOString();
      })(),
      gainMemberPoints: values.gainMemberPoints,
    };

    const {
      data: { result = {} },
    } = await client.mutate({
      mutation: submit,
      variables: {
        id,
        input,
      },
    });
    return true;
  };

  getInitialData(data) {
    const { node } = data || {},
      { id, active, name = '', startedAt, startedThru, gainMemberPoints } = node || {};
    return {
      __calendarTab: 0,
      id,
      active,
      name,
      shopId: localStorage.getItem('shopId'),
      startedAt,
      startedThru,
      timeAt: startedAt,
      timeThru: startedThru,
      gainMemberPoints,
    };
  }
}
