import { ReactComponent as IconCalendarPin } from '../../assets/icon/Backend-DateBoard-Icon-Pin.svg';
import { ReactComponent as IconCalendarService } from '../../assets/icon/Backend-DateBoard-Icon-Service.svg';
import { ReactComponent as IconShopList } from '../../assets/icon/shopList.svg';
import { ReactComponent as IconWandMagic } from '../../assets/icon/wandMagic.svg';
import { translate } from '../../shared/translate';
import { isDevelopment, isProduction } from '../../shared';
import {
  faCashRegister,
  faDollyFlatbedAlt,
  faGearComplexCode,
  faLaptopMobile,
  faTicketPerforated,
  faArrowRightToBracket,
  faUserTieHair,
  faClipboardList,
} from '@fortawesome/pro-regular-svg-icons';

const getOwnerMenu = (shopType, companyId) => {
  return [
    {
      children: [
        {
          name: translate['shop_list'],
          icon: {
            icon: IconShopList,
            type: 'svg',
            viewBox: '0 0 18 18',
          },
          link: '/shops',
          querystring: '?active=IN%5E*true',
          component: require('../list_Shop/index').default,
          children: [
            {
              name: translate.all_pos_devices,
              link: '/shops/all_devices',
              component: require('../list_AllDevice').default,
            },
          ],
        },
      ],
    },
    {
      children: [
        {
          name: translate['dashboard'],
          icon: 'faStore',
          link: '/',
          component: require('../Dashboard').default,
          permission: 'viewDashboard',
        },
      ],
    },
    {
      name: translate['sales_management'],
      icon: 'faClipboardList',
      children: [
        {
          name: translate['view_sales'],
          icon: 'faFileInvoice',
          link: '/orders',
          component: require('../list_Order').default,
          permission: 'viewOrders',
          children: [
            {
              link: '/orders/:id',
              component: require('../item_Order').default,
            },
          ],
        },
        {
          name: translate['returns_management'] || '退貨總覽',
          icon: 'faBriefcaseArrowRight',
          link: '/returns',
          component: require('../list_Return').default,
          permission: 'viewReturns',
          children: [
            {
              link: '/returns/:id',
              component: require('../item_Return').default,
            },
          ],
        },
        {
          name: translate['add_order'],
          icon: 'faClipboardMedical',
          link: '/add_orders',
          component: require('../Add_Order').default,
          permission: 'createOrder',
        },
        {
          name: translate['delivery_notes'],
          icon: 'faTruck',
          link: '/delivery_notes',
          component: require('../list_DeliveryNote').default,
          permission: 'viewDeliveryNotes',
          children: [
            {
              name: translate.new_delivery_note,
              link: '/delivery_notes/new',
              component: require('../item_DeliveryNote').default,
            },
            {
              link: '/delivery_notes/:id',
              component: require('../item_DeliveryNote').default,
            },
          ],
        },
      ],
    },
    {
      name: translate['menu_message_center'],
      icon: 'faMessages',
      children: [
        {
          shrinkMenu: true,
          name: translate.customer_chat,
          link: '/messages',
          permission: 'viewCustomerChats',
          icon: 'faMessage',
          component: require('../item_Messages').default,
          children: [
            {
              link: '/messages/:id?',
              component: require('../item_Messages').default,
            },
          ],
        },
        {
          name: translate.menu_system_message,
          link: '/system_messages',
          permission: 'viewSystemMessages',
          icon: 'faBell',
          component: require('../item_Messages').default,
        },
      ],
    },
    {
      name: translate['product_management'],
      icon: 'faBoxTaped',
      children: [
        {
          name: translate.ai_generated_product,
          icon: {
            icon: IconWandMagic,
            type: 'svg',
            viewBox: '0 0 18 18',
          },
          link: '/products_ai',
          component: require('../item_Product_AI').default,
          permission: 'autoGeneration',
        },
        {
          name: translate.my_products,
          icon: 'faShirt',
          link: '/products',
          component: require('../list_Product').default,
          permission: 'viewProducts',
          children: [
            {
              name: translate.listed,
              link: '/products/listed',
              component: require('../list_Product').default,
            },
            {
              name: translate.unlisted,
              link: '/products/unlisted',
              component: require('../list_Product').default,
            },
            {
              name: translate.new_product,
              link: '/products/new',
              component: require('../item_Product').default,
            },
            {
              link: '/products/:id',
              component: require('../item_Product').default,
            },
          ],
        },
        {
          name: translate.bundle_product,
          icon: 'faPuzzle',
          link: '/bundle_products',
          component: require('../list_BundleProduct').default,
          permission: 'viewBundleProducts',
          children: [
            {
              name: translate.new_product,
              link: '/bundle_products/new',
              component: require('../item_BundleProduct').default,
            },
            {
              link: '/bundle_products/:id',
              component: require('../item_BundleProduct').default,
            },
          ],
        },
        {
          name: translate['menu_product_collections'],
          icon: 'faShapes',
          link: '/product_collections',
          component: require('../list_Collection/index').default,
          permission: 'viewCollections',
          children: [
            {
              name: translate.new_collection,
              link: '/product_collections/new',
              component: require('../item_Collection/index').default,
            },
            {
              link: '/product_collections/:id?',
              component: require('../item_Collection/index').default,
            },
          ],
        },
        (companyId === '0d03b93b-c432-4fa2-95a2-55d00c65ebbd' || companyId === '8AX6z3V7e8Zr-XSCnJTlW') && {
          name: translate['cross_selling_products'],
          icon: 'faCartPlus',
          link: '/addon_products',
          component: require('../list_AddOnProduct/index').default,
          permission: 'viewAddOnProducts',
          children: [
            {
              name: translate.addon_products,
              link: '/addon_products/new',
              component: require('../item_AddOnProduct/index').default,
            },
            {
              link: '/addon_products/:id?',
              component: require('../item_AddOnProduct/index').default,
            },
          ],
        },
        !!isDevelopment && {
          name: translate['product_modifiers'],
          icon: 'faToggleOn',
          link: '/product_modifiers',
          component: require('../list_ProductModifier/index').default,
          permission: 'viewProductModifiers',
          children: [
            {
              name: translate.new_product_modifier,
              link: '/product_modifiers/new',
              component: require('../item_ProductModifier/index').default,
            },
            {
              link: '/product_modifiers/:id?',
              component: require('../item_ProductModifier/index').default,
            },
          ],
        },
        {
          name: translate.cash_voucher,
          icon: 'faMoneyBill1',
          link: '/cash_vouchers',
          component: require('../list_CashVoucher').default,
          permission: 'viewCashVouchers',
          children: [
            {
              name: translate.new_cash_voucher,
              link: '/cash_vouchers/new',
              component: require('../item_CashVoucher').default,
            },
            {
              link: '/cash_vouchers/:id?',
              component: require('../item_CashVoucher').default,
            },
          ],
        },
      ].filter((_) => _),
    },
    {
      name: translate['inventory_management'],
      icon: 'faWarehouse',
      children: [
        {
          name: translate['overview'],
          icon: 'faWarehouseFull',
          link: '/stock_overview',
          component: require('../list_StockOverView/index').default,
          permission: 'viewStockOverview',
        },
        {
          name: translate.all_warehouses,
          icon: 'faWarehouse',
          link: '/warehouses',
          component: require('../list_Warehouse/index').default,
          permission: 'viewWarehouses',
          children: [
            {
              name: translate.new_warehouse,
              link: '/warehouses/new',
              component: require('../item_Warehouse/index').default,
            },
            {
              link: '/warehouses/:id?',
              component: require('../item_Warehouse/index').default,
            },
          ],
        },
        {
          name: translate.inventory_transfer,
          icon: 'faRightLeftLarge',
          link: '/transfers',
          component: require('../list_Transfer').default,
          permission: 'viewTransfers',
          children: [
            {
              name: translate.new_transfer,
              link: '/transfers/new',
              component: require('../item_Transfer/index').default,
            },
            {
              link: '/transfers/:id?',
              component: require('../item_Transfer/index').default,
            },
          ],
        },
        {
          name: translate.receive_purchase,
          icon: 'faTruckArrowRight',
          link: '/receive_purchase',
          component: require('../list_ReceivePurchase').default,
          permission: 'viewReceivePurchases',
          children: [
            {
              name: translate.new_receive_purchase,
              link: '/receive_purchase/new',
              component: require('../item_ReceivePurchase/index').default,
            },
            {
              link: '/receive_purchase/:id?',
              component: require('../item_ReceivePurchase/index').default,
            },
          ],
        },
        {
          name: translate.stock_out || '扣貨',
          icon: 'faPenField',
          link: '/stock_out',
          component: require('../list_StockOut/index').default,
          permission: 'viewStockOut',
          children: [
            {
              name: translate.new_stock_out || '新增扣貨單',
              link: '/stock_out/new',
              component: require('../item_StockOut/index').default,
            },
            {
              link: '/stock_out/:id?',
              component: require('../item_StockOut/index').default,
            },
          ],
        },
        {
          name: translate.stock_take,
          icon: 'faBoxesStacked',
          link: '/stock_take',
          component: require('../list_Stocktake/index').default,
          permission: 'viewStockTakes',
          children: [
            {
              name: translate.new_transfer,
              link: '/stock_take/new',
              component: require('../item_Stocktake/index').default,
            },
            {
              link: '/stock_take/:id?',
              component: require('../item_Stocktake/index').default,
            },
          ],
        },
      ],
    },
    {
      children: [
        {
          name: translate['pos_device_management'],
          icon: {
            icon: faLaptopMobile,
          },
          link: '/devices',
          component: require('../list_Device/index').default,
          permission: 'viewDevices',
          children: [
            {
              name: translate.new_device,
              link: '/devices/new',
              component: require('../item_Device/index').default,
            },
            {
              link: '/devices/:id?',
              component: require('../item_Device/index').default,
            },
          ],
        },
      ],
    },
    {
      name: translate['client_management'],
      icon: 'faUser',
      children: [
        {
          name: translate['client_database'],
          icon: 'faSquareUser',
          link: '/customers',
          component: require('../list_Customer/index').default,
          permission: 'viewCustomers',
          children: [
            {
              name: translate.new_customer,
              link: '/customers/new',
              component: require('../item_Customer/create').default,
              permission: 'createCustomer',
            },
            {
              link: '/customers/:id',
              component: require('../item_Customer/index').default,
            },
          ],
        },
        {
          name: translate.register_field_setting,
          icon: 'faUserGear',
          link: '/customer_fields_setting',
          component: require('../item_CustomerFieldsSetting').default,
          permission: 'viewCustomerFields',
        },
      ],
    },
    {
      name: translate['discounts_and_promotions_management'],
      icon: 'faPercent',
      children: [
        {
          name: translate['discount_code'],
          link: '/discount_code',
          icon: {
            icon: faTicketPerforated,
          },
          component: require('../list_Offer').default,
          permission: 'viewCoupons',
          children: [
            {
              name: translate.discount_code_add,
              link: '/discount_code/new',
              component: require('../item_DiscountCode').default,
            },
            {
              link: '/discount_code/:id',
              component: require('../item_DiscountCode').default,
            },
          ],
        },
        {
          name: translate['coupons_and_discount_code'],
          link: '/coupons',
          icon: 'faMoneyCheckDollar',
          component: require('../list_Offer').default,
          permission: 'viewCoupons',
          children: [
            {
              name: translate.coupon_add,
              link: '/coupons/new',
              component: require('../item_Coupon').default,
            },
            {
              link: '/coupons/:id',
              component: require('../item_Coupon').default,
            },
          ],
        },
        {
          name: translate['discounts'],
          link: '/discounts',
          icon: 'faTags',
          component: require('../list_Offer').default,
          permission: 'viewDiscounts',
          children: [
            {
              name: translate.discount_add,
              link: '/discounts/new',
              component: require('../item_Discount').default,
            },
            {
              link: '/discounts/:id',
              component: require('../item_Discount').default,
            },
          ],
        },
        {
          name: translate['gift'],
          link: '/gifts',
          icon: 'faGift',
          component: require('../list_Offer').default,
          permission: 'viewGifts',
          children: [
            {
              name: translate.gift_add,
              link: '/gifts/new',
              component: require('../item_Gift').default,
            },
            {
              link: '/gifts/:id',
              component: require('../item_Gift').default,
            },
          ],
        },
        {
          name: translate['sale_price'] || '特價',
          link: '/salePrices',
          icon: 'faHandHoldingUsd',
          component: require('../list_Offer').default,
          permission: 'viewSalePrices',
        },
      ],
    },
    {
      name: translate['promotion_and_tracking'],
      icon: 'faBullhorn',
      children: [
        {
          name: translate.analyst_platform,
          icon: 'faChartMixed',
          link: '/analysis_tool',
          permission: 'viewAnalystPlatforms',
          component: require('../item_ShopAnalysisTool').default,
          children: [
            {
              name: translate['sitemap_manager'],
              link: '/analysis_tool/sitemap',
              component: require('../list_Sitemap').default,
            },
          ],
        },
        {
          name: translate.broadcast,
          icon: 'faSatelliteDish',
          link: '/campaigns',
          permission: 'viewCampaigns',
          component: require('../list_Campaign').default,
          children: [
            {
              name: translate.new_campaign,
              link: '/campaigns/new',
              component: require('../item_Campaign/index').default,
            },
            {
              link: '/campaigns/:id',
              component: require('../item_Campaign/index').default,
            },
          ],
        },
        {
          name: translate.subscriptions_list,
          icon: 'faNewspaper',
          link: '/subscriptions',
          permission: 'viewSubscriptions',
          component: require('../list_Subscription').default,
          children: [
            {
              name: translate.new_subscription,
              link: '/subscriptions/new',
              component: require('../item_Subscription/index').default,
            },
            {
              link: '/subscriptions/:id',
              component: require('../item_Subscription/index').default,
            },
          ],
        },
      ],
    },
    {
      name: translate['report_and_analysis'],
      icon: 'faChartSimple',
      children: [
        {
          name: translate['sale_performance_and_data'],
          icon: 'faChartLineUp',
          link: '/sale_performance_and_data',
          permission: 'viewSalesReports',
          component: require('../item_SalePerformance').default,
          children: [
            {
              name: translate.product_analyst,
              link: '/sale_performance_and_data/product_sales',
              component: require('../item_SalePerformance').default,
              children: [
                {
                  link: '/sale_performance_and_data/product_sales/:id',
                  component: require('../item_SingleSaleReport/ProductReport/index').default,
                },
              ],
            },
            {
              name: translate.collection_analyst,
              link: '/sale_performance_and_data/collection_sales',
              component: require('../item_SalePerformance').default,
              children: [
                {
                  link: '/sale_performance_and_data/collection_sales/:id',
                  component: require('../item_SingleSaleReport/CollectionReport/index').default,
                },
              ],
            },
            {
              name: translate.promotion_code_analyst,
              link: '/sale_performance_and_data/promotion_code_sales',
              component: require('../item_SalePerformance').default,
              children: [
                {
                  link: '/sale_performance_and_data/promotion_code_sales/:id',
                  component: require('../item_SingleSaleReport/PromotionCodeReport/index').default,
                },
              ],
            },
            {
              name: translate.coupon_analyst,
              link: '/sale_performance_and_data/coupon_sales',
              component: require('../item_SalePerformance').default,
              children: [
                {
                  link: '/sale_performance_and_data/coupon_sales/:id',
                  component: require('../item_SingleSaleReport/CouponReport/index').default,
                },
              ],
            },
            {
              name: translate.discount_analyst,
              link: '/sale_performance_and_data/discount_sales',
              component: require('../item_SalePerformance').default,
              children: [
                {
                  link: '/sale_performance_and_data/discount_sales/:id',
                  component: require('../item_SingleSaleReport/DiscountReport/index').default,
                },
              ],
            },
            {
              name: translate.member_tier_analyst,
              link: '/sale_performance_and_data/member_tier_sales',
              component: require('../item_SalePerformance').default,
              children: [
                {
                  link: '/sale_performance_and_data/member_tier_sales/:id',
                  component: require('../item_SingleSaleReport/MemberTierReport/index').default,
                },
              ],
            },
            {
              name: translate.salesperson_analyst,
              link: '/sale_performance_and_data/salesperson_sales',
              component: require('../item_SalePerformance').default,
              children: [
                {
                  link: '/sale_performance_and_data/salesperson_sales/:id',
                  component: require('../item_SingleSaleReport/SalespersonReport/index').default,
                },
              ],
            },
            {
              name: translate.all_orders,
              link: '/sale_performance_and_data/order_sales',
              component: require('../item_SalePerformance').default,
            },
          ],
        },
        !isProduction() && {
          name: translate['customer_analysis'],
          icon: 'faChartUser',
          link: '/customer_analysis',
          permission: 'viewCustomerReports',
          component: require('../item_CustomerAnalysis').default,
        },
      ],
    },
    {
      name: translate['website_setting'],
      icon: {
        icon: faGearComplexCode,
      },
      children: [
        {
          name: translate['custom_pages'],
          icon: 'faPenToSquare',
          link: '/custom_pages',
          component: require('../list_CustomPage').default,
          permission: 'viewCustomPages',
          children: [
            {
              name: translate['new_custom_page'],
              link: '/custom_pages/new',
              component: require('../item_CustomPageFull').default,
            },
            {
              link: '/custom_pages/:id',
              component: require('../item_CustomPageFull').default,
            },
          ],
        },
        {
          name: translate['marquee'],
          icon: 'faRectangleAd',
          link: '/marquee',
          component: require('../item_Marquee').default,
          permission: 'viewMarquees',
        },
        {
          name: translate['article'],
          icon: 'faMoneyCheckPen',
          link: '/articles',
          component: require('../list_Article').default,
          permission: 'viewArticles',
          children: [
            {
              name: translate['new_article'],
              link: '/articles/new',
              component: require('../item_Article').default,
            },
            {
              link: '/articles/:id',
              component: require('../item_Article').default,
            },
          ],
        },
        {
          name: translate['form'],
          icon: 'faSquareCheck',
          link: '/forms',
          component: require('../list_Form').default,
          permission: 'viewForms',
          children: [
            {
              name: translate['new_form'],
              link: '/forms/new',
              component: require('../item_Form').default,
            },
            {
              link: '/forms/:id',
              component: require('../item_Form').default,
            },
            {
              link: '/forms/:id/records',
              component: require('../list_FormRecords').default,
            },
            {
              link: '/forms/:formId/records/:id',
              component: require('../item_FormRecords').default,
            },
          ],
        },
        {
          name: translate['popup_advertisement'],
          icon: 'faWindowRestore',
          link: '/modalbox',
          component: require('../item_PopupAdv').default,
          permission: 'viewPopups',
        },
        {
          name: translate['navigation_template'],
          icon: 'faLink',
          link: '/navigation_template',
          component: require('../item_NavigationTemplate').default,
          permission: 'viewNavigationTemplates',
        },
        {
          name: translate['other_website_content'],
          icon: 'faLink',
          link: '/other_website_setting',
          component: require('../item_OtherWebsiteSetting').default,
          permission: 'viewWebsiteSettings',
        },
      ],
    },
    {
      name: translate['merchant_setting'],
      icon: {
        icon: faCashRegister,
      },
      children: [
        {
          name: translate.merchant_info,
          icon: 'faSquareInfo',
          link: '/merchant',
          permission: 'viewMerchantSettings',
          component: require('../item_Merchant').default,
          children: [
            {
              name: translate.referenceNo_setting,
              link: '/merchant/reference_no_setting',
              component: require('../item_ReferenceFormat').default,
            },
          ],
        },
        {
          name: translate['employee_management'],
          icon: {
            icon: faUserTieHair,
          },
          link: '/staffs',
          component: require('../list_Staff/index').default,
          permission: 'viewStaffSettings',
          children: [
            {
              name: translate.new_customer,
              link: '/staffs/new',
              component: require('../item_Staff').default,
              permission: 'createCustomer',
            },
            {
              link: '/staffs/:id',
              component: require('../item_Staff').default,
            },
          ],
        },
        {
          name: translate['shift_schedule_management'],
          icon: {
            icon: faClipboardList,
          },
          permission: 'viewRosterSettings',
          link: '/rosters',
          component: require('../list_Rosters').default,
          children: [
            {
              name: translate.new_item,
              link: '/rosters/new',
              component: require('../item_Roster').default,
            },
            {
              link: '/rosters/:id',
              component: require('../item_Roster').default,
            },
          ],
        },
        {
          name: translate['delivery_options'],
          icon: {
            icon: faDollyFlatbedAlt,
          },
          link: '/delivery_options',
          component: require('../list_ShippingZone').default,
          permission: 'viewDeliveryNotes',
          children: [
            {
              name: translate.new_delivery_option,
              link: '/delivery_options/new',
              component: require('../item_ShippingZone').default,
            },
            {
              link: '/delivery_options/:id',
              component: require('../item_ShippingZone').default,
            },
          ],
        },
        {
          name: translate['credential'],
          icon: 'faCreditCard',
          link: '/credentials',
          component: require('../list_Credentials').default,
          permission: 'viewCredentials',
          children: [
            {
              name: translate.new_credential,
              link: '/credentials/new',
              component: require('../item_Credential').default,
            },
            {
              link: '/credentials/:id',
              component: require('../item_Credential').default,
            },
          ],
        },
        {
          name: translate['payment_providers'],
          icon: 'faCreditCard',
          link: '/payments',
          component: require('../list_Payment').default,
          permission: 'viewPaymentMethods',
          children: [
            {
              name: translate.new_payment,
              link: '/payments/new',
              component: require('../item_Payment').default,
            },
            {
              link: '/payments/:id',
              component: require('../item_Payment').default,
            },
          ],
        },
        {
          name: translate['email_template'],
          icon: 'faEnvelope',
          link: '/email_templates',
          component: require('../item_EmailTemplate').default,
          permission: 'viewEmailTemplates',
        },
        {
          name: translate['receipt_printout_settings'],
          icon: 'faReceipt',
          link: '/receipt_template',
          component: require('../item_ReceiptTemplate').default,
          permission: 'viewReceiptTemplates',
        },
        {
          name: translate['cash_flow_statement'],
          icon: 'faMoneyBill',
          link: '/cash_flow_statement',
          component: require('../list_CashFlowStatement').default,
          permission: 'viewCashFlowStatements',
        },
        {
          name: translate.member_tier_management,
          icon: 'faUsers',
          link: '/member_tiers',
          component: require('../list_MemberTier').default,
          permission: 'viewMemberTiers',
          children: [
            {
              name: translate.member_default_settings,
              link: '/member_tiers/member_default_settings',
              component: require('../item_MemberDefaultSettings').default,
            },
            {
              name: translate.point_setting,
              link: '/member_tiers/setting',
              component: require('../item_PointsSetting').default,
            },
            {
              name: translate.new_member_tier,
              link: '/member_tiers/new',
              component: require('../item_MemberTier').default,
            },
            {
              link: '/member_tiers/:id',
              component: require('../item_MemberTier').default,
            },
          ],
        },
        {
          name: translate.admin_user_setting,
          icon: {
            icon: faArrowRightToBracket,
          },
          link: '/users',
          component: require('../list_User').default,
          permission: 'viewUsers',
          children: [
            {
              name: translate.new_admin_user,
              link: '/users/new',
              component: require('../item_User/create').default,
            },
            {
              name: translate.update_admin_user,
              link: '/users/:id',
              component: require('../item_User/update').default,
            },
          ],
        },
        {
          name: translate['account_and_password'],
          icon: 'faCircleUser',
          link: '/account_password',
          component: require('../item_AccountPassword').default,
        },
        {
          name: translate['email_setting'],
          icon: 'faMailBulk',
          link: '/account',
          component: require('../item_Account').default,
          permission: 'viewAccountSetting',
        },
        {
          name: translate['product_order_options'],
          icon: 'faFileInvoiceDollar',
          link: '/setting_products_orders',
          component: require('../item_SettingProductOrder').default,
          permission: 'viewProductOrderSetting',
        },
        {
          name: translate['attribute_setting'] || '自定參數設定',
          icon: 'faSliders',
          link: '/attributes',
          component: require('../list_Attribute').default,
          permission: 'viewAttributes',
          children: [
            {
              name: translate.new_attribute || '新增自定參數',
              link: '/attributes/new',
              component: require('../item_Attribute').default,
            },
            {
              link: '/attributes/:id',
              component: require('../item_Attribute').default,
            },
          ],
        },
      ],
    },
    shopType === 'FOOD_BEVERAGES' && {
      name: translate['settings_title_real_shop'],
      icon: 'faStore',
      children: [
        {
          name: translate['settings_venue'],
          icon: 'faLocationDot',
          permission: 'viewVenues',
          link: '/venues',
          component: require('../list_Venue').default,
          children: [
            {
              name: translate.new_venue,
              link: '/venues/new',
              component: require('../item_Venue').default,
            },
            {
              link: '/venues/:id',
              component: require('../item_Venue').default,
            },
          ],
        },
        {
          name: translate['settings_table'],
          icon: {
            icon: 'faPlateUtensils',
          },
          permission: 'viewTables',
          link: '/tables',
          component: require('../list_Table').default,
          children: [
            {
              link: '/tables/:id',
              component: require('../item_Table').default,
            },
          ],
        },
        {
          name: translate.gate_setting,
          icon: {
            icon: 'faRoadBarrier',
          },
          permission: 'viewGateSetting',
          link: '/gate_setting',
          component: require('../Gate_Setting').default,
        },
      ],
    },
    !isProduction() && {
      name: translate['booking_management'],
      icon: {
        icon: 'faCalendarAlt',
      },
      children: [
        {
          name: translate['booking_setting'],
          icon: {
            icon: IconCalendarService,
            type: 'svg',
            viewBox: '0 0 16 16',
          },
          permission: 'viewServices',
          link: '/service_bundles',
          component: require('../list_ServiceBundle').default,
          children: [
            {
              name: translate.new_booking,
              link: '/service_bundles/new',
              component: require('../item_ServiceBundle').default,
            },
            {
              // name: translate.update_booking,
              link: '/service_bundles/:id',
              component: require('../item_ServiceBundle').default,
            },
          ],
        },
        {
          name: translate['service_location_setting'],
          icon: {
            icon: IconCalendarPin,
            type: 'svg',
            viewBox: '0 0 16 16',
          },
          permission: 'viewServiceLocations',
          link: '/service_locations',
          component: require('../list_ServiceLocation').default,
          children: [
            {
              name: translate.new_item,
              link: '/service_locations/new',
              component: require('../item_ServiceLocation').default,
            },
            {
              link: '/service_locations/:id',
              component: require('../item_ServiceLocation').default,
            },
          ],
        },
        {
          name: translate['appointment'],
          icon: {
            icon: IconCalendarPin,
            type: 'svg',
            viewBox: '0 0 16 16',
          },
          permission: 'viewAppointments',
          link: '/appointments',
          component: require('../list_Appointments').default,
          children: [
            {
              link: '/appointments/create',
              component: require('../item_Appointment/create').default,
            },
            {
              link: '/appointments/:id',
              component: require('../item_Appointment').default,
            },
          ],
        },
      ],
    },
  ];
};

export default getOwnerMenu;
