import { gql } from '@apollo/client';

export const FRAGMENT_GIFT = gql`
  fragment FRAGMENT_GIFT on CompanyGift {
    name
    description
    remark
    sortIndex
    discardSubsequent
    metadata {
      key
      value
    }
    triggers {
      triggerType
      triggerId
      triggerIds
      triggerValueType
      triggerValue
    }
    actions {
      actionType
      actionId
      actionIds
      actionValueType
      actionValue
    }
    publishAt
    publishThru
    membersOnly
    active
    repeat
    excludedGifts @client
  }
`;
export const GET_QUERY = gql`
  ${FRAGMENT_GIFT}
  query ($id: ID!) {
    node(id: $id) {
      id
      updatedAt
      createdAt
      ...FRAGMENT_GIFT
    }
  }
`;

export const CREATE_QUERY = gql`
  ${FRAGMENT_GIFT}
  mutation ($input: CompanyGiftCreateInput!) {
    companyGiftCreate(input: $input) {
      id
      ...FRAGMENT_GIFT
    }
  }
`;

export const UPDATE_QUERY = gql`
  ${FRAGMENT_GIFT}
  mutation ($id: ID!, $input: CompanyGiftUpdateInput!) {
    companyGiftUpdate(id: $id, input: $input) {
      id
      ...FRAGMENT_GIFT
    }
  }
`;

export const DELETE_QUERY = gql`
  mutation ($id: ID!) {
    companyGiftDelete(id: $id) {
      id
    }
  }
`;
