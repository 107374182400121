import { sort } from 'rrule/dist/esm/dateutil';
import ConnectionPageMarker from '../../../components/ConnectionPageMaker';
import { translate } from '../../../shared/translate';
import { GET_SALE_RECORD_QUERY } from './query';
import { withSalePerformanceRange } from '../../../components/SalePerformanceRangeProvider';

class SalesRecordsList extends ConnectionPageMarker {
  state = {
    ...this.state,
    title: translate.all_products_data,
    gql: {
      get: GET_SALE_RECORD_QUERY,
    },
    fields: [
      {
        title: translate.date,
        fieldName: 'createdAt',
        type: 'datetime',
        sort: 'createdAt',
      },
      {
        title: translate.shop_name,
        fieldName: 'shopName',
      },

      {
        title: translate.order_number,
        fieldName: 'referenceNo',
      },
      {
        title: translate.quantity,
        fieldName: 'count',
      },
      {
        title: translate.amount,
        fieldName: 'amount',
        type: 'price',
      },
      {
        title: translate.discount_amount,
        fieldName: 'discountAmount',
        type: 'price',
      },
    ],
  };

  getExtraFetchVariables() {
    const { id, range } = this.props;
    const { startedAt, startedThru } = range ?? {};

    return {
      startedAt,
      startedThru,
      id: localStorage.getItem('companyId'),
      promotionCodeId: id,
    };
  }

  getData(data) {
    const { node } = data || {},
      { report } = node || {},
      { promotionCodeSales } = report || {},
      { salesRecords } = promotionCodeSales || {};
    return { ...salesRecords };
  }
}

export default withSalePerformanceRange(SalesRecordsList);
