import React from 'react';
import { translate } from '../../../shared/translate';

export default {
  platform: {
    icon: <img className="h-4" src={require('../../../assets/adyen-logo.png')} alt="ADYEN POS" />,
    label: `ADYEN ${translate.offline || '（線下）'}`,
    value: 'ADYEN_POS',
  },
  form: (actions) => {
    return null;
  },
  type: 'PAYMENT',
};
