import React from 'react';
import FormPageMaker from 'components/FormPageMaker';
import { translate } from 'shared/translate';
import { client } from 'shared/apollo';
import { GET_QUERY, POST_QUERY, REMOVE_COLLECTION_QUERY, REMOVE_QUERY } from './query';
import SeoCardSetting from '../item_Product/SeoCardSetting';
import Skeleton from '@material-ui/lab/Skeleton';
import SelectComboCollection from '../../components/SelectCombo/SelectComboCollection';
import { toInputMedia } from '../../shared';
import DailyAvailabilityField from './DailyAvailabilityField';
import { Box, Typography } from '@material-ui/core';
import ProductsListView from './ProductsListView';
import moment from 'moment';
import KeetaCard from './KeetaCard';
import SalesChannelField from '../item_Product/SalesChannelField';
import _ from 'lodash';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: POST_QUERY,
      remove: REMOVE_COLLECTION_QUERY,
    },
    tabs: [
      {
        name: translate.menu_product_collections,
        cards: [
          {
            fields: [
              {
                label: translate.collection_name,
                type: 'text',
                name: 'name',
                maxLength: 100,
                required: true,
              },
              {
                label: translate.category_handle,
                type: 'text',
                name: 'code',
                maxLength: 100,
                required: true,
                text: translate.same_code_link_categories,
              },
              {
                label: translate.sort_index,
                description:
                  translate.display_order_description ||
                  '「顯示次序」指的是本項目在前端網站或POS上呈現的次序，系統以升序排列，數字越小，顯示越靠前。',
                type: 'number',
                name: 'sortIndex',
                inputProps: {
                  step: 1,
                },
              },
              {
                label: translate.collection_position,
                render: ({ loading, values: { parent }, setFieldValue, isSubmitting }) =>
                  loading ? (
                    <Skeleton height={30} />
                  ) : (
                    <>
                      <SelectComboCollection
                        disabled={isSubmitting}
                        value={parent}
                        onChange={(collection) => {
                          setFieldValue('parent', collection);
                        }}
                      />
                      <Typography variant="body2">{translate.set_as_primary_category_when_left_blank}</Typography>
                    </>
                  ),
              },
              {
                label: translate.online_day_time,
                name: 'publishAt',
                type: 'datetime-local',
                md: 6,
                text: translate.start_time_collection_remark || '如不輸入，本產品分類將即時可供選擇',
              },
              {
                label: translate.offline_day_time,
                name: 'publishThru',
                type: 'datetime-local',
                hasPermanent: true,
                md: 6,
                text: translate.available_collection_if_no_off_shelf_date || '如不輸入下架日期，系統將視為常設產品分類',
              },
              {
                label: translate.sales_channel || '銷售渠道',
                render: ({ loading, disabled, values, setFieldValue }) =>
                  loading ? (
                    <Skeleton height={30} width={100} />
                  ) : (
                    <SalesChannelField
                      disabled={disabled}
                      value={values.salesChannels}
                      onChange={(v) => setFieldValue('salesChannels', v)}
                    />
                  ),
              },
              {
                label: translate.schedule_category_visibility || '設定啟用時段',
                name: 'isDailyAvaActive',
                type: 'switch',
                md: 'auto',
              },
              {
                md: true,
                label: translate.collection_dailyAvailability,
                display: ({ values }) => values.isDailyAvaActive,
                render: ({ loading, values, setFieldValue, isSubmitting }) => {
                  return (
                    <DailyAvailabilityField
                      disabled={isSubmitting}
                      value={values.dailyAvailability}
                      onChange={(v) => setFieldValue('dailyAvailability', v)}
                    />
                  );
                },
              },
              {
                label: translate.image,
                type: 'media',
                name: 'media',
              },
              {
                label: translate.description,
                type: 'html',
                name: 'description',
              },
            ],
          },
          // ProductCard,
        ],
      },
      {
        name: translate.product,
        noPadding: true,
        cards: [
          !!this.state.id
            ? ProductsListView
            : {
                render: () => {
                  return (
                    <Box p={5} textAlign={'center'}>
                      <Typography>{translate.create_product_category_first}</Typography>
                    </Box>
                  );
                },
              },
        ],
      },
      SeoCardSetting,
      {
        name: translate.keeta_settings || 'Keeta設定',
        cards: [KeetaCard],
      },
    ],
  };

  submit = async (values) => {
    const {
      id,
      gql: { submit },
    } = this.state;
    const {
      name,
      code,
      active,
      sortIndex,
      description,
      media,
      parent,
      metaTitle,
      metaDescription,
      metaKeywords,
      rewriteUri,
      isDailyAvaActive,
      publishAt,
      publishThru,
      settingKeeta,
      salesChannels,
    } = values;

    let input = {
      name,
      code: code || name,
      description,
      shopId: localStorage.getItem('shopId'),
      active,
      sortIndex,
      media: toInputMedia(media),
      parentId: parent ? parent.id : null,
      metaTitle,
      metaDescription,
      metaKeywords,
      salesChannels,
      rewriteUri: rewriteUri || null,
      publishAt: moment(publishAt).toISOString(),
      publishThru: !!publishThru ? moment(publishThru).toISOString() : null,
      settingKeeta: !!settingKeeta && settingKeeta?.isMandatory ? { isMandatory: true } : null,
    };

    // check isDailyAvaActive true or false to set the dailyAvailability's input null or not
    if (isDailyAvaActive) {
      // isDailyAvaActive is true
      const { startedAt, startedThru } = values.dailyAvailability || {};
      const shouldSetDailyAvailability =
        startedAt !== startedThru &&
        !(startedAt && startedAt.includes('T16:00:00.000Z') && startedThru && startedThru.includes('T16:00:00.000Z'));

      if (shouldSetDailyAvailability) {
        // startedAt and startedThru both are 00:00
        input.dailyAvailability = {
          daysOfWeek: values.dailyAvailability?.daysOfWeek ?? [],
          startedAt,
          startedThru,
        };
      } else {
        // startedAt and startedThru both not are 00:00
        input.dailyAvailability = null;
      }
    } else {
      // isDailyAvaActive is false
      input.dailyAvailability = null;
    }

    await client.mutate({
      mutation: submit,
      variables: {
        id,
        input,
      },
    });

    return true;
  };

  getInitialData = (data) => {
    const { node } = data || {},
      {
        id = '',
        name = '',
        code = '',
        description = '',
        sortIndex = 0,
        active = true,
        media,
        parent,
        metaTitle = '',
        metaDescription = '',
        metaKeywords = '',
        rewriteUri = '',
        updatedAt,
        dailyAvailability,
        publishAt,
        publishThru,
        settingKeeta,
        salesChannels,
      } = node || {};
    // { nodes: _products = [] } = parseConnection(products);

    return {
      id: id || '',
      name: name || '',
      code: code || name,
      description: description || '',
      sortIndex: sortIndex || 0,
      active,
      media,
      parent,
      salesChannels: _.uniq(['DEFAULT'].concat(salesChannels || ['POS', 'CUTSOMERADO', 'KIOSK'])),
      metaTitle,
      metaDescription,
      metaKeywords,
      rewriteUri,
      updatedAt,
      dailyAvailability,
      isDailyAvaActive: !(
        dailyAvailability == null ||
        (!dailyAvailability?.daysOfWeek && !dailyAvailability?.startedAt && !dailyAvailability?.startedThru)
      ),
      publishAt,
      publishThru,
      settingKeeta,
    };
  };
}
