import { gql } from '@apollo/client';

export const FRAGMENT_LIST_ROSTER = gql`
  fragment FRAGMENT_LIST_ROSTER on RosterSlot {
    id
    updatedAt
    createdAt
    active
    status
    startedAt
    startedThru
    customer {
      id
      email
      phoneNumber
      metadata {
        key
        value
      }
    }
    staff {
      id
      name
      phoneNumber
    }
  }
`;

export const GET_QUERY = gql`
  query ($cursor: Int, $limits: Int, $sortBy: [SorterInput!], $filter: RosterSlotFilterInput) {
    rosterSlots(cursor: $cursor, limits: $limits, sortBy: $sortBy, filter: $filter) @client {
      nextCursor
      totalCount
      nodes {
        ...FRAGMENT_LIST_ROSTER
      }
    }
  }
  ${FRAGMENT_LIST_ROSTER}
`;

export const ROSTER_SLOT_DELETE = gql`
  mutation ($id: ID!) {
    rosterSlotDelete(id: $id) {
      id
      status
    }
  }
`;
