import React, { useState } from 'react';
import { toast } from '../../shared/toast';
import errorParser from '../../shared/errorParser';
import InputField from '../../components/FormPageMaker/Fields/InputField';
import { InputAdornment, IconButton, CardContent, Card, Typography, Grid } from '@material-ui/core';
import { translate } from '../../shared/translate';
import { UPDATE_QUERY } from './query';
import { client } from '../../shared/apollo';
import { Icon } from '../../components/IconRender';

export default ({ data: { id, type = 'google_tag_manager', context: _context } = {} }) => {
  const [loading, setLoading] = useState(false);
  const [context, setContext] = useState(_context);

  return (
    <Card elevation={2} style={{ height: '100%' }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item>
            <img style={{ width: 200 }} src={require('../../assets/what-is-gtm.webp')} alt={''} />
          </Grid>
          <Grid item xs={true}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant={'h6'}>{translate.google_tag_manager}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>{translate.google_tag_manager_remark}</Typography>
              </Grid>
              <Grid item xs={12}>
                <InputField
                  disabled={loading}
                  required={true}
                  value={context}
                  onChange={(e) => {
                    setContext(e.target.value);
                  }}
                  placeholder={'GTM-XXXXXXXX'}
                  // eslint-disable-next-line
                  InputProps={{
                    startAdornment: <InputAdornment position={'start'}>TAG</InputAdornment>,
                    endAdornment: (
                      <IconButton
                        disabled={loading}
                        onClick={async () => {
                          try {
                            setLoading(true);
                            await client.mutate({
                              mutation: UPDATE_QUERY,
                              variables: {
                                id,
                                input: {
                                  shopId: localStorage.getItem('shopId'),
                                  type,
                                  context,
                                  active: true,
                                },
                              },
                            });
                          } catch (e) {
                            toast.error(errorParser(e));
                          } finally {
                            setLoading(false);
                          }
                        }}
                      >
                        <Icon icon={'faArrowCircleRight'} />
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
