import React from 'react';
import { translate } from '../../../shared/translate';

export default {
  option: {
    group: translate.recommend,
    icon: <img className="h-4" src={require('../../../assets/uprise.png')} alt="stripe" />,
    label: 'Uprise Payment',
    value: 'UPRISE_PAYMENT',
    credentialPlatform: 'UPRISE_PAYMENT',
  },
  form: (actions) => {
    return null;
  },
};
