import { gql } from '@apollo/client';
import { FRAGMENT_LIST_APPOINTMENT } from '../list_Appointments/AppointmentCalendar/query';

export const GET_ORDER_CUSTOMER = gql`
  query ($id: ID!) {
    node(id: $id) {
      ... on ShopOrder {
        customer {
          id
          email
          phoneNumber
          metadata {
            key
            value
          }
        }
      }
    }
  }
`;

export const FRAGMENT_APPOINTMENTS = gql`
  fragment FRAGMENT_APPOINTMENTS on ShopAppointment {
    id
    createdAt
    updatedAt
    active
    attendanceStatus
    contactAddress {
      person
      email
      tel
    }
    location {
      id
      name
    }
    metadata {
      key
      value
    }
    order {
      id
      referenceNo
      createdAt
    }
    orderItem {
      id
      description
      remark
      unitPrice
    }
    referenceNo
    remark
    service {
      id
      name
      durationMins
      reserveMins
    }
    serviceBundle {
      id
      name
    }
    startedAt
    startedThru
    status
  }
`;

export const GET_LINKED_APPOINTMENTS = gql`
  query ($id: ID!) {
    linkedAppointments(orderId: $id) @client {
      nextCursor
      totalCount
      nodes {
        ...FRAGMENT_LIST_APPOINTMENT
      }
    }
  }
  ${FRAGMENT_LIST_APPOINTMENT}
`;

export const GET_QUERY = gql`
  query ($id: ID!) {
    node(id: $id) {
      ...FRAGMENT_APPOINTMENTS
    }
  }
  ${FRAGMENT_APPOINTMENTS}
`;

export const UPDATE_QUERY = gql`
  mutation ($id: ID!, $input: AppointmentUpdateInput!) {
    appointmentUpdate(id: $id, input: $input) {
      ...FRAGMENT_APPOINTMENTS
    }
  }
  ${FRAGMENT_APPOINTMENTS}
`;

export const CREATE_QUERY = gql`
  mutation ($input: AppointmentCreateInput!) {
    appointmentCreate(input: $input) {
      ...FRAGMENT_APPOINTMENTS
    }
  }
  ${FRAGMENT_APPOINTMENTS}
`;
