import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_QUERY, ROSTER_SLOT_DELETE } from './query';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment/moment';
import { convertMetaObject, dayRangeHeaderFormat, parseConnection, stringToColour } from '../../../shared';
import CustomToolBar from './CustomToolBar';
import Color from 'color';
import EventModal from './EventModal';
import _ from 'lodash';
import { Grid, Typography, useTheme } from '@material-ui/core';
import SelectComboRoster from '../../../components/SelectCombo/SelectComboRoster';
import toast from '../../../shared/toast';
import errorParser from '../../../shared/errorParser';
import { client } from '../../../shared/apollo';
import { translate } from '../../../shared/translate';

const RosterCalendar = () => {
  const theme = useTheme();
  const localizer = momentLocalizer(moment);
  const [range, setRange] = useState({
    startedAt: moment().startOf('month').subtract(7, 'day').toISOString(),
    startedThru: moment().endOf('month').add(7, 'day').toISOString(),
  });
  const [selectedEvent, setSelectedEvent] = useState(undefined);
  const [selectedRoster, setSelectedRoster] = useState(undefined);

  const [deleting, setDeleting] = useState(false);

  const { loading, data, refetch } = useQuery(GET_QUERY, {
    fetchPolicy: 'cache-and-network',
    skip: !selectedRoster?.id,
    variables: {
      filter: {
        startedAt: {
          operator: 'BETWEEN',
          value: [range.startedAt, range.startedThru],
        },
        rosterId: {
          operator: 'IN',
          value: selectedRoster?.id,
        },
      },
      sortBy: [{ field: 'id', order: 'DESC' }],
    },
  });

  const rosterSlots = !!selectedRoster?.id ? parseConnection(data?.rosterSlots).nodes : [];

  const events = Object.values(
    _.groupBy(rosterSlots, (rosterSlot) => {
      return [rosterSlot?.id, rosterSlot?.startedAt, rosterSlot?.startThru].join('-');
    }),
  ).map((value) => {
    return {
      startedAt: value?.[0]?.startedAt,
      startedThru: value?.[0]?.startedThru,
      customer: value?.[0]?.customer,
      staff: value?.[0]?.staff,
      id: value?.[0]?.id,
    };
  });

  return (
    <div style={{ height: 700, position: 'relative', marginTop: '5px' }}>
      <Grid container>
        <Grid item xs={12} md={6} lg={4}>
          <Typography variant="h6">{translate.shift_schedule}</Typography>
          <SelectComboRoster value={selectedRoster} onChange={(roster) => setSelectedRoster(roster)} />
        </Grid>
      </Grid>
      {!!selectedRoster?.id && (
        <>
          <Calendar
            style={{ height: '95%', marginTop: -25 }}
            defaultView={Views.MONTH}
            formats={{ dayRangeHeaderFormat }}
            components={{
              toolbar: (props) => <CustomToolBar {...props} loading={loading} />,
            }}
            step={60}
            timeslots={1}
            localizer={localizer}
            eventPropGetter={(event) => {
              const title = event?.title;
              const backgroundColor = Color(stringToColour(title)).mix(Color(theme.palette.primary.main), 0.4).hex();
              const color = Color(backgroundColor).contrast(Color('black')) > 11 ? '#000' : '#ffffff';
              return {
                style: {
                  backgroundColor,
                  color,
                  left: 0,
                  width: '100%',
                },
              };
            }}
            events={events?.reduce(
              (prev, event) => [
                ...prev,
                {
                  start: moment.utc(event.startedAt).toDate(),
                  end: moment.utc(event.startedThru).toDate(),
                  title: [
                    moment(event?.startedAt).format('A HH:mm'),
                    (() => {
                      if (!event?.customer?.id) return '';
                      return `【${translate.member} 】${convertMetaObject(event?.customer?.metadata ?? [])?.name}`;
                    })(),
                    (() => {
                      if (!event?.staff?.id) return '';
                      return `【${translate.staff} 】${event?.staff?.name}`;
                    })(),
                  ]
                    .filter(Boolean)
                    .join(' '),
                  allDay: false,
                  resource: event,
                },
              ],
              [],
            )}
            startAccessor="start"
            endAccessor="end"
            selectable={true}
            onSelectSlot={() => {
              setSelectedEvent(undefined);
            }}
            onSelectEvent={({ resource }, e) => {
              if (loading) return;
              setSelectedEvent({ resource, anchor: e.target });
            }}
            onRangeChange={(args) => {
              let start, end;
              if (_.isArray(args)) {
                start = args[0];
                end = args[args?.length - 1];
              } else if (args?.start && args?.end) {
                start = args.start;
                end = args.end;
              }
              setRange({
                startedAt: moment(start).subtract(1, 'day').toISOString(),
                startedThru: moment(end).add(1, 'day').toISOString(),
              });
            }}
            popup={true}
          />
          <EventModal
            selectedEvent={selectedEvent}
            onClose={() => setSelectedEvent(undefined)}
            disabled={deleting}
            onRosterSlotDeleteClick={async () => {
              try {
                setDeleting(true);
                await client.mutate({
                  mutation: ROSTER_SLOT_DELETE,
                  variables: {
                    id: selectedEvent?.resource?.id,
                  },
                });
                toast.success(translate.delete_success);
                setSelectedEvent(undefined);
                refetch();
              } catch (e) {
                toast.error(errorParser(e));
              } finally {
                setDeleting(false);
              }
            }}
          />
        </>
      )}
    </div>
  );
};

export default RosterCalendar;
