import React from 'react';
import { Grid } from '@material-ui/core';
import CheckboxField from '../../../components/FormPageMaker/Fields/CheckboxField';
import DaysOfWeekField from '../../../components/FormPageMaker/Fields/DaysOfWeekField';
import { translate } from '../../../shared/translate';

const ServiceWeekDateField = ({ disabled, value: daysOfWeek, onChange }) => {
  const active = !!daysOfWeek;
  return (
    <Grid container>
      <Grid item xs={12}>
        <CheckboxField
          size={'small'}
          label={translate.set_available_business_days || '設定可預約工作日'}
          checked={active}
          onChange={() => {
            if (active) onChange(null);
            else onChange([]);
          }}
          disabled={disabled}
        />
      </Grid>
      {active && <DaysOfWeekField value={daysOfWeek} onChange={onChange} disabled={disabled} />}
    </Grid>
  );
};

export default ServiceWeekDateField;
