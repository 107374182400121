import { gql } from '@apollo/client';

export const FRAGMENT_PRODUCT_VARIATION = gql`
  fragment FRAGMENT_PRODUCT_VARIATION on ProductVariation {
    id
    createdAt
    updatedAt
    active
    sku
    unitPrice
    combination {
      option
      name
    }
    product {
      id
      name
      sku
    }
  }
`;

export const GET_QUERY = gql`
  query (
    $id: ID!
    $cursor: Int
    $limits: Int
    $query: String
    $sortBy: [SorterInput!]
    $filter: ProductVariationFilterInput
  ) {
    node(id: $id) {
      id
      ... on CompanyShop {
        id
        variations(cursor: $cursor, limits: $limits, query: $query, sortBy: $sortBy, filter: $filter) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_PRODUCT_VARIATION
          }
        }
      }
    }
  }
  ${FRAGMENT_PRODUCT_VARIATION}
`;

export const FRAGMENT_VARIATION_PRICE_SCHEDULE = gql`
  fragment FRAGMENT_VARIATION_PRICE_SCHEDULE on VariationPriceSchedule {
    id
    createdAt
    updatedAt
    active
    sku
    unitPrice
    validAt
    validThru
  }
`;

export const GET_VARIATION_PRICE_SCHEDULE_QUERY = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $sortBy: [SorterInput!], $filter: VariationPriceScheduleFilterInput) {
    node(id: $id) {
      id
      ... on CompanyShop {
        id
        variationPriceSchedules(cursor: $cursor, limits: $limits, sortBy: $sortBy, filter: $filter) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_VARIATION_PRICE_SCHEDULE
          }
        }
      }
    }
  }
  ${FRAGMENT_VARIATION_PRICE_SCHEDULE}
`;

export const CREATE_QUERY = gql`
  mutation ($input: VariationPriceScheduleCreateInput!) {
    variationPriceScheduleCreate(input: $input) {
      id
      unitPrice
      validAt
      validThru
    }
  }
`;

export const UPDATE_QUERY = gql`
  mutation ($id: ID!, $input: VariationPriceScheduleUpdateInput!) {
    variationPriceScheduleUpdate(id: $id, input: $input) {
      id
      unitPrice
      validAt
      validThru
    }
  }
`;

export const DELETE_QUERY = gql`
  mutation ($id: ID!) {
    variationPriceScheduleDelete(id: $id) {
      id
    }
  }
`;

export const IMPORT_QUERY = gql`
  mutation ($shopId: ID!, $url: AWSURL!) {
    variationPriceSchedulesImport(shopId: $shopId, url: $url) {
      id
      status
      output
    }
  }
`;
