import React from 'react';
import ConnectionPageMaker from '../../../components/ConnectionPageMaker';
import { GET_QUERY } from './query';
import { translate } from '../../../shared/translate';
import { sleep } from '../../../shared';
import VariationCombinationDisplay from '../../../components/VariationCombinationDisplay';
import { Typography } from '@material-ui/core';

class PriceRecordsCard extends ConnectionPageMaker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    fields: [
      {
        title: translate.created_at,
        fieldName: 'createdAt',
        type: 'datetime',
        width: 180,
      },
      {
        title: translate.product_sku,
        fieldName: 'sku',
      },
      {
        title: translate.product_options,
        render: ({ combination }) => {
          if (!combination) return <Typography variant="body2">-</Typography>;
          return (
            <VariationCombinationDisplay
              style={{ paddingTop: 6 }}
              typographyStyle={{ fontSize: '0.98em' }}
              combination={combination.split('\n').map((item) => {
                const [name, option] = item.split(': ');
                return { name, option };
              })}
            />
          );
        },
      },
      {
        title: translate.price_history || '售價記錄',
        width: 120,
        align: 'right',
        type: 'price',
        fieldName: 'unitPrice',
      },
      {
        title: translate.changed_by || '更改用戶',
        width: 200,
        value: ({ createdBy }) => createdBy?.email,
      },
    ],
    limits: 20,
  };

  getData({ node } = {}) {
    const { priceHistories } = node || {};
    return priceHistories;
  }

  getExtraFetchVariables() {
    const { values } = this.props;
    return { id: values?.id };
  }

  getFetchPolicy() {
    return 'cache-first';
  }

  getSkip() {
    const { values } = this.props;
    return !values?.id;
  }

  renderWrapper(...args) {
    return <div style={{ width: '100%' }}>{this.renderContent(...args)}</div>;
  }

  getQueryParams = () => {
    const { queryParams } = this.state;
    return queryParams ?? {};
  };
  getQueryParam = (key) => {
    const { queryParams } = this.state;
    return queryParams?.[key];
  };
  patchQueryParams = (nextParams) => {
    const { queryParams } = this.state;

    if (typeof nextParams === 'function') {
      nextParams = nextParams(queryParams);
    }
    this.setState({
      queryParams: {
        ...queryParams,
        ...nextParams,
      },
    });
    sleep(0).then(() => {
      this.setState(this.getQueryState());
    });
  };
}

export default PriceRecordsCard;
