import React from 'react';
import FormPageMaker from '../../components/FormPageMaker';
import { translate } from '../../shared/translate';
import { CREATE_QUERY, GET_ORDER_CUSTOMER } from './query';
import { client } from '../../shared/apollo';
import { convertMetaArray, parseAddress } from '../../shared';
import { Grid, InputAdornment } from '@material-ui/core';
import SelectComboServiceLocation from '../../components/SelectCombo/SelectComboServiceLocation';
import moment from 'moment';
import SelectComboServiceBundle from '../../components/SelectCombo/SelectComboServiceBundle';
import SelectComboService from '../../components/SelectCombo/SelectComboService';
import CustomTimeField from '../../components/FormPageMaker/Fields/CustomTimeField';
import SelectComboOrder from '../../components/SelectCombo/SelectComboOrder';
import SelectComboCustomer from '../../components/SelectCombo/SelectComboCustomer';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      submit: CREATE_QUERY,
    },
    tabs: [
      {
        name: translate.appointment || '訂閱列表',
        cards: [
          {
            fields: [
              {
                md: 6,
                label: translate.associated_purchase_order_id || '對應訂單編號',
                render: ({ values, setFieldValue, disabled }) => {
                  return (
                    <SelectComboOrder
                      required={true}
                      disabled={disabled}
                      value={values.order}
                      onChange={async (v) => {
                        setFieldValue('order', v);
                        if (v?.id) {
                          const { data } = await client.query({
                            query: GET_ORDER_CUSTOMER,
                            variables: { id: v?.id },
                          });
                          const customer = data?.node?.customer;
                          if (customer) setFieldValue('customer', customer);
                        }
                      }}
                    />
                  );
                },
              },
              {
                md: 6,
                label: translate.member || '會員',
                render: ({ values, setFieldValue, disabled }) => {
                  return (
                    <SelectComboCustomer
                      value={values.customer}
                      disabled={disabled}
                      onChange={(v) => {
                        setFieldValue('customer', v);
                      }}
                    />
                  );
                },
              },
              { block: true },
              {
                md: 6,
                label: translate.booking_item || '預約項目',
                required: true,
                render: ({ values, setFieldValue, disabled }) => {
                  return (
                    <SelectComboServiceBundle
                      required={true}
                      disabled={disabled}
                      value={values.serviceBundle}
                      onChange={(v) => {
                        setFieldValue('serviceBundle', v);
                        setFieldValue('service', null);
                      }}
                    />
                  );
                },
              },
              {
                md: 6,
                display: ({ values }) => !!values.serviceBundle?.id,
                label: translate.booking_event || '預約節目',
                required: true,
                render: ({ values, setFieldValue, disabled }) => {
                  return (
                    <SelectComboService
                      required={true}
                      disabled={disabled}
                      value={values.service}
                      onChange={(v) => setFieldValue('service', v)}
                      filter={{
                        serviceBundleId: {
                          operator: 'IN',
                          value: [values.serviceBundle?.id],
                        },
                      }}
                    />
                  );
                },
              },
              {
                required: true,
                label: translate.booking_location || '預約地點',
                render: ({ values, setFieldValue, disabled }) => {
                  return (
                    <SelectComboServiceLocation
                      required={true}
                      disabled={disabled}
                      value={values.location}
                      onChange={(v) => setFieldValue('location', v)}
                    />
                  );
                },
              },
              {
                label: translate.booking_date || '預約日期',
                required: true,
                name: 'reservedAt',
                type: 'date',
              },
              {
                label: translate.booking_time || '預約時段',
                required: true,
                render: ({ values, setFieldValue, disabled }) => {
                  return (
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <CustomTimeField
                          required={true}
                          minuteStep={5}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position={'start'}>{translate.start_time || '開始時間'}</InputAdornment>
                            ),
                          }}
                          disabled={disabled}
                          value={values?.startedAt}
                          onChange={(v) => {
                            console.log(v);
                            setFieldValue('startedAt', v);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomTimeField
                          required={true}
                          minuteStep={5}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position={'start'}>{translate.close_time || '結束時間'}</InputAdornment>
                            ),
                          }}
                          disabled={disabled}
                          value={values?.startedThru}
                          onChange={(v) => setFieldValue('startedThru', v)}
                        />
                      </Grid>
                    </Grid>
                  );
                },
              },
              {
                label: translate.appointee_name || '預約人名稱',
                type: 'text',
                name: 'contactAddress.person',
              },
              {
                label: translate.appointee_email || '預約人電郵',
                type: 'email',
                name: 'contactAddress.email',
              },
              {
                label: translate.appointee_mobile || '預約人電話',
                type: 'mobile',
                name: 'contactAddress.tel',
              },
              {
                label: translate.remark,
                type: 'textarea',
                name: 'remark',
              },
            ],
          },
        ],
      },
    ],
  };

  submit = async (values) => {
    const {
      gql: { submit },
    } = this.state;

    const momentReservedAt = moment(values.reservedAt);
    console.log(values.reservedAt, values.startedAt);

    await client.mutate({
      mutation: submit,
      variables: {
        input: {
          serviceId: values?.service?.id,
          contactAddress: parseAddress(values.contactAddress, true),
          metadata: convertMetaArray(values.metadata),
          remark: values.remark,
          orderId: values.order?.id,
          customerId: values.customer?.id,
          serviceLocationId: values?.location?.id,
          startedAt: moment(values.startedAt)
            .year(momentReservedAt.year())
            .month(momentReservedAt.month())
            .date(momentReservedAt.date())
            .toISOString(),
          startedThru: moment(values.startedThru)
            .year(momentReservedAt.year())
            .month(momentReservedAt.month())
            .date(momentReservedAt.date())
            .toISOString(),
        },
      },
    });

    return true;
  };

  getInitialData = (data) => {
    return {
      contactAddress: {
        country: 'HKG',
      },
      metadata: {},
    };
  };
}
