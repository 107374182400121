import React from 'react';
import { Skeleton } from '@material-ui/lab';
import SelectComboCustomer from '../../../components/SelectCombo/SelectComboCustomer';

export default ({ loading, isSubmitting, values: { customerIds } = {}, setFieldValue }) => {
  if (loading) return <Skeleton height={30} />;

  return (
    <SelectComboCustomer
      multiple={true}
      value={customerIds || []}
      disabled={isSubmitting}
      onChange={(customerIds) => setFieldValue('customerIds', customerIds)}
    />
  );
};
