import { gql } from '@apollo/client';

export const FRAGMENT_SHOPCOLLECTION = gql`
  fragment ShopCollection on ShopCollection {
    id
    name
    active
    sortIndex
    description
    salesChannels
    code
    media {
      src
      optimizedSrc(width: 256, height: 256)
    }
    parent {
      id
      name
    }
    dailyAvailability {
      daysOfWeek
      startedAt
      startedThru
    }
    metaTitle
    metaDescription
    metaKeywords
    rewriteUri
    updatedAt
    publishAt
    publishThru
    settingKeeta {
      id
      active
      isMandatory
    }
  }
`;

export const GET_QUERY = gql`
  ${FRAGMENT_SHOPCOLLECTION}
  query ($id: ID!) {
    node(id: $id) {
      ...ShopCollection
    }
  }
`;

export const POST_QUERY = gql`
  ${FRAGMENT_SHOPCOLLECTION}
  mutation collectionSet($id: ID, $input: CollectionInput!) {
    result: collectionSet(id: $id, input: $input) {
      id
      updatedAt
      ...ShopCollection
    }
  }
`;

export const REMOVE_COLLECTION_QUERY = gql`
  mutation collectionUnset($id: ID!) {
    result: collectionUnset(id: $id) {
      id
    }
  }
`;

export const REMOVE_QUERY = gql`
  mutation collectionProductsRemove($id: ID!, $productIds: [ID!]!) {
    result: collectionProductsRemove(id: $id, productIds: $productIds) {
      id
    }
  }
`;

// For AddToCategoryListView
export const FRAGMENT_PRODUCT = gql`
  fragment FRAGMENT_PRODUCT on ShopProduct {
    id
    name
    images
    medias {
      #id
      src
      optimizedSrc(width: 256, height: 256)
    }
    active
    createdAt
    updatedAt
    basePrice
    maxPrice
    sku
    publishAt
    publishThru
    variations {
      id
      ignoreStock
      quantity
    }
    collections {
      nodes {
        id
        active
        name
      }
    }
  }
`;

export const FRAGMENT_BUNDLE_PRODUCT = gql`
  fragment FRAGMENT_BUNDLE_PRODUCT on ShopBundleProduct {
    id
    name
    sku
    publishAt
    publishThru
    active
    createdAt
    updatedAt
    unitPrice
    quantity
    ignoreStock
    suggestedRetailPrice
    medias {
      #id
      src
      optimizedSrc(width: 256, height: 256)
    }
    collections {
      nodes {
        id
        active
        name
      }
    }
  }
`;

export const GET_COLLECTION_PRODUCTS = gql`
  query (
    $id: ID!
    $cursor: Int
    $limits: Int
    $filter: ProductUnionFilterInput
    $query: String
    $sortBy: [SorterInput!]
  ) {
    node(id: $id) {
      id
      ... on ShopCollection {
        productUnions(cursor: $cursor, limits: $limits, filter: $filter, query: $query, sortBy: $sortBy) {
          nextCursor
          totalCount
          nodes {
            ... on ShopProduct {
              ...FRAGMENT_PRODUCT
            }
            ... on ShopBundleProduct {
              ...FRAGMENT_BUNDLE_PRODUCT
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_PRODUCT}
  ${FRAGMENT_BUNDLE_PRODUCT}
`;

export const AddToCategory_GET_QUERY = gql`
  query (
    $id: ID!
    $query: String
    $cursor: Int
    $limits: Int
    $sortBy: [SorterInput!]
    $filter: ProductUnionFilterInput
  ) {
    node(id: $id) {
      id
      ... on CompanyShop {
        id
        productUnions(query: $query, cursor: $cursor, limits: $limits, sortBy: $sortBy, filter: $filter) {
          nextCursor
          totalCount
          nodes {
            ... on ShopProduct {
              ...FRAGMENT_PRODUCT
            }
            ... on ShopBundleProduct {
              ...FRAGMENT_BUNDLE_PRODUCT
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_PRODUCT}
  ${FRAGMENT_BUNDLE_PRODUCT}
`;

export const AddToCategory_POST_QUERY = gql`
  mutation collectionProductsAdd($id: ID!, $productIds: [ID!]!) {
    collectionProductsAdd(id: $id, productIds: $productIds) {
      id
    }
  }
`;
