import React from 'react';
import { GET_QUERY } from './query';
import SystemButton from '../../../components/SystemButton';
import { translate } from '../../../shared/translate';
import { StateParamsConnectionPageMarker } from '../../../components/ConnectionPageMaker';
import RosterRuleModalForm from './RosterRuleModalForm';
import moment from 'moment';
import { Icon } from '../../../components/IconRender';
import DeleteButton from './DeleteButton';

export default class RosterRulesList extends StateParamsConnectionPageMarker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    refreshable: true,
    fields: [
      {
        title: translate.working_days || '工作日',
        value: (row) => {
          const { startedAt, startedThru, daysOfWeek } = row;
          return (
            <>
              {moment(startedAt).format('DD MMM YYYY')}
              <Icon icon={'faArrowRight'} style={{ fontSize: '1rem', verticalAlign: 'sub', margin: '0 8px' }} />
              {moment(startedThru).format('DD MMM YYYY')}
              {daysOfWeek?.length > 0 && (
                <span>
                  【
                  {daysOfWeek
                    .map(
                      (d) =>
                        ({
                          0: translate.sun,
                          1: translate.mon,
                          2: translate.tue,
                          3: translate.wed,
                          4: translate.thu,
                          5: translate.fri,
                          6: translate.sat,
                        }[d]),
                    )
                    .join(' ')}
                  】
                </span>
              )}
            </>
          );
        },
      },
      {
        title: translate.service_location_duration_minutes || '時段長度',
        value: (row) => {
          return (
            <>
              {moment(row?.startedAt).format('A HH:mm')}
              <Icon icon={'faArrowRight'} style={{ fontSize: '1rem', verticalAlign: 'sub', margin: '0 8px' }} />
              {moment(row?.startedThru).format('A HH:mm')}
            </>
          );
        },
      },
      {
        width: 150,
        title: translate.staff || '員工',
        type: 'text',
        value: (row) => row?.staff?.name || '-',
      },
      {
        width: 150,
        title: translate.member || '會員',
        type: 'text',
        value: (row) => row?.customer?.email || '-',
      },
      {
        width: 80,
        align: 'center',
        render: ({ id, customer, staff }) => (
          <DeleteButton
            id={id}
            name={`【${!!customer?.email ? translate.customer_email : translate.employee_name}：${
              customer?.email || staff?.name
            }】`}
            list={this}
          />
        ),
        noLink: true,
      },
    ],
    formInitialData: undefined,
    showForm: false,
  };

  getData = (data) => data?.node?.rules;

  renderExtras = ({ loading }) => {
    const { disabled } = this.props;
    return (
      <SystemButton
        variant={'contained'}
        color={'secondary'}
        disabled={loading || disabled}
        onClick={() => {
          this.setState({ showForm: true });
        }}
      >
        {translate.new_slot}
      </SystemButton>
    );
  };

  renderModal = () => {
    const { id } = this.props;
    const { showForm, formInitialData } = this.state;
    const { startedAt, startedThru } = this.props.values;

    return (
      <>
        <RosterRuleModalForm
          initialData={formInitialData}
          rosterId={id}
          startedAt={startedAt}
          startedThru={startedThru}
          open={showForm}
          onClose={() => {
            this.setState({ showForm: false, formInitialData: undefined });
          }}
          onSubmitted={() => {
            this.refetch();
            this.setState({ showForm: false, formInitialData: undefined });
          }}
        />
      </>
    );
  };

  getExtraFetchVariables() {
    const { id } = this.props;
    return { id };
  }
}
