import React from 'react';
import FormPageMaker from '../../components/FormPageMaker';
import { Box, Grid, InputAdornment, Typography } from '@material-ui/core';
import InputField from '../../components/FormPageMaker/Fields/InputField';
import SelectField from '../../components/FormPageMaker/Fields/SelectField';
import { GET_QUERY, UPDATE_QUERY } from './query';
import { client } from '../../shared/apollo';
import { translate } from '../../shared/translate';
import LSkeleton from '../../components/LSkeleton';
import Taber from '../../components/Taber';
import StyledConfigList, { AddButton } from '../../components/StyledConfigList';
import TrashButton from '../../components/TrashButton';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    id: localStorage.getItem('shopId'),
    gql: {
      prepare: GET_QUERY,
      submit: UPDATE_QUERY,
    },
    tabs: [
      {
        cards: [
          {
            fields: [
              {
                id: '#' + translate.member_point_release_policy,
                label: translate.member_point_release_policy,
                type: 'select',
                name: 'memberPointReleasePolicy',
                required: true,
                render: ({ loading, values, isSubmitting, setFieldValue }) =>
                  loading ? (
                    <LSkeleton />
                  ) : (
                    <Grid container alignItems={'center'} spacing={2}>
                      <Grid item>
                        <SelectField
                          style={{ minWidth: 120 }}
                          options={[
                            {
                              label: translate.order_confirmed,
                              value: 'ORDER_CONFIRM',
                            },
                            {
                              label: translate.payment_completed,
                              value: 'ORDER_PAYMENT_COMPLETE',
                            },
                            {
                              label: translate.shipped_out,
                              value: 'ORDER_SHIPPING_PROCESSING',
                            },
                          ]}
                          disabled={isSubmitting}
                          value={values.memberPointReleasePolicy}
                          onChange={(e) => setFieldValue('memberPointReleasePolicy', e.target.value)}
                          required={true}
                        />
                      </Grid>
                      <Grid item>
                        <Typography>{translate.afterwards}</Typography>
                      </Grid>
                      <Grid item>
                        <InputField
                          type={'number'}
                          disabled={isSubmitting}
                          value={values.memberPointReleaseDelay}
                          onChange={(e) => setFieldValue('memberPointReleaseDelay', e.target.value)}
                          // eslint-disable-next-line
                          InputProps={{
                            endAdornment: <InputAdornment position={'end'}>{translate.day}</InputAdornment>,
                          }}
                          // eslint-disable-next-line
                          inputProps={{
                            step: 1,
                            min: 0,
                          }}
                          required={true}
                        />
                      </Grid>
                    </Grid>
                  ),
              },
              {
                id: '#' + translate.allow_member_point_checkout,
                label: translate.allow_member_point_checkout,
                type: 'checkbox',
                name: 'allowMemberPointCheckout',
                dense: true,
                block: true,
              },
              {
                id: '#' + translate.point_money_convert,
                label: translate.point_money_convert,
                render: ({ loading, values, setFieldValue, isSubmitting }) =>
                  loading ? (
                    <LSkeleton />
                  ) : (
                    <Grid container alignItems={'center'} spacing={2}>
                      <Grid item>
                        <Typography>{translate.every_points || '每'}</Typography>
                      </Grid>
                      <Grid item>
                        <InputField
                          type={'number'}
                          disabled={isSubmitting || !values.allowMemberPointCheckout}
                          value={values.memberPointUsePerUnit}
                          onChange={(e) => setFieldValue('memberPointUsePerUnit', e.target.value)}
                          // eslint-disable-next-line
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position={'end'}>
                                {translate.points_with_capital_letter || '分'}
                              </InputAdornment>
                            ),
                          }}
                          // eslint-disable-next-line
                          inputProps={{
                            min: 0,
                          }}
                          required={true}
                        />
                      </Grid>
                      <Grid item>
                        <Typography>{translate.earns || '兌換'}</Typography>
                      </Grid>
                      <Grid item>
                        <InputField
                          type={'number'}
                          disabled={isSubmitting || !values.allowMemberPointCheckout}
                          value={values.memberPointUseUnitPoint}
                          onChange={(e) => setFieldValue('memberPointUseUnitPoint', e.target.value)}
                          // eslint-disable-next-line
                          InputProps={{
                            startAdornment: <InputAdornment position={'start'}>$</InputAdornment>,
                          }}
                          // eslint-disable-next-line
                          inputProps={{
                            min: 0,
                          }}
                          required={true}
                        />
                      </Grid>
                    </Grid>
                  ),
                required: true,
              },
              {
                label: translate.single_order_points_redemption_rule || '單筆訂單積分兌換規則',
                text:
                  translate.customers_can_freely_set_redemption_amount ||
                  '顧客可在兌換上限內自由設定兌換金額。 請注意，積分兌換條件與上限金額將排除 “不適用全店折扣商品”',
                type: 'span',
              },
              {
                id: '#' + translate.point_expiry_dates,
                label: translate.point_expiry_dates,
                render: ({ isSubmitting, loading, setFieldValue, values: { memberPointResetDates = [] } }) => (
                  <StyledConfigList>
                    <Box m={1.5}>
                      {memberPointResetDates.map((date, i) => {
                        const { month, day } = date;
                        const monthOrDefault = month || '01';
                        return (
                          <Box mx={1.5}>
                            <Grid key={`${i}-${monthOrDefault}-${day}`} container alignItems={'center'} spacing={2}>
                              <Grid item>
                                <SelectField
                                  value={monthOrDefault}
                                  onChange={(e) => {
                                    date.month = e.target.value;
                                    setFieldValue('memberPointResetDates', memberPointResetDates);
                                  }}
                                  placeholder={'?月'}
                                  options={Array(12)
                                    .fill(undefined)
                                    .map((__, i) => ({
                                      label: `${i + 1}月`,
                                      value: `${i + 1}`.padStart(2, '0'),
                                    }))}
                                  required={true}
                                />
                              </Grid>
                              <Grid item>
                                <SelectField
                                  value={day}
                                  onChange={(e) => {
                                    date.day = e.target.value;
                                    setFieldValue('memberPointResetDates', memberPointResetDates);
                                  }}
                                  style={{ minWidth: 120 }}
                                  placeholder={'?日'}
                                  options={Array(new Date(new Date().getFullYear(), monthOrDefault, 0).getDate())
                                    .fill(undefined)
                                    .map((__, i) => ({
                                      label: `${i + 1}日`,
                                      value: `${i + 1}`.padStart(2, '0'),
                                    }))}
                                  required={true}
                                />
                              </Grid>
                              <Grid item>
                                <TrashButton
                                  onClick={() => {
                                    memberPointResetDates.splice(i, 1);
                                    setFieldValue('memberPointResetDates', memberPointResetDates);
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        );
                      })}
                      <AddButton
                        disabled={isSubmitting || loading}
                        onClick={() => {
                          setFieldValue('memberPointResetDates', memberPointResetDates.concat({ month: '01' }));
                        }}
                        title={translate.add_new}
                      />
                    </Box>
                  </StyledConfigList>
                ),
              },
              {
                id: '#' + translate.member_point_graceful_period,
                label: translate.member_point_graceful_period,
                type: 'period',
                name: 'memberPointResetGracefulPeriod',
                required: true,
                InputProps: {
                  style: { maxWidth: 250 },
                },
                description:
                  translate.points_expired_on_specified_date_after_grace_period ||
                  `積分會在寬限期後的指定日到期，例如：若設定到期日為6/30，且寬限期為 1 個月；則當年度 5/30 至 6/29 發送的積分，會統一在明年的 6/30 到期。`,
              },
            ],
          },
        ],
      },
    ],
  };

  submit = async (values) => {
    const {
      gql: { submit },
    } = this.state;

    await client.mutate({
      mutation: submit,
      variables: {
        shopId: localStorage.getItem('shopId'),
        shopUpdateInput: {
          name: values.name,
          allowMemberPointCheckout: values.allowMemberPointCheckout,
          memberPointUseActive: true,
          memberPointUseUnitPoint: values.memberPointUseUnitPoint,
          memberPointUsePerUnit: values.memberPointUsePerUnit,
          memberPointReleasePolicy: values.memberPointReleasePolicy,
          memberPointReleaseDelay: values.memberPointReleaseDelay,
        },
        companyId: localStorage.getItem('companyId'),
        companyUpdateInput: {
          memberPointResetGracefulPeriod: values.memberPointResetGracefulPeriod,
          memberPointResetDates: values.memberPointResetDates
            .filter(({ day, month }) => !!day && !!month)
            .map(({ day, month }) => `${day}/${month}`),
        },
      },
    });
    return true;
  };

  getInitialData({ node } = {}) {
    const { company, ...data } = node || {},
      { memberPointResetDates, memberPointResetGracefulPeriod = 'P1M' } = company || {};
    return {
      ...data,
      memberPointResetGracefulPeriod,
      memberPointResetDates: (memberPointResetDates || []).map((date) => ({
        day: (date || '').split('/')[0],
        month: (date || '').split('/')[1],
      })),
    };
  }

  renderContent(result) {
    const { history } = this.props;
    const { loading, data } = this.getFetchResult(result);
    this.initialValues = this.getInitialData(data || {});
    return (
      <>
        <Taber
          currentTab={1}
          tabs={[
            {
              name: translate.membership_level,
              onClick: () => history.push('/member_tiers'),
            },
            {
              name: translate.point_setting,
            },
            {
              name: translate.member_default_settings,
              onClick: () => history.push('/member_tiers/member_default_settings'),
            },
          ]}
        />
        {this.renderForm({ ...this.initialValues }, loading)}
      </>
    );
  }
}
