import React from 'react';
import { Skeleton } from '@material-ui/lab';
import SelectComboStaff from '../../../components/SelectCombo/SelectComboStaff';

export default ({ loading, isSubmitting, values: { staffIds } = {}, setFieldValue }) => {
  if (loading) return <Skeleton height={30} />;

  return (
    <SelectComboStaff
      multiple={true}
      value={staffIds || []}
      disabled={isSubmitting}
      onChange={(staffIds) => setFieldValue('staffIds', staffIds)}
    />
  );
};
