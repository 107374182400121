import React, { useEffect } from 'react';
import SelectComboCompanyShop from '../../components/SelectCombo/SelectComboCompanyShop';
import { translate } from '../../shared/translate';
import { JSONParseSafely } from '../../shared';

const CompanyShopSelector = ({ multiple, value, onChange, ...props }) => {
  useEffect(() => {
    if (sessionStorage.getItem('shop')) {
      onChange(JSONParseSafely(sessionStorage.getItem('shop')));
    }
  }, []);

  return (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
      <SelectComboCompanyShop
        multiple={multiple}
        extraNodes={[{ name: translate.storewide_analysis || '全店分析' }]}
        placeholder={
          multiple
            ? value?.length > 0
              ? undefined
              : translate.storewide_analysis || '全店分析'
            : translate.storewide_analysis || '全店分析'
        }
        value={value}
        onChange={(value) => {
          sessionStorage.setItem('shop', JSON.stringify(value));
          onChange(value);
        }}
        style={{ width: 200 }}
        {...props}
      />
    </div>
  );
};

export default CompanyShopSelector;
