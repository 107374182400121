import React from 'react';
import { translate } from '../../../shared/translate';

export default {
  option: {
    group: translate.others,
    icon: <img className="h-4" src={require('../../../assets/stripe.png')} alt="stripe" />,
    label: 'Stripe',
    value: 'STRIPE_CREDIT_CARD',
    credentialPlatform: 'STRIPE_CREDIT_CARD',
  },
  form: (actions) => {
    return null;
  },
};
