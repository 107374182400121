import React from 'react';
import ConnectionPageMarker from '../../../components/ConnectionPageMaker';
import { GET_QUERY } from './query';
import { translate } from '../../../shared/translate';
import { JSONParseSafely, NumberFormat, PriceFormat } from '../../../shared';
import EllipsisTypography from '../../../components/EllipsisTypography';
import ConnectionCardHeader from '../../../components/ConnectionPageMaker/ConnectionCardHeader';
import { Divider, Grid, Typography } from '@material-ui/core';
import { theme } from '../../../theme';
import FormHeader from '../FormHeader';
import ExportButton from '../ExportButton';
import { gql } from '@apollo/client';
import { withSalePerformanceRange } from '../../../components/SalePerformanceRangeProvider';

class DiscountAnalyst extends ConnectionPageMarker {
  state = {
    ...this.state,

    title: translate.all_discounts_analyst,
    stickyHeader: true,
    getRowLink: ({ id, name }) => ({
      pathname: `/sale_performance_and_data/discount_sales/${id}`,
      state: { title: name },
    }),
    fields: [
      {
        label: translate.discount,
        title: () => (
          <Typography variant={'h6'} color={'secondary'}>
            {translate.all_discounts}
          </Typography>
        ),
        render: (row) => <EllipsisTypography>{row.name}</EllipsisTypography>,
      },
      {
        label: translate.sold_count,
        title: () => {
          const { totalCount } = this.totalDiscountSales || {};
          return (
            <Typography variant={'h6'} color={'secondary'}>
              {NumberFormat(totalCount)}
            </Typography>
          );
        },
        fieldName: 'count',
        sortBy: 'count',
        type: 'number',
        align: 'right',
        width: 120,
      },
      {
        label: translate.orders,
        title: () => {
          const { totalOrder } = this.totalDiscountSales || {};
          return (
            <Typography variant={'h6'} color={'secondary'}>
              {NumberFormat(totalOrder)}
            </Typography>
          );
        },
        fieldName: 'orderCount',
        sortBy: 'orderCount',
        type: 'number',
        align: 'right',
        width: 100,
      },
      {
        label: translate.sale_amount,
        title: () => {
          const { totalAmount } = this.totalDiscountSales || {};
          return (
            <Typography variant={'h6'} color={'secondary'}>
              {PriceFormat(totalAmount)}
            </Typography>
          );
        },
        fieldName: 'amount',
        sortBy: 'amount',
        type: 'price',
        align: 'right',
        width: 100,
      },
      {
        label: translate.order_average_price,
        title: () => {
          const { totalAvgPrice } = this.totalDiscountSales || {};
          return (
            <Typography variant={'h6'} color={'secondary'}>
              {PriceFormat(totalAvgPrice)}
            </Typography>
          );
        },
        fieldName: 'avgPrice',
        type: 'price',
        align: 'right',
        width: 120,
      },
      {
        label: translate.average_sold_count,
        title: () => {
          const { totalAvgCount } = this.totalDiscountSales || {};
          return (
            <Typography variant={'h6'} color={'secondary'}>
              {NumberFormat(totalAvgCount)}
            </Typography>
          );
        },
        fieldName: 'avgCount',
        type: 'number',
        align: 'right',
        width: 120,
      },

      {
        label: translate.usage_count,
        title: () => {
          const { totalUsageCount } = this.totalDiscountSales || {};
          return (
            <Typography variant={'h6'} color={'secondary'}>
              {NumberFormat(totalUsageCount)}
            </Typography>
          );
        },
        fieldName: 'usageCount',
        sortBy: 'usageCount',
        type: 'number',
        align: 'right',
        width: 100,
      },
      {
        label: translate.discount_amount,
        title: () => {
          const { totalDiscountAmount } = this.totalDiscountSales || {};
          return (
            <Typography variant={'h6'} color={'secondary'}>
              {PriceFormat(Math.abs(totalDiscountAmount))}
            </Typography>
          );
        },
        value: (row) => `${PriceFormat(Math.abs(row.discountAmount))}`,
        align: 'right',
        width: 100,
      },
    ],
    hasQSearch: true,
    qFields: ['name', 'sku'],
  };

  onCompleted(data) {
    const { node } = data || {},
      { report } = node || {},
      { totalDiscountSales } = report || {};
    this.totalDiscountSales = totalDiscountSales;
    this.setState({ ...this.state });
  }

  getSortBy() {
    const { sortBy } = this.state;
    return sortBy ? [sortBy] : [];
  }

  getExtraFetchVariables() {
    const { range } = this.props;
    const { startedAt, startedThru } = range ?? {};

    return {
      startedAt,
      startedThru,
      id: localStorage.getItem('companyId'),
    };
  }

  getSkip() {
    const { range } = this.props;
    const { startedAt, startedThru } = range ?? {};

    if (!startedAt || !startedThru) return true;
    return super.getSkip();
  }

  getQuery() {
    return GET_QUERY;
  }

  getData({ node } = {}) {
    const { report } = node || {},
      { topDiscountSales } = report || {};
    return topDiscountSales;
  }

  renderTable(...args) {
    return (
      <>
        <Divider style={{ height: 2, backgroundColor: '#999' }} />
        {super.renderTable(...args)}
      </>
    );
  }

  renderCardHeader(context) {
    return (
      <>
        <ConnectionCardHeader style={{ paddingBottom: 0, marginBottom: -theme.spacing(1) }}>
          <Grid container justify={'space-between'}>
            <Grid item>
              <Typography variant={'h6'} color={'secondary'}>
                {translate.all_discounts_data}
              </Typography>
            </Grid>
            <Grid item>
              <ExportButton
                mutation={gql`
                  mutation ($shopId: ID!, $startedAt: AWSDateTime!, $startedThru: AWSDateTime!) {
                    discountsReportExport(shopId: $shopId, startedAt: $startedAt, startedThru: $startedThru) {
                      id
                      updatedAt
                      createdAt
                      status
                      output
                    }
                  }
                `}
                {...this.state}
              />
            </Grid>
          </Grid>
        </ConnectionCardHeader>
        {super.renderCardHeader(context)}
      </>
    );
  }
  renderExtras() {
    return <FormHeader />;
  }
}

export default withSalePerformanceRange(DiscountAnalyst);
