import React from 'react';
import ConfigCard from '../ConfigCard';
import PreviewCard from '../PreviewCard';
import SplitCard from '../SplitCard';
import EmailWelcome from '../EmailWelcome';
import moment from 'moment/moment';
import { commonOrderSuggestions } from '../EmailOrderConfirm';

export default class EmailOrderCancel extends EmailWelcome {
  emailType = 'ORDER_CANCELLATION';

  state = {
    ...this.state,
    cards: [
      (props) => (
        <SplitCard
          configCard={
            <ConfigCard
              suggestions={commonOrderSuggestions}
              allows={['isTitle', 'isText', 'isImage', 'isDivider', 'isButton', 'isLink']}
              {...props}
            />
          }
          previewCard={
            <PreviewCard
              extraData={{
                cancelReason: 'Expired',
                order: {
                  id: '00000000-0000-0000-0000-000000000000',
                  referenceNo: 'PO000000',
                  updatedAt: moment().format('DD/MM/YYYY HH:mm:ss'),
                  subtotal: '300.00',
                  total: '350.00',
                  refundAmount: '350.00',
                  refundMethod: '信用卡',
                  paymentMethods: '信用卡支付',
                  shippingProvider: {
                    name: 'SF Express',
                  },
                  shippingAddress: {
                    person: 'Kathy Chan',
                    lines: ['Flat 25, Block A, 10/F, Acacia Building, 150 Kennedy Road, Wan Chai'],
                    country: 'HKG',
                  },
                  currency: '$',
                  totalAdjustments: [
                    {
                      description: '運費',
                      amount: '100.00',
                    },
                    {
                      description: '優惠券',
                      amount: '-50.00',
                    },
                  ],
                },
                items: [
                  {
                    description: '測試商品',
                    quantity: 2,
                    unitPrice: 100,
                    total: 200,
                    sku: '0000000C100000L',
                    media: {
                      src: 'https://assets.omniwe.com/7X0IO45MpkN8FuAnRXSaV.png',
                    },
                    remark: '這是備註訊息',
                  },
                  {
                    media: {
                      src: 'https://assets.omniwe.com/7X0IO45MpkN8FuAnRXSaV.png',
                    },
                    description: '組合商品',
                    quantity: 1,
                    unitPrice: 100,
                    total: 100,
                    bundleProduct: {
                      bundleProduct: {
                        sku: 'BP00000000000L',
                      },
                      options: [
                        {
                          label: '選項 1',
                          product: {
                            name: '測試商品A',
                            medias: [
                              {
                                src: 'https://assets.omniwe.com/7X0IO45MpkN8FuAnRXSaV.png',
                              },
                            ],
                          },
                          variation: {
                            sku: 'V00000000001',
                          },
                        },
                        {
                          label: '選項 2',
                          product: {
                            name: '測試商品B',
                            medias: [
                              {
                                src: 'https://assets.omniwe.com/7X0IO45MpkN8FuAnRXSaV.png',
                              },
                            ],
                          },
                          variation: {
                            sku: 'V00000000002',
                          },
                        },
                      ],
                    },
                  },
                ],
              }}
              {...props}
            />
          }
        />
      ),
    ],
  };
}
