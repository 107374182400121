import { infinityFetch } from '../../index';
import { gql } from '@apollo/client';
import { sumBy } from 'lodash';

const GET_MY_STOCK_WAREHOUSES = gql`
  query ($cursor: Int, $limits: Int, $sortBy: [SorterInput!], $filter: UserShopsFilterInput, $query: String) {
    me {
      ... on User {
        id
        shops(cursor: $cursor, limits: $limits, sortBy: $sortBy, filter: $filter, query: $query) {
          totalCount
          nextCursor
          nodes {
            id
            stockWarehouse {
              id
            }
          }
        }
      }
    }
  }
`;

const GET_QUERY = gql`
  query (
    $id: ID!
    $warehouseIds: [ID!]!
    $cursor: Int
    $limits: Int
    $sortBy: [SorterInput!]
    $filter: InventoryReportDataFilterInput
    $query: String
  ) {
    node(id: $id) {
      ... on Company {
        report {
          inventory(
            warehouseIds: $warehouseIds
            cursor: $cursor
            limits: $limits
            sortBy: $sortBy
            filter: $filter
            query: $query
          ) {
            nextCursor
            totalCount
            nodes {
              sku
              stocks {
                quantity
                warehouseId
              }
            }
          }
        }
      }
    }
  }
`;

export default async function (__, { productSku, shopId, companyId } = {}) {
  try {
    if (!productSku) return [];
    if (!companyId) return [];

    const warehouseIds = await (async () => {
      const { nodes: shops } = await infinityFetch({
        query: GET_MY_STOCK_WAREHOUSES,
        variables: {
          query: `companyId.keyword:${companyId}`,
        },
        getConnection: (data) => data?.me?.shops,
      });
      if (shopId) {
        return [shops.find((shop) => shop?.id === shopId)?.stockWarehouse?.id].filter(Boolean);
      } else {
        return shops.map((shop) => shop?.stockWarehouse?.id).filter(Boolean);
      }
    })();
    if (!warehouseIds?.length) return [];

    const { nodes } = await infinityFetch({
      query: GET_QUERY,
      variables: {
        id: localStorage.getItem('companyId'),
        limits: 100,
        warehouseIds,
        query: `productSkus.keyword:"${productSku}"`,
        fetchPolicy: 'network-only',
      },
      getConnection: (data) => data?.node?.report?.inventory,
    });
    return nodes?.reduce((reducer, node) => {
      return reducer + sumBy(node?.stocks, 'quantity');
    }, 0);
  } catch (e) {
    return [];
  } finally {
  }
}
