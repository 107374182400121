import { Box, Grid } from '@material-ui/core';
import React from 'react';
import CheckboxField from '../../../components/FormPageMaker/Fields/CheckboxField';
import { translate } from '../../../shared/translate';

const KeetaCard = ({ loading, values: { settingKeeta }, setFieldValue, disabled }) => {
  const { id, active, isMandatory } = settingKeeta || {};

  return (
    <Box p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CheckboxField
            loading={loading}
            disabled={disabled}
            text={translate.enable_keeta || '使用Keeta'}
            checked={active}
            onChange={() => setFieldValue('settingKeeta', active ? null : { active: true })}
          />
        </Grid>
        {active && (
          <Grid item xs={12}>
            <Box className="p-8 border">
              <CheckboxField
                loading={loading}
                disabled={disabled}
                text={translate.keeta_must_order_category || 'Keeta必點分類'}
                checked={isMandatory}
                onChange={() => setFieldValue('settingKeeta', { ...settingKeeta, isMandatory: !isMandatory })}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default KeetaCard;
