import React from 'react';
import { Box, TableRow } from '@material-ui/core';
import PrintTable from '../../../components/PrintTable';
import { translate } from '../../../shared/translate';
import { parseConnection, PriceFormat } from '../../../shared';
import { Skeleton } from '@material-ui/lab';
import StyledTableCell from '../../item_Order/ItemsCard/StyledTableCell';
import { Body1Typography } from '../ItemsTable';

const PaymentTable = ({ loading, order }) => {
  const { totalPaid, totalRefund } = order || {},
    { nodes: invoices } = parseConnection((order || {}).invoices);

  return (
    <>
      {invoices.filter((invoice) => invoice.status === 'COMPLETED').length > 0 && (
        <Box mt={2}>
          <PrintTable
            loading={loading}
            data={invoices.filter((invoice) => invoice.status === 'COMPLETED')}
            columns={[
              {
                title: translate.payment_method,
                value: ({ paymentMethod, paymentMethodV2, credential }) => {
                  return paymentMethodV2?.name || paymentMethod?.name || credential?.name;
                },
              },
              {}, // for css -> align table cell
              { width: 120 }, // for css -> align table cell
              {
                title: translate.amount,
                width: 120,
                align: 'left',
                value: ({ total = 0, totalRefund = 0 }) => {
                  if (!totalRefund) return PriceFormat(total);
                  return `${PriceFormat(total)}`;
                },
              },
            ]}
          >
            {totalRefund > 0 && (
              <TableRow style={{ backgroundColor: '#fff' }}>
                <StyledTableCell colSpan={2} align={'right'}>
                  <Body1Typography>{translate.total_refunded}</Body1Typography>
                </StyledTableCell>
                <StyledTableCell colSpan={1} />
                <StyledTableCell colSpan={1} align={'left'}>
                  <Body1Typography>{loading ? <Skeleton width={60} /> : PriceFormat(totalRefund)}</Body1Typography>
                </StyledTableCell>
              </TableRow>
            )}
            <TableRow style={{ backgroundColor: '#fff' }}>
              <StyledTableCell colSpan={2} align={'right'}>
                <Body1Typography>{translate.total}</Body1Typography>
              </StyledTableCell>
              <StyledTableCell colSpan={1} />
              <StyledTableCell colSpan={1} align={'left'}>
                <Body1Typography>{loading ? <Skeleton width={60} /> : PriceFormat(totalPaid)}</Body1Typography>
              </StyledTableCell>
            </TableRow>
          </PrintTable>
        </Box>
      )}
    </>
  );
};

export default PaymentTable;
