import React from 'react';
import FormPageMaker from '../../components/FormPageMaker';
import { CREATE_QUERY, REMOVE_QUERY, GET_QUERY, UPDATE_QUERY } from './query';
import { translate } from '../../shared/translate';
import { client } from '../../shared/apollo';
import { convertMetaArray, convertMetaObject, getCredential } from '../../shared';
import ActivityIndicator from '../../components/ActivityIndicator';
import toast from '../../shared/toast';
import errorParser from '../../shared/errorParser';
import ManualModule from './PaymentExtraForm/ManualModule';
import UnoPayModule from './PaymentExtraForm/UnoPayModule';
import UprisePaymentModule from './PaymentExtraForm/UprisePaymentModule';
import OctopusPOSModule from './PaymentExtraForm/OctopusPOSModule';
import KPayPOSPaymentModule from './PaymentExtraForm/KPayPOSPaymentModule';
import KPayPOSScanModule from './PaymentExtraForm/KPayPOSScanModule';
import KPayPOSOctopusModule from './PaymentExtraForm/KPayPOSOctopusModule';
import KPayPOSPaymeModule from './PaymentExtraForm/KPayPOSPaymeModule';
import KPayCNPSalesGatewayModule from './PaymentExtraForm/KPayCNPSalesGatewayModule';
import KPayAlipaySaleQRModule from './PaymentExtraForm/KPayAlipaySaleQRModule';
import KPayWXPaySaleQRModule from './PaymentExtraForm/KPayWXPaySaleQRModule';
import KPayUnionpaySaleQRModule from './PaymentExtraForm/KPayUnionpaySaleQRModule';
import BBMSLPOSCreditCardModule from './PaymentExtraForm/BBMSLPOSCreditCardModule';
import BBMSLPOSAlipayModule from './PaymentExtraForm/BBMSLPOSAlipayModule';
import BBMSLPOSOctopusModule from './PaymentExtraForm/BBMSLPOSOctopusModule';
import BBMSLPOSWechatModule from './PaymentExtraForm/BBMSLPOSWechatModule';
import BBMSLPOSQuickpassModule from './PaymentExtraForm/BBMSLPOSQuickpassModule';
import BBMSLPOSPaymeModule from './PaymentExtraForm/BBMSLPOSPaymeModule';
import QFPayPOSCardModule from './PaymentExtraForm/QFPayPOSCardModule';
import QFPayPOSWechatPayModule from './PaymentExtraForm/QFPayPOSWechatPayModule';
import QFPayPOSAlipayModule from './PaymentExtraForm/QFPayPOSAlipayModule';
import QFPayPOSPaymeModule from './PaymentExtraForm/QFPayPOSPaymeModule';
import QFPayPOSUnionModule from './PaymentExtraForm/QFPayPOSUnionModule';
import QFPayPOSFPSModule from './PaymentExtraForm/QFPayPOSFPSModule';
import QFPayPOSOctopusModule from './PaymentExtraForm/QFPayPOSOctopusModule';
import QFPayPOSUnionCardModule from './PaymentExtraForm/QFPayPOSUnionCardModule';
import QFPayPOSAmexModule from './PaymentExtraForm/QFPayPOSAmexModule';
import QFPayCheckoutServiceModule from './PaymentExtraForm/QFPayCheckoutServiceModule';
import EFTPOSCreditCardModule from './PaymentExtraForm/EFTPOSCreditCardModule';
import EFTPOSScanModule from './PaymentExtraForm/EFTPOSScanModule';
import EFTPOSOctopusModule from './PaymentExtraForm/EFTPOSOctopusModule';
import EFTAlipayCNModule from './PaymentExtraForm/EFTAlipayCNModule';
import EFTAlipayHKModule from './PaymentExtraForm/EFTAlipayHKModule';
import EFTFPSModule from './PaymentExtraForm/EFTFPSModule';
import EFTOctopusModule from './PaymentExtraForm/EFTOctopusModule';
import EFTPaymeModule from './PaymentExtraForm/EFTPaymeModule';
import EFTUnionpayModule from './PaymentExtraForm/EFTUnionpayModule';
import EFTVMModule from './PaymentExtraForm/EFTVMModule';
import EFTWechatModule from './PaymentExtraForm/EFTWechatModule';
import PaypalStandardModule from './PaymentExtraForm/PaypalStandardModule';
import SoePayPOSCardModule from './PaymentExtraForm/SoePayPOSCardModule';
import SoePayPOSQRModule from './PaymentExtraForm/SoePayPOSQRModule';
import SoePayPOSCashModule from './PaymentExtraForm/SoePayPOSCashModule';
import FreightAmigoAlipayCNModule from './PaymentExtraForm/FreightAmigoAlipayCNModule';
import FreightAmigoAlipayHKModule from './PaymentExtraForm/FreightAmigoAlipayHKModule';
import FreightAmigoWechatCNModule from './PaymentExtraForm/FreightAmigoWechatCNModule';
import FreightAmigoWechatHKModule from './PaymentExtraForm/FreightAmigoWechatHKModule';
import FreightAmigoCreditCardModule from './PaymentExtraForm/FreightAmigoCreditCardModule';
import OCGCWebAlipayModule from './PaymentExtraForm/OCGCWebAlipayModule';
import OCGCWebWechatModule from './PaymentExtraForm/OCGCWebWechatModule';
import AdyenPOSModule from './PaymentExtraForm/AdyenPOSModule';
import StripeCreditCardModule from './PaymentExtraForm/StripeCreditCardModule';

export const modules = [
  ManualModule,
  UnoPayModule,
  UprisePaymentModule,
  OctopusPOSModule,
  KPayPOSPaymentModule,
  KPayPOSScanModule,
  KPayPOSOctopusModule,
  KPayPOSPaymeModule,
  KPayCNPSalesGatewayModule,
  KPayAlipaySaleQRModule,
  KPayWXPaySaleQRModule,
  KPayUnionpaySaleQRModule,
  BBMSLPOSCreditCardModule,
  BBMSLPOSAlipayModule,
  BBMSLPOSOctopusModule,
  BBMSLPOSWechatModule,
  BBMSLPOSQuickpassModule,
  BBMSLPOSPaymeModule,
  QFPayPOSCardModule,
  QFPayPOSWechatPayModule,
  QFPayPOSAlipayModule,
  QFPayPOSPaymeModule,
  QFPayPOSUnionModule,
  QFPayPOSFPSModule,
  QFPayPOSOctopusModule,
  QFPayPOSUnionCardModule,
  QFPayPOSAmexModule,
  QFPayCheckoutServiceModule,
  EFTPOSCreditCardModule,
  EFTPOSScanModule,
  EFTPOSOctopusModule,
  EFTAlipayCNModule,
  EFTAlipayHKModule,
  EFTFPSModule,
  EFTOctopusModule,
  EFTPaymeModule,
  EFTUnionpayModule,
  EFTVMModule,
  EFTWechatModule,
  PaypalStandardModule,
  SoePayPOSCardModule,
  SoePayPOSQRModule,
  SoePayPOSCashModule,
  FreightAmigoAlipayCNModule,
  FreightAmigoAlipayHKModule,
  FreightAmigoWechatCNModule,
  FreightAmigoWechatHKModule,
  FreightAmigoCreditCardModule,
  OCGCWebAlipayModule,
  OCGCWebWechatModule,
  AdyenPOSModule,
  StripeCreditCardModule,
];

export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: CREATE_QUERY,
      remove: REMOVE_QUERY,
    },
    tabs: [
      {
        name: translate.payment_method_info,
        cards: [
          {
            fields: [
              {
                label: translate.name,
                type: 'text',
                name: 'name',
              },
              {
                label: translate.payment_method,
                type: 'select',
                name: 'provider',
                required: true,
                disabled: () => !!this.state.id,
                options: modules.map((module) => module.option),
                afterChanged: async (value, { setFieldValue }) => {
                  if (!value) return;
                  try {
                    ActivityIndicator.show(translate.check_credential);
                    const option = modules.find((opt) => opt.option.value === value)?.option;

                    const credential = await getCredential(option.credentialPlatform);
                    if (!credential) throw new Error('No related credential');
                    setFieldValue('credential', credential);
                  } catch (e) {
                    toast.error(errorParser(e));
                    setFieldValue('provider', undefined);
                  } finally {
                    ActivityIndicator.hide();
                  }
                },
              },
              {
                render: (actions) => {
                  const module = modules.find((opt) => opt.option.value === actions.values.provider);
                  if (!module?.form) return null;
                  return module.form(actions);
                },
              },
              {
                type: 'number',
                label: translate.sort_index,
                name: 'sortIndex',
                description:
                  translate.display_order_description ||
                  '「顯示次序」指的是本項目在前端網站或POS上呈現的次序，系統以升序排列，數字越小，顯示越靠前。',
                required: true,
                inputProps: {
                  step: 1,
                  min: 0,
                },
              },
              {
                label: translate.payment_instruction,
                type: 'html',
                name: 'description',
              },
              {
                label: translate.allow_qrcode_ordering_payment || '允許點餐二維碼付款',
                type: 'checkbox',
                name: 'metadata.allowQRCodeOrdering',
              },
              {
                label: translate.allow_kiosk_payment || '允許Kiosk付款',
                type: 'checkbox',
                name: 'metadata.allowKiosk',
              },
            ],
          },
        ],
      },
    ],
  };

  submit = async (values) => {
    const { id } = this.state;

    if (id)
      await client.mutate({
        mutation: UPDATE_QUERY,
        variables: {
          id,
          input: {
            description: values.description,
            metadata: convertMetaArray(values.metadata),
            name: values.name || '',
            sortIndex: values.sortIndex,
          },
        },
      });
    else
      await client.mutate({
        mutation: CREATE_QUERY,
        variables: {
          input: {
            credentialId: values?.credential?.id,
            description: values?.description,
            metadata: convertMetaArray(values.metadata),
            name: values.name || modules.find((opt) => opt.option.value === values.provider)?.option?.label || '',
            provider: values.provider,
            sortIndex: values.sortIndex,
          },
        },
      });

    return true;
  };

  getInitialData({ node } = {}) {
    const {
      name,
      sortIndex = 0,
      provider,
      description,
      createdAt,
      updatedAt,
      active = true,
      enabled = false,
      metadata,
    } = node ?? {};
    return {
      name,
      sortIndex,
      provider,
      description,
      createdAt,
      updatedAt,
      active,
      enabled,
      metadata: {
        allowQRCodeOrdering: 'true',
        allowKiosk: 'true',
        ...convertMetaObject(metadata),
      },
    };
  }
}
