import { translate } from '../../../../shared/translate';
import { actionWrapper, getCredential } from '../../../../shared';
import { client } from '../../../../shared/apollo';
import { KEETA_MENU_SYNC } from './query';
import confirmation from '../../../../shared/confirmation';

const MenuKeetaSync = {
  name: translate.sync_keeta_products || '同步Keeta產品',
  onClick: async function () {
    if (await confirmation(translate.sync_keeta_confirmation || '是否確認更新Keeta餐單')) {
      await actionWrapper(
        async () => {
          const credential = await getCredential('KEETA');
          if (!credential) throw new Error('No related credential');
          await client.mutate({
            mutation: KEETA_MENU_SYNC,
            variables: {
              shopId: localStorage.getItem('shopId'),
            },
          });
        },
        {
          messages: {
            success: translate.sync_submit || '已提交同步請求至系統，請耐心等候系統處理，處理結果將透過系統訊息通知',
          },
        },
      );
    }
  },
};

export default MenuKeetaSync;
