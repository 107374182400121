import React from 'react';
import { translate } from '../../../shared/translate';

export default {
  option: {
    group: translate.recommend,
    label: translate.manual_payment,
    value: 'MANUAL',
    credentialPlatform: 'MANUAL',
  },
  form: (actions) => {
    return null;
  },
};
