import React from 'react';
import { Grid } from '@material-ui/core';
import SystemButton from '../../SystemButton';
import { Skeleton } from '@material-ui/lab';
import { translate } from '../../../shared/translate';

const DaysOfWeekField = ({ loading, disabled, value, onChange = (_) => {}, required }) => {
  const daysOfWeek = value ?? [];
  return (
    <Grid container spacing={0.5}>
      {[
        { label: translate.sunday || '星期日', value: '0' },
        { label: translate.monday || '星期一', value: '1' },
        { label: translate.tuesday || '星期二', value: '2' },
        { label: translate.wednesday || '星期三', value: '3' },
        { label: translate.thursday || '星期四', value: '4' },
        { label: translate.friday || '星期五', value: '5' },
        { label: translate.saturday || '星期六', value: '6' },
      ].map(({ label, value }, i, arr) => {
        const selected = daysOfWeek?.includes(value);
        return (
          <Grid item key={i}>
            {loading ? (
              <Skeleton
                variant={'rect'}
                width={50}
                height={30}
                style={{
                  borderTopRightRadius: i === arr.length - 1 ? 8 : 0,
                  borderBottomRightRadius: i === arr.length - 1 ? 8 : 0,
                  borderTopLeftRadius: i === 0 ? 8 : 0,
                  borderBottomLeftRadius: i === 0 ? 8 : 0,
                }}
              />
            ) : (
              <SystemButton
                size={'small'}
                color={selected ? 'secondary' : undefined}
                variant={selected ? 'contained' : 'outlined'}
                disabled={disabled}
                style={{
                  marginLeft: i > 0 ? -1 : 0,
                  borderTopRightRadius: i === arr.length - 1 ? undefined : 0,
                  borderBottomRightRadius: i === arr.length - 1 ? undefined : 0,
                  borderTopLeftRadius: i === 0 ? undefined : 0,
                  borderBottomLeftRadius: i === 0 ? undefined : 0,
                }}
                onClick={() => {
                  if (daysOfWeek?.includes(value)) {
                    onChange(daysOfWeek?.filter((v) => v !== value));
                  } else {
                    onChange([...daysOfWeek, value]);
                  }
                }}
              >
                {label}
              </SystemButton>
            )}
          </Grid>
        );
      })}
      <input
        value={daysOfWeek?.join('')}
        required={required}
        style={{
          pointerEvents: 'none',
          position: 'absolute',
          opacity: 0,
        }}
      />
    </Grid>
  );
};

export default DaysOfWeekField;
