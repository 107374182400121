import React from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import { translate } from '../../shared/translate';
import FormRow from '../../components/FormRow';
import SelectComboDiscount from '../../components/SelectCombo/SelectComboDiscount';
import toast from '../../shared/toast';
import LSkeleton from '../../components/LSkeleton';
import SelectComboGift from '../../components/SelectCombo/SelectComboGift';

const ExcludedGiftsCard = ({ values: { id, excludedGifts = [] }, setFieldValue, isSubmitting, loading }) => {
  return (
    <Card elevation={0}>
      <CardContent>
        <Grid container spacing={1}>
          <FormRow title={translate.exclude_the_following_gifts || '不可跟以下贈品共同生效'}>
            {loading ? (
              <LSkeleton height={30} />
            ) : (
              <SelectComboGift
                copyableType={'CompanyGift'}
                multiple
                disabled={isSubmitting}
                value={excludedGifts}
                onChange={(gifts) => {
                  if (gifts.find((gift) => gift.id === id)) toast.error(translate.cannot_exclude_the_current_discount);
                  else setFieldValue('excludedGifts', gifts);
                }}
              />
            )}
          </FormRow>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ExcludedGiftsCard;
