import React, { useEffect, useState } from 'react';
import PopoverWrapper from '../../item_ServiceLocation/ScheduleCalendar/PopoverWrapper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Box, Card, CardContent, Grid, IconButton, Typography } from '@material-ui/core';
import { translate } from '../../../shared/translate';
import { Icon } from '../../../components/IconRender';
import FormRow from '../../../components/FormRow';
import moment from 'moment';
import RemoveToggle from '../../../components/RemoveToggle';
import { convertMetaObject } from '../../../shared';

const EventModal = ({ selectedEvent, onClose = (_) => _, disabled, onRosterSlotDeleteClick = (_) => _ }) => {
  const [box, setBox] = useState({});

  useEffect(() => {
    if (selectedEvent?.anchor) {
      const rect = selectedEvent.anchor.getBoundingClientRect();
      setBox({
        x: rect.left + rect.width / 2,
        y: rect.top,
      });
    }
  }, [selectedEvent?.anchor]);

  const { startedAt, startedThru, customer = {}, staff = {} } = selectedEvent?.resource ?? {};

  const { id: memberId, email: memberEmail, phoneNumber: memberPhoneNumber, metadata = [] } = customer ?? {};
  const { name: memberName } = convertMetaObject(metadata ?? []);

  return (
    <ClickAwayListener onClickAway={() => setTimeout(onClose, 0)}>
      <PopoverWrapper
        box={box}
        visible={!!selectedEvent}
        style={{
          minWidth: 350,
          maxWidth: 360,
          width: '40vw',
        }}
      >
        <Card>
          <CardContent
            style={{
              maxHeight: 'calc(100vh - 80px)',
              overflowY: 'auto',
            }}
          >
            <Grid container justifyContent={'space-between'} alignItems={'center'} spacing={3}>
              <Grid item>
                <Typography variant={'h5'}>{translate.shift_schedule_details || '排班資訊'}</Typography>
              </Grid>
              <Grid item>
                <IconButton size={'small'} onClick={onClose}>
                  <Icon icon={'faTimes'} />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <FormRow xs={12} title={translate.name || '名稱'}>
                    <Typography>{memberId ? memberName : staff?.name}</Typography>
                  </FormRow>
                  <FormRow xs={12} title={translate.contact_number || '電話號碼'}>
                    <Typography>
                      {memberId ? (!!memberPhoneNumber ? memberPhoneNumber : '-') : staff?.phoneNumber}
                    </Typography>
                  </FormRow>
                  {!!memberId && (
                    <FormRow xs={12} title={translate.email}>
                      <Typography>{!!memberEmail ? memberEmail : '-'}</Typography>
                    </FormRow>
                  )}
                  <FormRow xs={12} title={translate.working_days || '工作日'}>
                    <Typography>{moment(startedAt).format('DD MMM YYYY')}</Typography>
                  </FormRow>
                  <FormRow xs={'true'} md={'true'} title={translate.service_location_duration_minutes || '時段長度'}>
                    <Box display={'flex'} alignItems={'center'}>
                      <Typography>{moment(startedAt).format('HH:mm A')}</Typography>
                      <Icon icon={'faArrowRight'} style={{ fontSize: '1rem', verticalAlign: 'sub', margin: '0 8px' }} />
                      <Typography>{moment(startedThru).format('HH:mm A')}</Typography>
                    </Box>
                  </FormRow>
                  <FormRow xs={'auto'} md={'auto'} style={{ alignSelf: 'end' }}>
                    <RemoveToggle
                      disabled={disabled}
                      title={translate.confirm_to_delete}
                      onClick={onRosterSlotDeleteClick}
                    />
                  </FormRow>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </PopoverWrapper>
    </ClickAwayListener>
  );
};

export default EventModal;
