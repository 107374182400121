import React, { useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { translate } from '../../../../shared/translate';
import ActivityIndicator from '../../../../components/ActivityIndicator';
import { toast } from '../../../../shared/toast';
import errorParser from '../../../../shared/errorParser';
import { client } from '../../../../shared/apollo';
import { PRODUCTS_IMPORT_QUERY, PRODUCTS_UPDATE_IMPORT_QUERY, PRODUCTS_SEO_IMPORT_QUERY } from './query';
import FormToolbar from '../../../../components/FormToolbar';
import SystemButton from '../../../../components/SystemButton';
import { Formik } from 'formik';
import useBreakPoint from '../../../../components/useBreakPoint';
import FileUploadField from '../../../../components/FormPageMaker/Fields/FileUploadField';

const ImportContainer = ({ tab = 0, onClick }) => {
  const breakPoint = useBreakPoint();
  const isUnderSM = ['xs'].includes(breakPoint);

  const [loading, setLoading] = useState(false);

  return (
    <Formik
      key={tab}
      initialValues={{}}
      onSubmit={async ({ file }, { setSubmitting }) => {
        const { s3Path } = file || {};
        try {
          setLoading(true);

          if (tab === 0)
            await client.mutate({
              mutation: PRODUCTS_IMPORT_QUERY,
              variables: {
                shopId: localStorage.getItem('shopId'),
                url: s3Path,
              },
            });

          if (tab === 1)
            await client.mutate({
              mutation: PRODUCTS_UPDATE_IMPORT_QUERY,
              variables: {
                shopId: localStorage.getItem('shopId'),
                url: s3Path,
              },
            });

          if (tab === 2)
            await client.mutate({
              mutation: PRODUCTS_SEO_IMPORT_QUERY,
              variables: {
                shopId: localStorage.getItem('shopId'),
                url: s3Path,
              },
            });

          toast.success(translate.importing_submit);
          onClick();
        } catch (e) {
          toast.error(errorParser(e));
        } finally {
          setLoading(false);
          setSubmitting(false);
          ActivityIndicator.hide();
        }
      }}
    >
      {({ values, setFieldValue, handleSubmit, isSubmitting }) => {
        const { file } = values || {};
        const { fileName, fileSize, s3Path } = file || {};
        const _loading = isSubmitting || loading;
        const onClear = () => setFieldValue('file', null);

        return (
          <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box
              p={4}
              style={{
                backgroundColor: '#fff',
                borderRadius: isUnderSM ? 0 : '0 13px 0 0',
                boxShadow: '0px 1px 15px 0px #0000001A',
                flex: 1,
                height: '100%',
                overflowY: 'auto',
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: 'bold', marginBottom: 1 }}>{translate.upload_file}</Typography>

                  <FileUploadField
                    value={{
                      fileSize,
                      fileName,
                      s3Path,
                    }}
                    onChange={(value) => {
                      if (value) setFieldValue('file', value);
                      else {
                        setFieldValue('file', null);
                      }
                    }}
                    onClear={onClear}
                    loading={_loading}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box p={3} style={{ border: `1px solid #f6f6f6`, borderRadius: 13 }}>
                    <Grid container spacing={3}>
                      {tab === 0 && (
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <Typography>
                                {translate.if_not_exist_will_create_new ||
                                  '如果系統中未存在產品編號，上傳的資料將建立一個新的產品'}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>
                                {translate.if_SKU_exist_will_overwrite ||
                                  '如果系統中已存在產品編號，上傳的資料將會覆蓋／取代原有的產品資料'}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      {tab === 1 && (
                        <Grid item xs={12}>
                          <Typography>
                            {translate.existing_SKU_will_add_or_subtracted ||
                              '針對已存在產品編號，上傳的資料將會以添加或減少的方式加進原有的產品資料'}
                          </Typography>
                        </Grid>
                      )}

                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography style={{ fontWeight: 'bold' }}>{translate.tips || '提示'}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography>
                              1.{' '}
                              {translate.download_template_or_update_list ||
                                '請先下載CSV樣本或於產品列表中匯出想更新的產品'}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography>
                              2.{' '}
                              {translate.multiple_separate_with_commas ||
                                '當有多於一張產品圖片或標籤，請以「 , 」分隔圖片連結或標籤值'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <FormToolbar
              extraLeft={
                <SystemButton
                  disabled={_loading}
                  onClick={() => {
                    onClick();
                    onClear();
                  }}
                >
                  {translate.cancel}
                </SystemButton>
              }
              extra={
                <>
                  <SystemButton
                    color="info"
                    disabled={_loading}
                    onClick={async () => {
                      try {
                        setLoading(true);

                        if (tab === 0)
                          window.open('https://omniwe-assets-pro.s3.ap-east-1.amazonaws.com/MXCB7SzXFuwK7fpY5Atkb.csv');
                        if (tab === 1)
                          window.open('https://omniwe-assets-pro.s3.ap-east-1.amazonaws.com/QAAGflb8eZZ67G5qzYS67.csv');
                        if (tab === 2)
                          window.open('https://omniwe-assets-pro.s3.ap-east-1.amazonaws.com/lAIj5DD5nSUjNTVYdt31g.csv');
                      } catch (error) {
                        toast.error(errorParser(error));
                      } finally {
                        setLoading(false);
                      }
                    }}
                  >
                    {translate.download_csv_file || '下載CSV檔案'}
                  </SystemButton>

                  <SystemButton color={'omniewRed'} loading={_loading} disabled={_loading || !fileSize} type={'submit'}>
                    {translate.upload_csv_file || '上傳CSV檔案'}
                  </SystemButton>
                </>
              }
            />
          </form>
        );
      }}
    </Formik>
  );
};

export default ImportContainer;
