import React from 'react';
import { translate } from '../../../shared/translate';

export default {
  option: {
    group: translate.octopus,
    icon: <img className="h-4" src={require('../../../assets/octopus.png')} alt="OCTOPUS POS" />,
    label: `${translate.octopus + translate.offline}`,
    value: 'OCTOPUS_POS',
    credentialPlatform: 'OCTOPUS_POS',
  },
  form: (actions) => {
    return null;
  },
};
