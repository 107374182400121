import React from 'react';
import { Box, Grid } from '@material-ui/core';
import FormRow from 'components/FormRow';
import { translate } from 'shared/translate';
import InputField from 'components/FormPageMaker/Fields/InputField';
import _ from 'lodash';
import StyledConfigList, { AddButton } from '../../../components/StyledConfigList';
import { Icon } from '../../../components/IconRender';
import HashTagsArrayField from '../../../components/FormPageMaker/Fields/HashTagsArrayField';
import ColorsArrayField from '../../../components/FormPageMaker/Fields/ColorsArrayField';
import Dnd from '../../../components/DND';
import { ReactComponent as IconDragVertical } from '../../../assets/icon/drag-vertical.svg';
import TrashButton from '../../../components/TrashButton';
import { inventoryUnits } from '..';

export default ({ loading, isSubmitting, values, values: { combinations = [] } = {}, setFieldValue }) => {
  const onCombinationChange = (combinations) => {
    setFieldValue('combinations', combinations);

    const defaultVariation = {
      sku: '',
      unitPrice: 0,
      cost: 0,
      suggestedRetailPrice: 0,
      quantity: 0,
      weight: 0,
      active: true,
      display: true,
      lowStock: { remind: false, quantity: 0 },
      barcodes: [],
      unit: values?.variations?.[0]?.unit || inventoryUnits[0].value,
    };

    if (_.flatten(_.map(combinations, 'options')).length === 0) {
      return setFieldValue('variations', [defaultVariation]);
    }

    let variations;
    /*Compute_Variations_From_Combinations*/
    variations = combinations
      .map(({ name, options }) => ({ name, options: options.filter((opt) => !!opt && !!opt.name) }))
      .filter(({ name, options }) => !!name && !!options && options.length > 0)
      .reduce(
        (prev, { name: combinationName, options = [] }, i) => {
          return prev.reduce((prev, item) => {
            return [
              ...prev,
              ...options.reduce((prev, { name: optionName, priceAdjustment = 0 }) => {
                return [
                  ...prev,
                  {
                    ...defaultVariation,
                    combination: [...(item.combination || []), { name: combinationName, option: optionName }],
                  },
                ];
              }, []),
            ];
          }, []);
        },
        [{}],
      );
    /*Compute_Variations_From_Combinations*/

    /* Keep_SKU_And_Quantity */
    variations = variations.map((variation) => {
      const find = values.variations.find((_variation) => {
        return matchOptions(_variation.combination, variation.combination);
      });
      if (find) {
        return {
          ...variation,
          ...find,
          combination: variation.combination,
        };
      } else {
        return variation;
      }
    });
    function matchOptions(arr1 = [], arr2 = []) {
      if (arr1.length !== arr2.length) return false;
      return _.differenceBy(arr1, arr2, ({ name, option }) => `${name}: ${option}`).length === 0;
    }
    /* Keep_SKU_And_Quantity */

    setFieldValue('variations', variations);
  };

  return (
    <StyledConfigList>
      {combinations.length > 0 && (
        <Grid item xs={12}>
          <Dnd
            items={combinations}
            direction={'column'}
            disabled={loading || isSubmitting}
            onChange={(v) => onCombinationChange(v)}
            renderItem={(combination, i, { nonDraggableProps }) => {
              const { name = '', options = [] } = combination || {};

              return (
                <Box mx={1.5}>
                  <Grid container spacing={3}>
                    <Grid item md={'auto'}>
                      <Box
                        height={'100%'}
                        display={'flex'}
                        alignItems={'center'}
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        <Icon icon={IconDragVertical} type={'svg'} />
                      </Box>
                    </Grid>
                    <FormRow {...nonDraggableProps} title={translate.variant_name} required={true} md={3} xs>
                      <InputField
                        type={'text'}
                        value={name}
                        options={[translate.color, translate.size]}
                        onChange={(e) => {
                          combination.name = e.target.value;
                          onCombinationChange(combinations);
                        }}
                        maxLength={25}
                        onKeyDown={(e) => {
                          if (e.keyCode === 8) {
                            if (!combination.name) {
                              combinations.splice(i, 1);
                              onCombinationChange(combinations);
                            }
                          }
                        }}
                        required={true}
                        disabled={isSubmitting}
                      />
                    </FormRow>
                    <FormRow {...nonDraggableProps} title={translate.variant_option} required={true} md={true} xs>
                      {name === translate.color ? (
                        <ColorsArrayField
                          disabled={isSubmitting}
                          value={options.map((opt) => opt.name)}
                          onChange={(names) => {
                            combination.options = names.map((name) => ({ name, priceAdjustment: 0 }));
                            onCombinationChange(combinations);
                          }}
                        />
                      ) : (
                        <HashTagsArrayField
                          disabled={isSubmitting}
                          value={options.map((opt) => opt.name)}
                          onChange={(names) => {
                            combination.options = names.map((name) => ({ name, priceAdjustment: 0 }));
                            onCombinationChange(combinations);
                          }}
                        />
                      )}
                    </FormRow>
                    <Grid item {...nonDraggableProps} md={'auto'}>
                      <TrashButton
                        style={{ marginTop: 20 }}
                        onClick={() => {
                          combinations.splice(i, 1);
                          onCombinationChange(combinations);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              );
            }}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <AddButton
          disabled={loading || isSubmitting}
          onClick={() => {
            combinations.push({ name: '', options: [] });
            onCombinationChange(combinations);
          }}
          title={translate.new_variant}
        />
      </Grid>
    </StyledConfigList>
  );
};
