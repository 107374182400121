import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import SwitchField from '../../components/FormPageMaker/Fields/SwitchField';
import { translate } from '../../shared/translate';

const AutoActivateProductField = ({ value, onChange, disabled }) => {
  return (
    <Grid container justifyContent={'space-between'}>
      <Grid item xs={true} md={9}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant={'h6'}>{translate.auto_activate_new_products || '自動啟用新產品'}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'}>
              {translate.auto_activate_new_products_description ||
                '啟用後，總店或任何分店所新增的產品都會自動在所有分店同步並啟用。若需手動管理個別分店的產品，請關閉此設定'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <SwitchField checked={value === 1} disabled={disabled} onChange={() => onChange(!!+value ? 0 : 1)} />
      </Grid>
    </Grid>
  );
};

export default AutoActivateProductField;
