import React from 'react';
import { GET_QUERY } from './query';
import SystemButton from '../../../components/SystemButton';
import { translate } from '../../../shared/translate';
import { StateParamsConnectionPageMarker } from '../../../components/ConnectionPageMaker';
import LocationRuleModalForm from './LocationRuleModalForm';
import moment from 'moment';
import { Icon } from '../../../components/IconRender';

export default class LocationRulesList extends StateParamsConnectionPageMarker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    refreshable: true,
    fields: [
      {
        title: translate.time || '時間',
        value: (row) => {
          return (
            <>
              {moment(row?.startedAt).format('A HH:mm')}
              <Icon icon={'faArrowRight'} style={{ fontSize: '1rem', verticalAlign: 'sub', margin: '0 8px' }} />
              {moment(row?.startedThru).format('A HH:mm')}
            </>
          );
        },
      },
      {
        title: translate.available_date || '有效期',
        value: (row) => {
          const { startedAt, startedThru, daysOfWeek } = row;
          return (
            <>
              {moment(startedAt).format('DD MMM YYYY')}
              <Icon icon={'faArrowRight'} style={{ fontSize: '1rem', verticalAlign: 'sub', margin: '0 8px' }} />
              {/^2999/.test(startedThru) ? translate.permanent : moment(startedThru).format('DD MMM YYYY')}
              {daysOfWeek?.length > 0 && (
                <span>
                  【
                  {daysOfWeek
                    .map(
                      (d) =>
                        ({
                          0: translate.sun,
                          1: translate.mon,
                          2: translate.tue,
                          3: translate.wed,
                          4: translate.thu,
                          5: translate.fri,
                          6: translate.sat,
                        }[d]),
                    )
                    .join(' ')}
                  】
                </span>
              )}
            </>
          );
        },
      },
      {
        width: 150,
        title: translate.max_bookable_quantity || '可預約數量上限',
        type: 'number',
        value: (row) => row?.capacity,
      },
      {
        title: translate.sort_index,
        fieldName: 'sortIndex',
        type: 'number',
        width: 1,
        sortBy: 'sortIndex',
      },
      {
        title: translate.availability,
        align: 'center',
        width: 50,
        fieldName: 'active',
        type: 'bool',
        filter: 'active',
        sortBy: 'active',
      },
    ],
    formInitialData: undefined,
    showForm: false,
    onRowClick: (e, row) => {
      this.setState({ showForm: true, formInitialData: row });
    },
  };

  getData = (data) => data?.node?.rules;

  renderExtras = ({ loading }) => {
    const { disabled } = this.props;
    return (
      <SystemButton
        variant={'contained'}
        color={'secondary'}
        disabled={loading || disabled}
        onClick={() => {
          this.setState({ showForm: true });
        }}
      >
        {translate.new_slot}
      </SystemButton>
    );
  };

  renderModal = () => {
    const { id } = this.props;
    const { showForm, formInitialData } = this.state;
    return (
      <>
        <LocationRuleModalForm
          serviceLocationId={id}
          initialData={formInitialData}
          open={showForm}
          onClose={() => {
            this.setState({ showForm: false, formInitialData: undefined });
          }}
          onSubmitted={() => {
            this.refetch();
            this.setState({ showForm: false, formInitialData: undefined });
          }}
        />
      </>
    );
  };

  getExtraFetchVariables() {
    const { id } = this.props;
    return { id };
  }
}
