import React, { createContext } from 'react';
import moment from 'moment/moment';
import { useSessionStorage } from 'react-use';

export const SalePerformanceRangeContext = createContext([{}, () => {}]);

const SalePerformanceRangeProvider = (props) => {
  const [range, setRange] = useSessionStorage('salePerformanceRange', {
    dayMode: '1',
    startedAt: moment().subtract(7, 'days').startOf('day').toISOString(),
    startedThru: moment().endOf('day').toISOString(),
    shop: null,
  });

  return (
    <SalePerformanceRangeContext.Provider
      value={[
        range,
        (update) => {
          if (typeof update === 'function') setRange(update(range ?? {}));
          else setRange(update);
        },
      ]}
    >
      {props.children}
    </SalePerformanceRangeContext.Provider>
  );
};

export default SalePerformanceRangeProvider;

export const useSalePerformanceRange = () => {
  return React.useContext(SalePerformanceRangeContext);
};

export const withSalePerformanceRange = (Component) => (props) => {
  const [range, setRange] = useSalePerformanceRange();
  return <Component {...props} range={range} setRange={setRange} />;
};
