import { translate } from 'shared/translate';
import ConnectionPageMaker from '../../components/ConnectionPageMaker';
import { GET_QUERY } from './query';
import { getCommonColumns } from '../../shared';
import { modules } from '../item_Payment';
import ActiveSwitcher from './ActiveSwitcher';
import EnabledSwitcher from './EnabledSwitcher';
import React from 'react';
import { checkPermissionV2 } from '../../components/PermissionMask';
import Activation from '../../components/Activation';
import { Grid } from '@material-ui/core';

export default class extends ConnectionPageMaker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    createUrl: '/payments/new',
    getRowLink: ({ id, name }) => ({ pathname: `/payments/${id}`, state: { title: name } }),
    fields: [
      {
        title: translate['name'],
        value: ({ name, provider }) => (
          <Grid container spacing={1}>
            <Grid item>{modules.find((mo) => mo.option.value === provider)?.option?.icon}</Grid>
            <Grid item>{name}</Grid>
          </Grid>
        ),
      },
      {
        title: translate['payment_method'],
        width: 200,
        value: ({ provider } = {}) => {
          return modules.find((mo) => mo.option.value === provider)?.option?.label || provider;
        },
      },
      {
        title: translate.sort_index,
        width: 1,
        align: 'center',
        fieldName: 'sortIndex',
        type: 'number',
      },
      ...getCommonColumns({
        createdAt: {
          filter: null,
          sortBy: null,
        },
        updatedAt: {
          filter: null,
          sortBy: null,
        },
      }),
      {
        title: translate.list_activate,
        fieldName: 'enabled',
        type: 'bool',
        align: 'center',
        render: (data) => {
          const { id, enabled } = data || {};
          return !!checkPermissionV2('enablePaymentMethod') ? (
            <EnabledSwitcher id={id} enabled={enabled} onCompleted={this.refetch} />
          ) : (
            <Activation active={enabled} />
          );
        },
      },
      {
        title: translate.list_enable,
        fieldName: 'active',
        type: 'bool',
        align: 'center',
        render: (data) => {
          const { id, enabled, active } = data || {};
          return <ActiveSwitcher disabled={!enabled} id={id} active={active} onCompleted={this.refetch} />;
        },
      },
    ],
    selectedItem: undefined,
  };

  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }
  getData = ({ node } = {}) => {
    return node?.paymentMethods;
  };
}
