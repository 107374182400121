import React from 'react';
import { translate } from '../../../shared/translate';

export default {
  option: {
    group: `OCGC ${translate.online || '（線上）'}`,
    icon: <img className="h-4" src={require('../../../assets/logo_OCGC.png')} alt="OCGC alipay" />,
    label: `OCGC ${translate.alipay}` || 'OCGC 支付寶',
    value: 'OCGC_WEB_ALIPAY',
    credentialPlatform: 'OCGC',
  },
  form: (actions) => {
    return null;
  },
};
