import React from 'react';
import { Grid, InputAdornment } from '@material-ui/core';
import FormRow from '../../../components/FormRow';
import { translate } from '../../../shared/translate';
import InputField from '../../../components/FormPageMaker/Fields/InputField';
import SelectField from '../../../components/FormPageMaker/Fields/SelectField';
import VariationSkusField from './VariationSkusField';

const TriggerVariationField = ({ disabled, value, onChange }) => {
  return (
    <Grid container>
      <FormRow title={translate.designated_product}>
        <VariationSkusField
          disabled={disabled}
          value={value.triggerIds}
          onChange={(ids, variations) => {
            onChange({ ...value, triggerIds: ids, _triggerNodes: variations });
          }}
        />
      </FormRow>
      <FormRow
        title2={translate.purchase_count || '買滿件數'}
        title={
          <SelectField
            required={true}
            variant={'standard'}
            InputProps={{
              disableUnderline: true,
            }}
            disabled={disabled}
            value={value.triggerValueType}
            onChange={(e) => {
              value.triggerValueType = e.target.value;
              onChange(value);
            }}
            options={[
              {
                label: translate.purchase_count || '買滿件數',
                value: 'QUANTITY',
              },
              {
                label: translate.purchase_amount || '買滿金額',
                value: 'AMOUNT',
              },
            ]}
          />
        }
      >
        <InputField
          required={true}
          inputProps={{ step: 1, min: 0 }}
          disabled={disabled}
          value={value.triggerValue}
          onChange={(e) => {
            value.triggerValue = e.target.value;
            onChange(value);
          }}
          InputProps={{
            startAdornment:
              value.triggerValueType === 'AMOUNT' ? <InputAdornment position={'start'}>$</InputAdornment> : undefined,
            endAdornment:
              value.triggerValueType === 'QUANTITY' ? (
                <InputAdornment position={'end'}>{translate.unit_item}</InputAdornment>
              ) : undefined,
          }}
        />
      </FormRow>
    </Grid>
  );
};

export default TriggerVariationField;
