import React from 'react';
import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { GET_QUERY } from './query';
import { translate } from '../../../shared/translate';
import moment from 'moment';
import { Icon } from '../../../components/IconRender';

export default class extends ConnectionPageMaker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    createUrl: '/rosters/new',
    getRowLink: ({ id, referenceNo }) => ({
      pathname: `/rosters/${id}`,
      state: { title: referenceNo },
    }),
    fields: [
      {
        title: translate.shift_schedule_name || '排班表名稱',
        fieldName: 'name',
        sortBy: 'name',
        filter: 'name',
        filterType: 'text',
      },
      {
        title: translate.working_days || '工作日',
        value: (row) => {
          const { startedAt, startedThru } = row;
          return (
            <>
              {moment(startedAt).format('DD MMM YYYY')}
              <Icon icon={'faArrowRight'} style={{ fontSize: '1rem', verticalAlign: 'sub', margin: '0 8px' }} />
              {moment(startedThru).format('DD MMM YYYY')}
            </>
          );
        },
      },
    ],
  };
  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }
  getData = (data) => {
    return data?.node?.rosters;
  };

  getFilter(...args) {
    return {
      startedAt: {
        operator: 'NIN',
        value: [null],
      },
      ...super.getFilter(...args),
    };
  }
}
