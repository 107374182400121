import React from 'react';
import PromisifyModal from './PromisifyModal';
import { Box, DialogContent, Divider, Grid, Typography } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import { translate } from '../shared/translate';
import FormToolbar from './FormToolbar';

export default class ConfirmModal extends PromisifyModal {
  execute = (message, title, submitBtnType = 'submit', content, transparentBackground = false, cancelText) => {
    return new Promise((resolve, reject) => {
      this.setState({
        resolve,
        reject,
        show: true,
        message,
        title,
        submitBtnType,
        content,
        transparentBackground,
        cancelText,
        init: {},
      });
    });
  };
  renderHeader({ values }) {
    const { title, content } = this.state;
    if (content) return null;
    if (!!title)
      return (
        <DialogTitle style={{ textAlign: 'center', minWidth: 300 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                className="pt-2 pb-1"
                style={{ verticalAlign: 'middle', fontWeight: 'bold', fontSize: '1.2rem', color: '#000' }}
              >
                {title}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </DialogTitle>
      );
  }
  renderBody({ values }) {
    const { title, message, content } = this.state;
    if (content) return <>{content}</>;
    if (message)
      return (
        <DialogContent>
          {!!title ? (
            <Box className={'text-center mt-14 mb-20 mx-8'}>
              <Typography style={{ whiteSpace: 'pre-line', fontSize: '1.1rem' }}>{message}</Typography>
            </Box>
          ) : (
            <Box className={'text-center my-20 mx-8'}>
              <Typography style={{ whiteSpace: 'pre-line', color: '#000', fontWeight: 'bold', fontSize: '1.1rem' }}>
                {message}
              </Typography>
            </Box>
          )}
        </DialogContent>
      );
  }
  renderConfirmText() {
    return translate.confirm;
  }

  renderCancelText() {
    const { cancelText } = this.state;
    if (cancelText) return cancelText;
    return translate.back;
  }

  renderFooter({ isSubmitting }) {
    const { transparentBackground } = this.state;
    return (
      <FormToolbar
        transparentBackground={transparentBackground}
        disabled={isSubmitting}
        submitBtnType={this.state.submitBtnType}
        onCancel={this.onCancelClick}
        cancelText={this.renderCancelText()}
        confirmText={this.renderConfirmText()}
      />
    );
  }
}
