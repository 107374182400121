import React from 'react';
import AppointmentCalendar from './AppointmentCalendar';
import Taber from '../../components/Taber';
import { Box, Card } from '@material-ui/core';
import useQueryParams from '../../hooks/useQueryParams';
import ListView from './ListView';
import { translate } from '../../shared/translate';

export default (props) => {
  const [query, setQuery] = useQueryParams();

  return (
    <div>
      <Taber
        currentTab={+query?.tab || 0}
        tabs={[
          {
            name: translate.booking_overview || '預約總覽',
          },
          {
            name: translate.booking_schedule || '預約日曆',
          },
        ]}
        onTabChange={(tab) => setQuery({ tab })}
      />
      {
        {
          0: <ListView {...props} />,
          1: (
            <Card square elevation={0}>
              <Box p={2}>
                <AppointmentCalendar />
              </Box>
            </Card>
          ),
        }[query?.tab || 0]
      }
    </div>
  );
};
