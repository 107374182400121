import React from 'react';
import ConnectionPageMaker from '../../components/ConnectionPageMaker';
import { GET_QUERY } from './query';
import { translate } from 'shared/translate';
import { getCommonColumns, PriceFormat } from 'shared';
import MenuExport from './ListView/MenuExport';

export default class extends ConnectionPageMaker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    selectionMode: true,
    menus: [MenuExport],
    fields: [
      {
        title: translate.device,
        width: 250,
        fieldName: 'deviceId',
        type: 'text',
        filter: 'deviceId',
        sortBy: 'deviceId',
      },
      {
        title: translate.remark,
        fieldName: 'remark',
        type: 'text',
        filter: 'remark',
        sortBy: 'remark',
      },
      {
        title: translate.type,
        width: 100,
        align: 'center',
        fieldName: 'direction',
        type: 'option',
        options: [
          {
            label: translate.inbound,
            value: 'INBOUND',
          },
          {
            label: translate.outbound,
            value: 'OUTBOUND',
          },
        ],
        filter: 'direction',
        sortBy: 'direction',
      },
      {
        title: translate.amount,
        width: 120,
        align: 'right',
        render: ({ amount, direction }) => (
          <span
            className={`${
              {
                INBOUND: 'text-success',
                OUTBOUND: 'text-danger',
              }[direction]
            }`}
          >
            {PriceFormat(amount)}
          </span>
        ),
        fieldName: 'amount',
        type: 'price',
        filter: 'amount',
        sortBy: 'amount',
      },
      ...getCommonColumns(),
    ],
  };

  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }

  getData = ({ node } = {}) => {
    const { cashFlows } = node || {};
    return cashFlows;
  };
}
