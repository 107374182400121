import React from 'react';
import { translate } from '../../../shared/translate';

export default {
  option: {
    group: `${translate.qfpay || '錢方'} ${translate.online || '（線上）'}`,
    icon: <img className="h-4" src={require('../../../assets/QFPay Logo-p-500.png')} alt="qf" />,
    label: translate.qfpay_checkout_service || '錢方收銀台服務',
    value: 'QFPAY_CHECKOUT_SERVICE',
    credentialPlatform: 'QFPAY',
  },
  form: (actions) => {
    return null;
  },
};
