import React from 'react';
import { Card, InputAdornment, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { translate } from '../../../shared/translate';
import InputField from '../../../components/FormPageMaker/Fields/InputField';
import VariationCombinationDisplay from '../../../components/VariationCombinationDisplay';
import CheckboxField from '../../../components/FormPageMaker/Fields/CheckboxField';
import { omit } from 'lodash';

const KeetaVariationsTable = ({ userGetModeList = [], disabled, variations = [], value = {}, onChange = (_) => _ }) => {
  return (
    <Card variant={'outlined'}>
      <Table style={{ width: '100%' }} size={'small'}>
        <TableHead style={{ backgroundColor: '#eee' }}>
          <TableCell width={50}>
            <CheckboxField
              indeterminate={Object.keys(value).length > 0 && Object.keys(value).length < variations.length}
              disabled={disabled}
              checked={Object.keys(value).length === variations.length}
              onChange={() => {
                if (Object.keys(value).length === variations.length) {
                  onChange({});
                } else {
                  onChange(
                    variations.reduce((reducer, variation) => {
                      reducer[variation?.sku] = {
                        sku: variation?.sku,
                        price: variation?.unitPrice || 0,
                        pickPrice: variation?.unitPrice || 0,
                      };
                      return reducer;
                    }, {}),
                  );
                }
              }}
            />
          </TableCell>
          <TableCell>SKU</TableCell>
          <TableCell>{translate.variant}</TableCell>
          <TableCell width={150}>{translate.take_out_price || '外賣價'}</TableCell>
          <TableCell width={150}>{translate.pick_up_price || '自取價'}</TableCell>
        </TableHead>
        <TableBody>
          {variations.map((variation, i) => {
            const val = value[variation?.sku];
            const canDelivery = userGetModeList.includes('delivery');
            const canPickup = userGetModeList.includes('pickup');
            return (
              <TableRow key={variation?.id || i} style={{ backgroundColor: !val ? '#00000011' : undefined }}>
                <TableCell>
                  <CheckboxField
                    disabled={disabled}
                    checked={!!val}
                    onChange={() => {
                      if (!!val) {
                        onChange(omit(value, variation?.sku));
                      } else {
                        onChange({
                          ...value,
                          [variation?.sku]: {
                            sku: variation?.sku,
                            price: variation?.unitPrice || 0,
                            pickPrice: variation?.unitPrice || 0,
                          },
                        });
                      }
                    }}
                  />
                </TableCell>
                <TableCell>{variation?.sku}</TableCell>
                <TableCell>
                  <VariationCombinationDisplay
                    style={{ paddingTop: 6 }}
                    typographyStyle={{ fontSize: '0.98em' }}
                    combination={variation?.combination}
                  />
                </TableCell>
                <TableCell style={{ opacity: !canDelivery ? 0.25 : 1 }}>
                  <InputField
                    disabled={disabled || !canDelivery || !val}
                    value={val?.price}
                    type={'number'}
                    inputProps={{
                      min: 0,
                      step: 0.01,
                    }}
                    onChange={(e) => {
                      val.price = e.target.value;
                      onChange(value);
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </TableCell>
                <TableCell style={{ opacity: !canPickup ? 0.25 : 1 }}>
                  <InputField
                    disabled={disabled || !canPickup || !val}
                    value={val?.pickPrice}
                    type={'number'}
                    inputProps={{
                      min: 0,
                      step: 0.01,
                    }}
                    onChange={(e) => {
                      val.pickPrice = e.target.value;
                      onChange(value);
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Card>
  );
};

export default KeetaVariationsTable;
