import { infinityFetch } from '../../index';
import { gql } from '@apollo/client';
import { FRAGMENT_LIST_APPOINTMENT } from '../../../routes/list_Appointments/AppointmentCalendar/query';

const GET_QUERY = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $filter: ShopAppointmentFilterInput, $sortBy: [SorterInput!]) {
    node(id: $id) {
      ... on CompanyShop {
        appointments(cursor: $cursor, limits: $limits, filter: $filter, sortBy: $sortBy) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_LIST_APPOINTMENT
          }
        }
      }
    }
  }
  ${FRAGMENT_LIST_APPOINTMENT}
`;

export default async function (source, { orderId }) {
  return await infinityFetch({
    query: GET_QUERY,
    variables: {
      id: localStorage.getItem('shopId'),
      filter: {
        orderId: {
          operator: 'IN',
          value: [orderId],
        },
      },
    },
    getConnection: (data) => data?.node?.appointments,
  });
}
