import { translate } from '../../../../shared/translate';
import ActivityIndicator from '../../../../components/ActivityIndicator';
import { client } from '../../../../shared/apollo';
import toast from '../../../../shared/toast';
import errorParser from '../../../../shared/errorParser';
import { gql } from '@apollo/client';

const EXPORT_QUERY = gql`
  mutation ($shopId: ID!, $filter: CashFlowFilterInput) {
    cashFlowsExport(shopId: $shopId, filter: $filter) {
      id
      status
    }
  }
`;

const MenuExport = {
  name: translate.export,
  enable: function () {
    return this.getSelectionCount() > 0;
  },
  onClick: async function () {
    try {
      ActivityIndicator.show();
      await client.mutate({
        mutation: EXPORT_QUERY,
        variables: {
          shopId: localStorage.getItem('shopId'),
          filter: this.getSelectionFilter(),
        },
      });
      toast.success(translate.export_tips);
    } catch (e) {
      toast.error(errorParser(e));
    } finally {
      ActivityIndicator.hide();
    }
  },
};

export default MenuExport;
