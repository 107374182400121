import React from 'react';
import { translate } from '../../../shared/translate';

export default {
  option: {
    group: `KPAY ${translate.online || '（線上）'}`,
    icon: <img className="h-4" src={require('../../../assets/Logo_KPay.png')} alt="kpay" />,
    label: translate.kpay_wechat_payment || 'KPAY 微信支付',
    value: 'KPAY_WXPAY_SALE_QR',
    credentialPlatform: 'KPAY',
  },
  form: (actions) => {
    return null;
  },
};
