import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query ($id: ID!, $cursor: Int, $limits: Int, $filter: VariationPriceHistoryFilterInput, $sortBy: [SorterInput!]) {
    node(id: $id) {
      ... on ShopProduct {
        priceHistories(cursor: $cursor, limits: $limits, filter: $filter, sortBy: $sortBy) {
          totalCount
          nextCursor
          nodes {
            id
            createdAt
            unitPrice
            createdBy {
              id
              email
            }
            sku
            combination
          }
        }
      }
    }
  }
`;
