import React from 'react';
import { GET_VARIATIONS } from './query';
import { parseConnection } from '../../../../shared';
import { Query } from '@apollo/client/react/components';
import SelectComboVariation from '../../../../components/SelectCombo/SelectComboVariation';

const VariationSkusField = ({ disabled, value: skus = [], onChange = (skus, nodes) => {}, ...props }) => {
  return (
    <Query
      query={GET_VARIATIONS}
      variables={{ id: localStorage.getItem('shopId'), skus }}
      skip={!skus?.length}
      onCompleted={({ node } = {}) => {
        onChange(skus, parseConnection((node || {}).products).nodes);
      }}
    >
      {({ data: { node } = {} }) => {
        const _variations = parseConnection(node?.allVariationsBySkus).nodes;
        const variations = _variations.concat(
          skus.filter((sku) => !_variations.find((col) => col.sku === sku)).map((sku) => ({ sku })),
        );

        return (
          <SelectComboVariation
            {...props}
            copyableType={'ProductVariation'}
            idKey={'sku'}
            multiple={true}
            required={true}
            disabled={disabled}
            value={variations}
            onChange={(variations) => {
              onChange(
                variations.map((product) => product.sku),
                variations,
              );
            }}
          />
        );
      }}
    </Query>
  );
};

export default VariationSkusField;
