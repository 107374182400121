import React, { Fragment, useState } from 'react';
import { Grid, Typography, InputAdornment, Box } from '@material-ui/core';
import { translate } from '../../shared/translate';
import { Icon } from '../../components/IconRender';
import InputField from '../../components/FormPageMaker/Fields/InputField';
import SelectComboVariation from '../../components/SelectCombo/SelectComboVariation';
import SelectField from '../../components/FormPageMaker/Fields/SelectField';
import SelectComboBundleProductFull from '../../components/SelectCombo/SelectComboBundleProductFull';
import _ from 'lodash';
import uuid from 'react-uuid';
import Dnd from '../../components/DND';
import { ReactComponent as IconDragVertical } from '../../assets/icon/drag-vertical.svg';
import StyledConfigList, { AddButton } from '../../components/StyledConfigList';
import TrashButton from '../../components/TrashButton';

export default ({ value: items = [], onChange, disabled }) => {
  const [itemTypes, setItemTypes] = useState({}); // Separate state for item types

  const handleItemTypeChange = (index, value) => {
    setItemTypes((prevItemTypes) => ({
      ...prevItemTypes,
      [index]: value,
    }));
  };

  return (
    <>
      <Typography variant={'h6'}>{translate.checkout_products}</Typography>
      <Fragment>
        <StyledConfigList spacing={0}>
          <Grid item xs={12}>
            <Box mb={2}>
              <Dnd
                items={items}
                onChange={onChange}
                disabled={disabled}
                direction={'column'}
                getKey={(item, i) => item?.id ?? i}
                spacing={2}
                renderItem={(item, i, { nonDraggableProps }) => {
                  const { id, itemType, product, variation, quantity, bundleProduct } = item || {};

                  return (
                    <Grid container spacing={1} alignItems={'center'}>
                      <Grid item>
                        <Icon icon={IconDragVertical} type={'svg'} style={{ cursor: 'pointer' }} />
                      </Grid>
                      <Grid item {...nonDraggableProps}>
                        <SelectField
                          value={itemType}
                          onChange={(e) => {
                            const value = e.target.value;
                            onChange(
                              items.map((item, j) => {
                                if (j === i) {
                                  return {
                                    ...item,
                                    itemType: value,
                                  };
                                }
                                return item;
                              }),
                            );
                          }}
                          options={[
                            {
                              name: translate.product,
                              type: 'product',
                            },
                            {
                              name: translate.bundle_product,
                              type: 'bundleProduct',
                            },
                          ].map(({ name, type }, i) => ({ label: name, value: type }))}
                        />
                      </Grid>

                      {
                        {
                          bundleProduct: (
                            <Grid item xs={true} {...nonDraggableProps}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <SelectComboBundleProductFull
                                    key={items?.length}
                                    showSubtitle={false}
                                    value={bundleProduct}
                                    onChange={(v) => {
                                      onChange(
                                        items.map((item, j) => {
                                          if (i === j) {
                                            return {
                                              ...item,
                                              bundleProduct: v,
                                              sections: [],
                                            };
                                          }
                                          return item;
                                        }),
                                      );
                                    }}
                                    disabled={disabled}
                                  />
                                </Grid>
                                {bundleProduct?.sections?.map((section, j) => {
                                  const { label, options } = section ?? {};

                                  return (
                                    <Grid
                                      key={j}
                                      container
                                      xs={12}
                                      style={{
                                        marginTop: '8px',
                                      }}
                                      alignItems={'center'}
                                    >
                                      <Grid xs={2} item>
                                        <Typography>{label}</Typography>
                                      </Grid>
                                      <Grid xs={10} item>
                                        <SelectComboVariation
                                          productIds={_.map(options, (opt) => opt?.product?.id).filter((_) => _)}
                                          showSubtitle={false}
                                          value={item.sections?.[j]?.variation}
                                          onChange={(variation) => {
                                            onChange(
                                              items.map((item, k) => {
                                                if (k === i) {
                                                  return {
                                                    ...item,
                                                    sections: bundleProduct?.sections?.map((section, l) => {
                                                      if (l === j) {
                                                        return {
                                                          optionId: options.find(
                                                            (option) => option.product.id === variation?.product?.id,
                                                          )?.id,
                                                          variation,
                                                        };
                                                      }
                                                      return item.sections?.[l];
                                                    }),
                                                  };
                                                }
                                                return item;
                                              }),
                                            );
                                          }}
                                          disabled={disabled}
                                        />
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </Grid>
                          ),
                          product: (
                            <Grid item xs={true} {...nonDraggableProps}>
                              <SelectComboVariation
                                key={items?.length}
                                showSubtitle={false}
                                value={variation}
                                onChange={(variation) => {
                                  item.product = variation?.product;
                                  item.variation = variation;
                                  onChange(items);
                                }}
                                disabled={disabled}
                              />
                            </Grid>
                          ),
                        }[itemType]
                      }

                      <Grid item {...nonDraggableProps}>
                        <InputField
                          type={'number'}
                          // eslint-disable-next-line
                          inputProps={{
                            min: 1,
                            step: 1,
                          }}
                          // eslint-disable-next-line
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position={'start'}>
                                <Typography>{translate.quantity}</Typography>
                              </InputAdornment>
                            ),
                          }}
                          disabled={disabled}
                          placeholder={'Quantity'}
                          value={quantity}
                          onChange={(e) => {
                            item.quantity = e.target.value;
                            onChange(items);
                          }}
                          required={true}
                        />
                      </Grid>

                      <Grid item {...nonDraggableProps}>
                        <TrashButton
                          disabled={disabled}
                          onClick={() => {
                            items.splice(i, 1);
                            onChange(items);
                          }}
                        />
                      </Grid>
                    </Grid>
                  );
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <AddButton
              disabled={disabled}
              onClick={() => onChange([...items, { quantity: 1, id: uuid(), itemType: 'product' }])}
              title={translate.add_products}
            />
          </Grid>
        </StyledConfigList>
      </Fragment>
    </>
  );
};
