import { translate } from 'shared/translate';
import FormPageMaker from '../../components/FormPageMaker';
import { CREATE_QUERY, GET_QUERY, POST_QUERY, REMOVE_QUERY } from './query';
import React from 'react';
import {
  cloneObject,
  convertMetaArray,
  convertMetaObject,
  getRandomString,
  parseConnection,
  removeTypename,
  toInputMedia,
} from '../../shared';
import { InputAdornment, Typography } from '@material-ui/core';
import CollectionsField from '../item_Product/CollectionsField';
import moment from 'moment';
import SwitchGreyField from '../../components/FormPageMaker/Fields/SwitchGreyField';
import ModifiersField from './ModifiersField';
import ModifiersExtendField from './ModifiersExtendField';
import ServicesField from './ServicesField';
import { client } from '../../shared/apollo';
import _ from 'lodash';
import StockField from '../item_Product/StockField';
import SeoCardSetting from '../item_Product/SeoCardSetting';
import CustomFormField from '../../components/CustomFormField';
import TooltipContent from '../../components/TooltipContent';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: !!this.state.id ? POST_QUERY : CREATE_QUERY,
      remove: REMOVE_QUERY,
    },
    tabs: [
      {
        name: translate.booking_setting,
        cards: [
          {
            fields: [
              {
                label: translate.booking_name || '預約名稱',
                type: 'text',
                maxLength: 25,
                name: 'name',
                required: true,
                md: 6,
              },
              {
                label: translate.title,
                type: 'text',
                maxLength: 50,
                name: 'subtitle',
                md: 6,
              },
              {
                md: 6,
                label: translate.product_collections,
                render: (props) => <CollectionsField {...props} />,
              },
              {
                md: 6,
                label: translate.sort_index,
                type: 'number',
                name: 'primarySortIndex',
                description:
                  translate.display_order_description ||
                  '「顯示次序」指的是本項目在前端網站或POS上呈現的次序，系統以升序排列，數字越小，顯示越靠前。',
                inputProps: {
                  step: 1,
                },
              },
              {
                label: translate.hash_tag,
                description: translate.hash_tag_description || '系統會自動在輸入內容前加上 "#" 符號，請直接輸入內容',
                name: 'hashtags',
                type: 'hashTagsArray',
                md: 12,
                placeholder:
                  translate.remark_hashtags_placeholder || 'e.g. 熱門優惠　新品上市　Hot Deals　New Arrivals',
              },
              {
                label: translate.max_bookable_quantity || '可預約數量上限',
                md: 6,
                required: true,
                tooltip: (
                  <TooltipContent
                    points={[
                      <Typography variant="body2">
                        {translate.associated_appointments_tips ||
                          '此數量不與實際庫存同步。顧客付款後，數量會即時減少，售罄即無法加入購物車'}
                      </Typography>,
                    ]}
                  />
                ),
                render: ({ values, loading, isSubmitting, setFieldValue }) => {
                  return (
                    <StockField
                      loading={loading}
                      disabled={isSubmitting}
                      value={{
                        quantity: values.quantity,
                        ignoreStock: values.ignoreStock,
                      }}
                      onChange={(update) => {
                        setFieldValue('quantity', update.quantity);
                        setFieldValue('ignoreStock', update.ignoreStock);
                      }}
                    />
                  );
                },
              },
              {
                label: translate.price,
                description: translate.price_description || '請輸入售價',
                name: 'unitPrice',
                type: 'number',
                required: true,
                placeholder: '0.00',
                md: 3,
                inputProps: {
                  min: 0,
                  step: 0.01,
                },
                InputProps: {
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                },
              },
              {
                label: translate.suggested_retail_price,
                description: translate.suggested_retail_price_description || '請輸入建議零售價',
                name: 'suggestedRetailPrice',
                type: 'number',
                placeholder: '0.00',
                md: 3,
                inputProps: {
                  min: 0,
                  step: 0.01,
                },
                InputProps: {
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                },
              },
              {
                label: translate.image,
                type: 'medias',
                name: 'medias',
              },
              {
                label: translate.shelf_time,
                name: 'publishAt',
                type: 'datetime-local',
                md: 6,
              },
              {
                label: translate.off_shelf_time,
                name: 'publishThru',
                type: 'datetime-local',
                md: 6,
                hasPermanent: true,
              },
              {
                md: 12,
                label: translate.booking_description || '預約簡介',
                type: 'html',
                name: 'description',
              },
              {
                label: translate.purchase_options || '購物選項',
                render: ({ values, setFieldValue, disabled, loading }) => (
                  <>
                    <ModifiersField
                      loading={loading}
                      disabled={disabled}
                      value={values.modifiers}
                      onChange={(v) => setFieldValue('modifiers', v)}
                    />
                    <ModifiersExtendField
                      loading={loading}
                      disabled={disabled}
                      value={values.modifiers}
                      onChange={(v) => setFieldValue('modifiers', v)}
                    />
                  </>
                ),
              },
            ],
          },
          {
            name: translate.booking_item_management || '預約項目設定',
            fields: [
              {
                render: ({ values, setFieldValue, disabled, loading }) => (
                  <SwitchGreyField
                    label={translate.schedule_time_after_purchase || '購買後預約時間'}
                    checked={!values.slotRequiredAtCheckout}
                    onChange={(e) => {
                      const slotRequiredAtCheckout = !values.slotRequiredAtCheckout;
                      setFieldValue('slotRequiredAtCheckout', slotRequiredAtCheckout);
                      if (slotRequiredAtCheckout) setFieldValue('validationStrategy', 'PARALLEL');
                    }}
                    loading={loading}
                    disabled={disabled || !!values?.id}
                  />
                ),
              },
              {
                display: ({ values }) => !values.slotRequiredAtCheckout,
                render: ({ values, setFieldValue, disabled, loading }) => (
                  <SwitchGreyField
                    label={translate.bookings_must_be_mad_in_sequence || '項目必需按順序進行'}
                    remark={translate.system_open_slots_in_order || '如按順序進行，系統將依序開放預約'}
                    checked={values.validationStrategy === 'SERIES'}
                    onChange={(e) => setFieldValue('validationStrategy', e.target.checked ? 'SERIES' : 'PARALLEL')}
                    loading={loading}
                    disabled={disabled || !!values?.id}
                  />
                ),
              },
              {
                render: ({ values, disabled, setFieldValue, loading }) => (
                  <ServicesField
                    loading={loading}
                    disabled={disabled}
                    validationStrategy={values.validationStrategy}
                    value={values.services}
                    onChange={(v) => setFieldValue('services', v)}
                  />
                ),
              },
            ],
          },
        ],
      },
      {
        name: translate.customize_table,
        cards: [
          {
            fields: [
              {
                md: 'auto',
                label: translate.contact_info_required || '預約時須提供聯絡資料',
                type: 'checkbox',
                name: 'metadata.mustHaveContactAddress',
              },
              {
                label: `${translate.product_remarks}`,
                render: ({ disabled, values, setFieldValue }) => {
                  return (
                    <CustomFormField
                      onlyAllowFieldTypes={['TEXT']}
                      useFieldKey={false}
                      disabled={disabled}
                      value={values?.metadataFields}
                      onChange={(v) => {
                        setFieldValue('metadataFields', v);
                      }}
                    />
                  );
                },
              },
            ],
          },
        ],
      },
      SeoCardSetting,
    ],
  };

  submit = async (values) => {
    const {
      id,
      gql: { submit },
    } = this.state;

    await client.mutate({
      mutation: submit,
      variables: {
        id,
        input: {
          active: values.active,
          collectionCodes: _.uniq(_.map(values.collections, 'code')),
          description: values.description,
          hashtags: _.uniq(values.hashtags),
          ignoreStock: !!values.ignoreStock,
          medias: values.medias.map(toInputMedia).filter(Boolean),
          metadata: convertMetaArray(values.metadata),
          metaDescription: values.metaDescription,
          metaKeywords: values.metaKeywords,
          metaTitle: values.metaTitle,
          metadataFields: _.map(values.metadataFields, (mf) => ({
            key: mf.key,
            name: mf.name,
            type: mf.type,
            required: mf.required,
            values: mf.values?.filter(Boolean),
            unique: mf.unique,
            visible: mf.visible,
            metadata: convertMetaArray(convertMetaObject(mf.metadata)),
          })),
          modifiers: _.map(values.modifiers, (mod) => ({
            name: mod.name ?? '',
            min: mod.min ?? null,
            max: mod.max ?? null,
            options: _.map(mod?.options, (opt) => ({
              active: opt?.active ?? true,
              name: opt?.name ?? '',
              priceAdjustment: opt?.priceAdjustment ?? 0,
              minutesAdjustment: opt?.minutesAdjustment ?? 0,
            })),
          })),
          name: values.name ?? '',
          publishAt: values.publishAt,
          publishThru: values.publishThru,
          quantity: values.quantity ?? 0,
          rewriteUri: values.rewriteUri,
          services: _.map(values.services, (service, i) => ({
            id: service?.id,
            active: service?.active ?? true,
            availableUntil: service?.availableUntil,
            metadata: convertMetaArray(service?.metadata),
            name: service?.name ?? '',
            serviceLocationIds: _.uniq(_.map(service?.serviceLocations, 'id')),
            serviceLocationName: service?.serviceLocationName ?? '',
            outboundSkus: _.map(service?.productVariations, 'sku'),
            sortIndex: i,
            validSince: service?.validSince,
            validUntil: service?.validUntil,
            durationMins: service?.durationMins ?? 60,
            reserveMins: service?.reserveMins ?? 0,
            showStartTimeOnly: service?.showStartTimeOnly ?? false,
            intervalMins: service?.intervalMins ?? 15,
            startedAt: (() => {
              const m1 = moment(service?.startedAt || new Date());
              const m2 = moment(service.timeAt || '1970-01-01 00:00:00');
              m1.hours(m2.hours()).minutes(m2.minutes()).seconds(0).milliseconds(0);
              return m1.toISOString();
            })(),
            startedThru: (() => {
              const m1 = moment(service.startedThru || new Date('2999-01-01'));
              const m2 = moment(service.timeThru || '1970-01-01 00:00:00');
              m1.hours(m2.hours()).minutes(m2.minutes()).seconds(0).milliseconds(0);
              return m1.toISOString();
            })(),
            daysOfWeek: service?.daysOfWeek,
          })),
          shopId: id ? undefined : localStorage.getItem('shopId'),
          sku: values.sku || `${moment().format('YYYYMMDDHHmm')}_${getRandomString(8)}`,
          slotRequiredAtCheckout: id ? undefined : !!values.slotRequiredAtCheckout,
          primarySortIndex: values.primarySortIndex,
          subtitle: values.subtitle ?? '',
          suggestedRetailPrice: values.suggestedRetailPrice,
          unitPrice: values.unitPrice ?? 0,
          validationStrategy: values.validationStrategy,
        },
      },
    });

    return true;
  };

  getInitialData(data) {
    const { node } = data || {};
    return {
      ...node,
      metadataFields: removeTypename(cloneObject(node?.metadataFields || [])),
      name: node?.name || '',
      subtitle: node?.subtitle || '',
      slotRequiredAtCheckout: !!node?.slotRequiredAtCheckout,
      unitPrice: node?.unitPrice,
      suggestedRetailPrice: node?.suggestedRetailPrice,
      quantity: node?.quantity,
      description: node?.description,
      hashtags: node?.hashtags ?? [],
      publishAt: node?.publishAt,
      publishThru: node?.publishThru,
      validationStrategy: node?.validationStrategy || 'SERIES',

      sku: node?.sku,
      primarySortIndex: node?.primarySortIndex || 0,
      metadata: convertMetaObject(node?.metadata || []),
      modifiers: removeTypename(cloneObject(node?.modifiers || [])),

      collections: parseConnection(node?.collections).nodes,
      services: parseConnection(node?.services).nodes.map((service) => ({
        ...service,
        serviceLocationName: service?.serviceLocationName || translate.location,
        serviceLocations: parseConnection(service?.serviceLocations).nodes,
        productVariations: parseConnection(service?.productVariations).nodes,
        metadata: convertMetaObject(service?.metadata || []),
        availableUntil: removeTypename(cloneObject(service?.availableUntil)),
        validSince: removeTypename(cloneObject(service?.validSince)),
        validUntil: removeTypename(cloneObject(service?.validUntil)),
        startedThru: !service?.startedThru || /^2999/.test(service?.startedThru) ? null : service?.startedThru,
        timeAt: service?.startedAt,
        timeThru: service?.startedThru,
      })),
      medias: cloneObject(node?.medias || []),
    };
  }
}
