import React from 'react';
import { translate } from '../../../shared/translate';
import { Card } from '@material-ui/core';
import FormPageMaker from '../../../components/FormPageMaker';
import FormCard from '../../../components/FormPageMaker/Cards/FormCard';

export default {
  option: {
    group: `ADYEN ${translate.offline || '（線下）'}`,
    icon: <img className="h-4" src={require('../../../assets/adyen-logo.png')} alt="ADYEN POS" />,
    label: `ADYEN ${translate.offline || '（線下）'}`,
    value: 'ADYEN_POS',
    credentialPlatform: 'ADYEN_POS',
  },
  form: (actions) => {
    return (
      <FormCard
        variant={'outlined'}
        actions={actions}
        fields={[
          {
            label: 'POS Terminal ID',
            type: 'text',
            name: 'metadata.posId',
          },
          {
            label: 'Encryption Key identifier',
            type: 'text',
            name: 'metadata.keyIdentifier',
            placeholder: 'omniwe',
          },
          {
            label: 'Encryption Key passphrase',
            type: 'text',
            name: 'metadata.keyPassphrase',
            placeholder: 'pYudX24********************6U3QC',
          },
          {
            label: 'Encryption Key version',
            type: 'text',
            name: 'metadata.keyVersion',
            placeholder: '1',
          },
        ]}
      />
    );
  },
};
