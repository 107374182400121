import React from 'react';
import { translate } from '../../../shared/translate';

export default {
  option: {
    group: `EFT ${translate.online || '（線上）'}`,
    icon: <img className="h-4" src={require('../../../assets/eft-logo.png')} alt="eft" />,
    label: translate.eft_union_pay || 'EFT 銀聯卡',
    value: 'EFT_UNIONPAY',
    credentialPlatform: 'EFT',
  },
  form: (actions) => {
    return null;
  },
};
