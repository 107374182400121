import { gql } from '@apollo/client';

export const GET_VARIATIONS = gql`
  query ($id: ID!, $skus: [String!]!) {
    node(id: $id) {
      ... on CompanyShop {
        id
        allVariationsBySkus(skus: $skus) @client
      }
    }
  }
`;
