import React from 'react';
import { Grid, Typography, Card, CardContent, InputAdornment } from '@material-ui/core';
import CheckboxField from '../../../components/FormPageMaker/Fields/CheckboxField';
import CardContentContainer from '../CardContentContainer';
import { translate } from '../../../shared/translate';
import DateTimeField from '../../../components/FormPageMaker/Fields/DateTimeField';
import useBreakPoint from '../../../components/useBreakPoint';
import InputField from '../../../components/FormPageMaker/Fields/InputField';
import _ from 'lodash';
import SwitchField from '../../../components/FormPageMaker/Fields/SwitchField';

const FormFront = ({ values, setFieldValue, loading, disabled }) => {
  const breakpoint = useBreakPoint();
  const isWebView = !!~['lg'].indexOf(breakpoint);

  const { metadata } = values;
  const isMobileView = !!~['xs'].indexOf(breakpoint);
  const {
    isAlwayShowStock,
    isShowStockUnder,
    isAlwayShowProductTag,
    isAlwayShowProductImage,
    minimumMemberLevelToViewPrice,
    isQROrderingShowAll,
    isAlwayHideAllCatalog,
  } = metadata;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CardContentContainer
          hasActive={true}
          cardTitle={translate.catalog_mode || '目錄模式'}
          cardSubtitle={
            translate.catalog_mode_description ||
            '啟用後，網店將切換至「目錄模式」，只保留會員相關功能及提供產品瀏覽，同時隱藏產品搜尋、我的最愛和購物車。若需恢復完整購物功能，請關閉此設定'
          }
          value={isAlwayHideAllCatalog}
          onChange={() => {
            setFieldValue('metadata', {
              ...metadata,
              isAlwayHideAllCatalog: !!isAlwayHideAllCatalog ? '' : '1',
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <CardContentContainer
          hasActive={true}
          cardTitle={translate.display_product_images_after_variation || '選擇規格後總是顯示產品圖片'}
          cardSubtitle={
            translate.display_product_images_after_variation_remark ||
            '產品圖片分為「主圖」和「副圖」。通常情況下，選擇購買選項後只顯示相關的副圖。但當開啟這個選項時，主圖會顯示在副圖後面'
          }
          value={isAlwayShowProductImage}
          onChange={() => {
            setFieldValue('metadata', {
              ...metadata,
              isAlwayShowProductImage: !!isAlwayShowProductImage ? '' : '1',
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <CardContentContainer
          hasActive={true}
          cardTitle={translate.display_product_tags || '顯示產品標籤'}
          cardSubtitle={translate.display_product_tags_on_web_app || '於網頁或APP中顯示產品標籤'}
          value={isAlwayShowProductTag}
          onChange={() => {
            setFieldValue('metadata', {
              ...metadata,
              isAlwayShowProductTag: !!isAlwayShowProductTag ? '' : '1',
            });
          }}
        >
          {/* <Box>
          <SwitchField
            disabled={loading || disabled}
            checked={!!isAlwayShowProductTag}
            onChange={() => {
              setFieldValue('metadata', {
                ...metadata,
                isAlwayShowProductTag: !!isAlwayShowProductTag ? '' : '1',
              });
            }}
          />
        </Box> */}
        </CardContentContainer>
      </Grid>
      <Grid item xs={12}>
        <CardContentContainer
          hasActive={false}
          cardTitle={translate.inventory_display || '頁面顯示庫存'}
          cardSubtitle={
            translate.display_product_inventory_quantity_on_the_product_page || '於產品頁面顯示商品庫存數量'
          }
        >
          <CheckboxField
            checked={!!isAlwayShowStock}
            label={translate.always_display_actual_inventory || '總是顯示實際庫存'}
            onClick={() => {
              setFieldValue('metadata', {
                ...metadata,
                isAlwayShowStock: !!isAlwayShowStock ? '' : '1',
                isShowStockUnder: '',
              });
            }}
            disabled={loading || disabled}
          />
          <CheckboxField
            checked={!!isShowStockUnder}
            onClick={() => {
              setFieldValue('metadata', {
                ...metadata,
                isShowStockUnder: !!isShowStockUnder ? '' : '1',
                isAlwayShowStock: '',
              });
            }}
            disabled={loading || disabled}
            label={
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography>{translate.only_display_when_inventory_is_less_than || '只在庫存低於'}</Typography>
                </Grid>
                <Grid item>
                  <InputField
                    style={{ width: '100px' }}
                    value={isShowStockUnder}
                    type={'number'}
                    inputProps={{
                      min: 1,
                      step: 1,
                    }}
                    onChange={(e) => {
                      setFieldValue('metadata', {
                        ...metadata,
                        isShowStockUnder: e.target.value,
                        isAlwayShowStock: '',
                      });
                    }}
                    disabled={loading || disabled || !!isAlwayShowStock}
                  />
                </Grid>
                <Grid item>
                  <Typography>{translate.less_than || '時顯示'}</Typography>
                </Grid>
              </Grid>
            }
          />
        </CardContentContainer>
      </Grid>
      <Grid item xs={12}>
        <Card
          style={{
            margin: '0 0 25px 0',
            padding: isMobileView ? '10px' : '0 60px',
            borderRadius: '12px',
            overflow: 'hidden',
            boxShadow: '0 6px 16px 0 lightGrey',
            // position: 'relative',
          }}
        >
          <CardContent>
            <Grid container style={{ justifyContent: 'space-between' }}>
              <Grid item xs={9}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container alignItems="center">
                      <Grid item>
                        <Typography variant="h6">
                          {translate.only_show_in_specific_member_tier || '高於特定會員等級時才顯示產品價錢'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center">
                      <Grid item>
                        <Typography variant="subtitle2">
                          {translate.view_after_login || '指定的類別的會員在登入後，才能顯示產品數量、價格及結帳'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <InputField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {translate.membership_level} {'>='}{' '}
                          </InputAdornment>
                        ),
                      }}
                      type={'number'}
                      inputProps={{
                        min: 1,
                        step: 1,
                      }}
                      disabled={disabled || _.isNil(minimumMemberLevelToViewPrice)}
                      value={minimumMemberLevelToViewPrice}
                      onChange={(e) => {
                        setFieldValue('metadata', {
                          ...metadata,
                          minimumMemberLevelToViewPrice: e?.target?.value,
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <SwitchField
                      checked={!_.isNil(minimumMemberLevelToViewPrice)}
                      disabled={disabled}
                      onChange={() => {
                        if (!_.isNil(minimumMemberLevelToViewPrice)) {
                          setFieldValue('metadata', {
                            ...metadata,
                            minimumMemberLevelToViewPrice: undefined,
                          });
                        } else {
                          setFieldValue('metadata', {
                            ...metadata,
                            minimumMemberLevelToViewPrice: 1,
                          });
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <CardContentContainer
          cardTitle={translate.show_all_products_category_on_qr_code || '顧客 QR Code下單顯示「所有產品」分類'}
          cardSubtitle={
            translate.show_all_products_category_on_qr_code_description ||
            '啟用此設定後，顧客使用 QR Code下單時，選單將顯示系統預設的「所有產品」分類，讓顧客瀏覽所有可選產品。若停用此設定，「所有產品」分類將不會顯示，僅顯示其他自定義分類。'
          }
          value={!!isQROrderingShowAll}
          onChange={() => {
            setFieldValue('metadata', {
              ...metadata,
              isQROrderingShowAll: !!isQROrderingShowAll ? '' : '1',
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <CardContentContainer
          comingSoon
          cardTitle={translate.purchase_out_of_stock_products_in_POS || 'POS 可購買缺貨產品'}
          cardSubtitle={
            translate.stock_less_than_zero_can_continue_shopping || '當產品數量等於或少於0時，顧客可以繼續購物並付款'
          }
        />
      </Grid>
      <Grid item xs={12}>
        <CardContentContainer
          comingSoon
          cardTitle={translate.purchase_out_of_stock_products_online || 'Online 可購買缺貨產品'}
          cardSubtitle={translate.stock_equal_zero_can_continue_shopping || '當產品數量為0時，顧客可以繼續購物並付款'}
        />
      </Grid>
      <Grid item xs={12}>
        <CardContentContainer
          comingSoon
          cardTitle={translate.store_vacation_settings || '商戶休假設定'}
          cardSubtitle={
            translate.schedule_vacation_when_temporarily_unable_to_operate ||
            '不管你出於什麼原因暫時無法營業，都可預定休假時間，並在休假設定中選擇允許物品銷售或暫停物品銷售'
          }
        >
          <Grid container>
            <Grid item xs={12} style={{ margin: '4px 0' }}>
              <Grid container spacing={!isWebView ? 1 : 5}>
                <Grid item>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {translate.choose_start_date_time || '請選擇開始日期及時間'}
                  </Typography>
                  <DateTimeField
                    disabled={true}
                    type={'datetime-local'}
                    style={{ width: !isWebView ? 'calc(100vw * 0.5)' : '280px', margin: '5px 0' }}
                    // value={validAt}
                    onChange={(v) => {
                      // onChange({ ...value, validAt: v });
                    }}
                    inputProps={
                      {
                        // max: moment(validThru).subtract(1, 'minutes').format('YYYY-MM-DDTHH:mm'),
                      }
                    }
                  />
                </Grid>
                <Grid item>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {translate.choose_last_date_time || '請選擇完結日期及時間'}
                  </Typography>
                  <DateTimeField
                    disabled={true}
                    type={'datetime-local'}
                    style={{ width: !isWebView ? 'calc(100vw * 0.5)' : '280px', margin: '5px 0' }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <CheckboxField
                    disabled={true}
                    textVariant="subtitle1"
                    checked={false}
                    text={translate.checkout_available || '可結帳'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CheckboxField
                    disabled={true}
                    textVariant="subtitle1"
                    checked={false}
                    text={translate.checkout_unavailable || '不可結帳'}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContentContainer>
      </Grid>
    </Grid>
  );
};

export default FormFront;
