import React, { useState } from 'react';
import { Popper, Fade, Card, Grid, Typography, Button, Box, Badge } from '@material-ui/core';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import { Skeleton } from '@material-ui/lab';
import { translate } from '../../../../shared/translate';
import Avatar from '@material-ui/core/Avatar';
import ButtonBase from '@material-ui/core/ButtonBase';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import SystemButton from '../../../../components/SystemButton';
import { Icon } from '../../../../components/IconRender';
import { ReactComponent as IconCircleExclamation } from '../../../../assets/icon/circle-exclamation.svg';

const { REACT_APP_MAIN_SITE_URL } = process.env;

const LogOutButton = ({ isEmailVerified }) => {
  const handleClick = async () => {
    localStorage.clear();
    window.location.href = `${REACT_APP_MAIN_SITE_URL}/login?redirectUrl=${window.location.origin}/login` || '/login';
    // await client.resetStore();
    // await client.clearStore();
  };

  return isEmailVerified ? (
    <Button size="small" color="secondary" variant="contained" type="button" onClick={handleClick}>
      {translate.logout}
    </Button>
  ) : (
    <SystemButton size="small" variant="outlined" className="normal-btn" onClick={handleClick}>
      {translate.logout}
    </SystemButton>
  );
};

export default ({ loading, user: { name, email, picture, isEmailVerified } = {}, className }) => {
  const [anchor, setAnchor] = useState(undefined);

  return (
    <ClickAwayListener onClickAway={() => setAnchor(undefined)}>
      <div>
        <ButtonBase
          className={className}
          style={{ borderRadius: '50%', paddingRight: isEmailVerified ? undefined : 8 }}
          onClick={(e) => setAnchor(anchor ? undefined : e.currentTarget)}
        >
          <Badge variant="dot" invisible={isEmailVerified} color="secondary" overlap={'circular'}>
            <Avatar alt={name} src={picture} style={{ width: 24, height: 24 }} />
          </Badge>
        </ButtonBase>
        {/*<IconButton className={className} onClick={e => setAnchor(anchor ? undefined : e.currentTarget)}>*/}
        {/*  <Icon icon={'AccountCircle'} type={'material'} />*/}
        {/*</IconButton>*/}
        <Popper
          id={!!anchor ? 'account-popper' : undefined}
          style={{ zIndex: 1 }}
          open={!!anchor}
          anchorEl={anchor}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Card style={{ overflow: 'hidden', width: 200 }}>
                <CardActionArea disableRipple={true} onClick={() => setAnchor(undefined)}>
                  <CardMedia
                    style={{ minWidth: 120, height: 120 }}
                    image={picture || require('../../../../assets/gray.jpg')}
                  />
                  <CardContent>
                    <Grid container alignItems={'center'} direction={'column'} spacing={1}>
                      {name && (
                        <Grid item style={{ width: '100%' }}>
                          {loading ? (
                            <Skeleton />
                          ) : (
                            <Typography align={'center'} style={{ maxWidth: '100%' }} variant={'h6'} noWrap={true}>
                              {name}
                            </Typography>
                          )}
                        </Grid>
                      )}
                      {!isEmailVerified && (
                        <Grid item>
                          {loading ? (
                            <Skeleton />
                          ) : (
                            <Box display="flex" alignItems="center" gridGap={8}>
                              <Typography color="secondary">{translate.unverified}</Typography>
                              <Icon
                                type={'svg'}
                                icon={IconCircleExclamation}
                                viewBox={'0 0 512 512'}
                                style={{ color: '#DF5241' }}
                              />
                            </Box>
                          )}
                        </Grid>
                      )}
                      <Grid item style={{ width: '100%' }}>
                        <Typography align={'center'} style={{ maxWidth: '100%' }} noWrap={true}>
                          {loading ? <Skeleton /> : email}
                        </Typography>
                      </Grid>
                      {isEmailVerified ? (
                        <Grid item>
                          <LogOutButton isEmailVerified={isEmailVerified} />
                        </Grid>
                      ) : (
                        <Grid item>
                          <Box display="flex" alignItems="center" gridGap={12}>
                            <Button
                              size="small"
                              color="secondary"
                              variant="contained"
                              type="button"
                              onClick={() => {
                                window.open(`${REACT_APP_MAIN_SITE_URL}/verify/email?email=${email}` || '/login');
                              }}
                            >
                              {translate.verify_now}
                            </Button>
                            <LogOutButton />
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Fade>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};
