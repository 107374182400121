import ConnectionPageMarker from '../../../components/ConnectionPageMaker';
import { translate } from '../../../shared/translate';
import { GET_SALE_RECORD_QUERY } from './query';
import { withSalePerformanceRange } from '../../../components/SalePerformanceRangeProvider';

class SalesRecordsList extends ConnectionPageMarker {
  state = {
    ...this.state,
    title: translate.all_products_data,
    gql: {
      get: GET_SALE_RECORD_QUERY,
    },
    fields: [
      {
        title: translate.date,
        fieldName: 'createdAt',
        type: 'datetime',
        sortBy: 'createdAt',
      },
      {
        title: translate.shop_name,
        fieldName: 'shopName',
      },

      {
        title: translate.order_number,
        fieldName: 'referenceNo',
      },
      {
        title: translate.quantity,
        align: 'center',
        fieldName: 'count',
      },
      {
        title: translate.amount,
        align: 'right',
        fieldName: 'amount',
        type: 'price',
      },
    ],
  };

  getExtraFetchVariables() {
    const { id, range } = this.props;
    const { startedAt, startedThru } = range ?? {};

    return {
      startedAt,
      startedThru,
      id: localStorage.getItem('companyId'),
      salespersonId: id,
    };
  }

  getData(data) {
    return data.node.report.salespersonSales.salesRecords;
  }
}

export default withSalePerformanceRange(SalesRecordsList);
